import { Store } from "./core/store";
const maxHistoryLength = 20;

export const History = new Store({
  key: "store:history-2",
  defaultSchema: {
    course: {},
    index: 0,
  },
});

History.add = (data) => {
  const list = History.get(`course.${data._id}.list`, []);
  if (list.length > maxHistoryLength) {
    list.shift();
  }

  const cIndex = History.get(`course.${data._id}.index`, 0) + 1;

  if (list.length > cIndex) {
    list.splice(cIndex);
  }

  History.set(`course.${data._id}.list`, [
    ...list,
    {
      data: JSON.parse(JSON.stringify(data)),
      time: Date.now(),
    },
  ]);

  History.set(`course.${data._id}.index`, list.length);
};

History.getPrev = (_id) => {
  const cIndex = History.get(`course.${_id}.index`);
  const index = cIndex - 1 <= 0 ? 0 : cIndex - 1;
  History.set(`course.${_id}.index`, index);

  return History.get(`course.${_id}.list.${index}`);
};

History.getNext = (_id) => {
  const cIndex = History.get(`course.${_id}.index`, 0) + 1;
  const list = History.get(`course.${_id}.list`, []);
  const index = cIndex >= list.length - 1 ? list.length - 1 : cIndex;
  History.set(`course.${_id}.index`, index);

  return History.get(`course.${_id}.list.${index}`);
};

History.canNext = (_id) => {
  const cIndex = History.get(`course.${_id}.index`, 0);
  const list = History.get(`course.${_id}.list`, []);

  return cIndex < list.length -1
};

History.canPrev = (_id) => {
  const cIndex = History.get(`course.${_id}.index`, 0);

  return cIndex > 0
};

window.History = History;
