import { Link as L } from "react-router-dom";
import { getClasses } from "../constants/theme";

const StatusesConf = {
  CRSStatus: {
    Confirmed: ["Проверен", "green"],
    Hidden: ["Скрыт"],
    Rejected: ["Отклонен"],
    PendingConsideration: ["Требует провеки", "red"],
    UnderConsideration: ["На проверке", "orange"],
  },
  KYCStatus: {
    Verified: ["Проверен", "green"],
    WaitingVerification: ["На проверке"],
    Rejected: ["Отклонен", "red"],
    RejectedRemote: ["Отклонен сервисом", "red"],
    VerifiedRemote: ["Удаленное подтверждение", "green"],
    SelfEmployedVerified:  ["Проверен на самозанятого", "green"],
  },
  PREStatus: {
    NotSloved: ["Не решена", "red"],
    Solved: ["Решена", "green"],
  },
  ARTStatus:{
    Confirmed: ["Проверен", "green"],
    Rejected: ["Отклонен"],
    PendingConsideration: ["Требует провеки", "red"],
    UnderConsideration: ["На проверке", "orange"],
  }
};

const getConf = (name, type) => {
  if (StatusesConf[type] === undefined) return [name];

  if (StatusesConf[type][name] === undefined) return [name];

  return StatusesConf[type][name];
};

export default function Statuses(props) {
  const classes = getClasses(dynamicStyle);
  const constr = window[props.class];

  if (constr === undefined) return null;

  const status = new constr(props.status);
  let statusStrings = status.toString();

  if (statusStrings === "") {
    if (props.default) statusStrings = props.default;
    else return null;
  }

  return (
    <div className={classes(props.rowMode ? "rowitems" : "items")}>
      {statusStrings.split("|").map((s) => {
        const [title, className] = getConf(s, props.class);
        return <div className={classes(props.rowMode ? "status" : "status2", className)}>{title}</div>;
      })}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    items: {
      display: "flex",
    },
    rowitems: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
    status: {
      ...Fonts.subhead2,
      color: Colors.neutral2,
      backgroundColor: "#F3F5F8",
      padding: "4px 12px",
      borderRadius: 8,
      marginBottom: 2,
    },
    status2: {
      ...Fonts.subhead2,
      color: Colors.neutral2,
      backgroundColor: "#F3F5F8",
      padding: "4px 12px",
      borderRadius: 8,
      marginRight:4
    },
    green: {
      color: "#09AF51",
      backgroundColor: "#EFFBEF",
    },
    red: {
      color: "#F53B3B",
      backgroundColor: "#FFEEE1",
    },
    orange: {
      color: "#FF961E",
      backgroundColor: "#FAF8F4",
    },
  };
};
