import React from "react";
import { ArrowLeft } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { getClasses } from "../../constants/theme";
import SearchBar from "./SearchBar";

const BLink = (props) => {
  return (
    <Link className={props.className} to={props.link}>
      {props.children}
    </Link>
  );
};

export default function InnerHeader(props) {
  const classes = getClasses(dynamicStyle);
  const navigate = useNavigate();

  return (
    <div className={classes("wrapper")}>
      {/* {props.goBack && (
        <div onClick={() => navigate(-1)} className={classes("arrowWrapper")}>
          <ArrowLeft />
        </div>
      )} */}
      {props.title && (
        <div className={classes("title")}>
          {props.title}
        </div>
      )}
      {props.breadcrumbs && (
        <div className={classes('breadcrumbs')}>
          {props.breadcrumbs.map(([title, link]) => {
            if (link) {
              return (
                <BLink className={classes("bitem", "blink")} classes={classes} link={link}>
                  {title}
                </BLink>
              );
            } else {
              return <div className={classes("bitem")}>{title}</div>;
            }
          })}
        </div>
      )}
      {props.onQuerySubmit && (
        <div>
          <SearchBar
            defaultQuery={props.defaultQuery}
            onQuerySubmit={props.onQuerySubmit}
          />
        </div>
      )}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    arrowWrapper: {
      display: "flex",
      marginRight: 16,
    },
    breadcrumbs:{
      display:'flex',
      flexDirection:'row',
      flex: 1,
    },
    subtitle: {
      ...Fonts.subhead1,
      color: Colors.neutral2,
    },
    bitem:{
      ...Fonts.pageTitle,
      color: Colors.n90,
      marginRight:16
    },
    blink: {
      textDecoration: 'none',
      display: "flex",
      color: Colors.blue90
    },
    title: {
      ...Fonts.pageTitle,
      color: Colors.neutral1,
      display: "flex",
      flex: 1,
    },
    wrapper: {
      display: "flex",
      flexDirection: "row",
      height: 100,
      alignItems: "center",
      borderBottom: "1px solid #E5EAEF",
      paddingLeft: 40,
      paddingRight: 40,
     // position: "fixed",
      background: " #fff",
      right: 0,
     // left: 294,
    },
  };
};
