import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import { errorHandler } from "../api";
import { getClasses } from "../constants/theme";

import loader from "./../assets/load.gif";

const initialState = {
  last: [],
  data: [],
  total: 0,
  loading: false,
  qp: void 0,
  first_fetch: false,
  end: false,
  inited: false,
  direction: 0,

};

function reducer(state, action) {
  let data;
  switch (action.type) {
    case "loading":
      return {
        ...state,
        loading: true,
      };
    case "inited":
      data = action.invert
        ? [...action.result.data].reverse()
        : action.result.data;

      return {
        ...state,
        direction: 1,
        data: data,
        last: data,
        total: action.result.total,
        loading: false,
        first_fetch: true,
        inited: true,
        end: action.result.data.length === 0,
        pageHeight: document.getElementById("container").clientHeight,
        qp:
          action.result.data.length > 0
            ? action.result.data[action.result.data.length - 1]._qp
            : void 0,
      };
    case "addNext":
      data = action.invert
        ? [...action.result.data].reverse()
        : action.result.data;

      return {
        ...state,
        direction: 1,
        data: action.invert
          ? [...data, ...state.data]
          : [...state.data, ...data],
        last: data,
        pageHeight: document.getElementById("container").clientHeight,
        total: action.result.total,
        first_fetch: true,
        loading: false,
        end: action.result.data.length === 0,
        qp:
          action.result.data.length > 0
            ? action.result.data[action.result.data.length - 1]._qp
            : void 0,
      };

    case "addNextBefore":
      data = action.invert
        ? [...action.result.data].reverse()
        : action.result.data;

      return {
        ...state,
        last: data,
        direction: -1,
        pageHeight: document.getElementById("container").clientHeight,
        data: [...state.data, ...data],
      };
    case "insertBefore":
      return {
        ...state,
        direction: -1,
        last: data,
        pageHeight: document.getElementById("container").clientHeight,
        data: [...state.data, action.item],
      };
    default:
      return { ...state };
  }
}

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
    (window.innerHeight ||
      document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
    (window.innerWidth ||
      document.documentElement.clientWidth) /* or $(window).width() */
  );
}

const InfinityList = React.forwardRef(function (props, ref) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const endRef = useRef();
  const endStat = useRef(false);
  const classes = getClasses(dynamicStyle);

  useImperativeHandle(ref, () => ({
    reload: () => {
      init();
    },
    insertBefore: (item) => {
      dispatch({ type: "insertBefore", item });
    },
    getNext: (qp) => {
      getNext(qp);
    },
    getState: () => {
      return state;
    },
    getPrev: () => {
      next();
    }
  }));

  const fetchData = async (options = {}, fo = {}) => {
    const { withLoading = true } = fo;
    try {
      withLoading && dispatch({ type: "loading" });
      const res = await props.fetchData({
        limit: props.limit || 10,
        ...options,
      });

      return res;
    } catch (e) {
      errorHandler(e);
      return {
        data: [],
        total: 0,
      };
    }
  };

  const init = async () => {
    const res = await fetchData({
      offset: 0,
    });

    dispatch({ type: "inited", result: res, invert: props.invert });
    endStat.current = false;
    props.onBatchAdded && props.onBatchAdded(res, state)
  };

  const getNext = async (qp) => {
    const res = await fetchData(
      {
        offset: state.data.length,
      },
      {
        withLoading: false,
      }
    );

    if (res.data.length > 0) {
      dispatch({ type: "addNextBefore", result: res, invert: props.invert });
      endStat.current = false;
    }

    props.onBatchAdded && props.onBatchAdded(res, state)
  };

  const next = async () => {
    if (state.end) return;
    const res = await fetchData({
      offset: state.data.length,
    });

    dispatch({ type: "addNext", result: res, invert: props.invert });
    endStat.current = false;

    props.onBatchAdded && props.onBatchAdded(res, state)
  };

  const check = () => {
    if (props.disableAutoLoad) return;

    if (
      endRef.current &&
      isElementInViewport(endRef.current) &&
      endStat.current === false
    ) {
      endStat.current = true;
      endRef.current.click();
    }
  };

  useEffect(() => {
    init();
    document.addEventListener("scroll", check);

    return () => {
      document.removeEventListener("scroll", check);
    };
  }, []);

  useEffect(() => {
    props.invert &&
      window.scrollTo(
        0,
        document.getElementById("container").clientHeight - state.pageHeight
      );
  }, [state.pageHeight]);

  useEffect(() => {
    props.onItemsAdded && props.onItemsAdded(state);
  }, [state.data.length]);

  if (state.inited === false)
    return (
      <div className={classes("emptyContainer")}>
        <img className={classes("loader")} src={loader} />
      </div>
    );


  return (
    <>
      {state.loading && (
        <div className={classes("emptyContainer")}>
          <img className={classes("loader")} src={loader} />
        </div>
      )}
      {state.end === false && props.startComponent && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div ref={endRef} onClick={next}>
            {props.startComponent}
          </div>
        </div>
      )}
      {state.data.map((item, index, arr) => (
        <React.Fragment key={index + ""}>
          {props.renderItem(item, index, arr)}
        </React.Fragment>
      ))}
      {props.renderFooterComponent && props.renderFooterComponent()}
      {state.end === false && props.endComponent && state.data.length >= props.size && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div ref={endRef} onClick={next}>
            {props.endComponent}
          </div>
        </div>
      )}
    </>
  );
});

InfinityList.defaultProps = {
  size: 10
}

export default InfinityList;


const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    loader: {
      height: 32,
      width: 32,
    },
    emptyContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      left: 292,
      right: 0,
      bottom: 0,
      top: 0,
      // backgroundColor: Colors.n0,
    },
  };
};
