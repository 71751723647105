"use strict";
class Status {
  constructor(value) {
    if (value instanceof Status) this._value = value._value;
    else this._value = value;
    if (typeof this._value === "string")
      this._value = this.constructor.mask(this._value);
  }
  isArchived() {
    return (this._value & 1) !== 0;
  }
  set(bitName) {
    const i = this.constructor.constList.indexOf(bitName);
    if (i === -1) throw new Error("Invalid status name");
    typeof this._value === "undefined" && (this._value = 0);
    this._value = this._value | (1 << i);
    return this;
  }
  unset(bitName) {
    const i = this.constructor.constList.indexOf(bitName);
    if (i === -1) throw new Error("Invalid status name");
    if (typeof this._value === "undefined") return this;
    this._value = this._value & ~(1 << i);
    return this;
  }
  get value() {
    return this._value;
  }
  toJSON() {
    return this._value;
  }
  toString() {
    const result = [];
    (this.constructor.constList || []).forEach((_const) => {
      if (this[`is${_const}`]()) result.push(_const);
    });
    return result.join("|");
  }
  any(b) {
    var bits = b;
    if (typeof this._value === "undefined" || typeof bits === "undefined")
      return false;
    if (typeof bits === "string") bits = bits.split("|");
    if (Array.isArray(bits)) {
      bits = bits
        .map((bit) => +this.constructor[bit])
        .reduce((prev, curr) => prev | curr);
    }
    return (this._value & bits) !== 0;
  }
  all(b) {
    var bits = b;
    if (typeof this._value === "undefined" || typeof bits === "undefined")
      return false;
    if (typeof bits === "string") bits = bits.split("|");
    if (Array.isArray(bits))
      bits = bits
        .map((bit) => +this.constructor[bit])
        .reduce((prev, curr) => prev | curr);
    return (this._value & bits) === bits;
  }
  static mask(b = [], negate = false) {
    let bits = b;
    if (typeof bits === "string") bits = bits.split("|");
    let result = 0;
    bits.forEach((bit) => {
      result |= +this[bit];
    });
    if (!negate) return result;
    else {
      return (
        (this.constList || [])
          .map((bit) => +this[bit])
          .reduce((prev, curr) => prev | curr) - result
      );
    }
  }
}
class ACHStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isCompleted(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isRead(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
}
ACHStatus.Archived = 1;
ACHStatus.Removed = 2;
ACHStatus.Completed = 4;
ACHStatus.Read = 8;
ACHStatus.constList = ["Archived", "Removed", "Completed", "Read"];
ACHStatus.prefix = "ACH";
window["ACHStatus"] = ACHStatus;
class ACTStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRead(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
}
ACTStatus.Archived = 1;
ACTStatus.Removed = 2;
ACTStatus.Read = 4;
ACTStatus.constList = ["Archived", "Removed", "Read"];
ACTStatus.prefix = "ACT";
window["ACTStatus"] = ACTStatus;
class ARTStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isHidden(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isConfirmed(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isRejected(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isUnderConsideration(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isPendingConsideration(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
}
ARTStatus.Archived = 1;
ARTStatus.Removed = 2;
ARTStatus.Hidden = 4;
ARTStatus.Confirmed = 8;
ARTStatus.Rejected = 16;
ARTStatus.UnderConsideration = 32;
ARTStatus.PendingConsideration = 64;
ARTStatus.constList = [
  "Archived",
  "Removed",
  "Hidden",
  "Confirmed",
  "Rejected",
  "UnderConsideration",
  "PendingConsideration",
];
ARTStatus.prefix = "ART";
window["ARTStatus"] = ARTStatus;
class BUSStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
BUSStatus.Archived = 1;
BUSStatus.Removed = 2;
BUSStatus.constList = ["Archived", "Removed"];
BUSStatus.prefix = "BUS";
window["BUSStatus"] = BUSStatus;
class CHTStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isMultiply(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isClosed(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isWithoutServiceMessages(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isWithoutIncTotals(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isTechnicalSupport(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isMainTargetChat(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isStreaming(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isMutedServiceMessage(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isCalling(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isOrgChat(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
}
CHTStatus.Archived = 1;
CHTStatus.Removed = 2;
CHTStatus.Multiply = 4;
CHTStatus.Closed = 8;
CHTStatus.WithoutServiceMessages = 16;
CHTStatus.WithoutIncTotals = 32;
CHTStatus.TechnicalSupport = 64;
CHTStatus.MainTargetChat = 128;
CHTStatus.Streaming = 256;
CHTStatus.MutedServiceMessage = 512;
CHTStatus.Calling = 1024;
CHTStatus.OrgChat = 2048;
CHTStatus.constList = [
  "Archived",
  "Removed",
  "Multiply",
  "Closed",
  "WithoutServiceMessages",
  "WithoutIncTotals",
  "TechnicalSupport",
  "MainTargetChat",
  "Streaming",
  "MutedServiceMessage",
  "Calling",
  "OrgChat",
];
CHTStatus.prefix = "CHT";
window["CHTStatus"] = CHTStatus;
class CHMStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isChatOwner(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isChatMuted(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isClosed(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isMultiply(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isOrganizer(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isWithoutServiceMessages(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isBlocked(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isMyMessagesRead(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isConnected(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isAssistant(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
}
CHMStatus.Archived = 1;
CHMStatus.Removed = 2;
CHMStatus.ChatOwner = 4;
CHMStatus.ChatMuted = 8;
CHMStatus.Closed = 16;
CHMStatus.Multiply = 32;
CHMStatus.Organizer = 64;
CHMStatus.WithoutServiceMessages = 128;
CHMStatus.Blocked = 256;
CHMStatus.MyMessagesRead = 512;
CHMStatus.Connected = 1024;
CHMStatus.Assistant = 2048;
CHMStatus.constList = [
  "Archived",
  "Removed",
  "ChatOwner",
  "ChatMuted",
  "Closed",
  "Multiply",
  "Organizer",
  "WithoutServiceMessages",
  "Blocked",
  "MyMessagesRead",
  "Connected",
  "Assistant",
];
CHMStatus.prefix = "CHM";
window["CHMStatus"] = CHMStatus;
class CLStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isAuthenticated(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isOnline(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isConfirmedSMS(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isRegistrationCompleted(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isDeveloper(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isFake(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isBlocked(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isTechnicalSupport(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isOrganizer(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isCommonChatsDenied(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
  isVerified(v) {
    v === undefined && (v = this._value);
    return (v & 4096) !== 0;
  }
  isCallsAllowed(v) {
    v === undefined && (v = this._value);
    return (v & 8192) !== 0;
  }
}
CLStatus.Archived = 1;
CLStatus.Removed = 2;
CLStatus.Authenticated = 4;
CLStatus.Online = 8;
CLStatus.ConfirmedSMS = 16;
CLStatus.RegistrationCompleted = 32;
CLStatus.Developer = 64;
CLStatus.Fake = 128;
CLStatus.Blocked = 256;
CLStatus.TechnicalSupport = 512;
CLStatus.Organizer = 1024;
CLStatus.CommonChatsDenied = 2048;
CLStatus.Verified = 4096;
CLStatus.CallsAllowed = 8192;
CLStatus.constList = [
  "Archived",
  "Removed",
  "Authenticated",
  "Online",
  "ConfirmedSMS",
  "RegistrationCompleted",
  "Developer",
  "Fake",
  "Blocked",
  "TechnicalSupport",
  "Organizer",
  "CommonChatsDenied",
  "Verified",
  "CallsAllowed",
];
CLStatus.prefix = "CL";
window["CLStatus"] = CLStatus;
class CMTStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isOrganizer(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isAssistant(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isFrozen(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isLikedByOrg(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
}
CMTStatus.Archived = 1;
CMTStatus.Removed = 2;
CMTStatus.Organizer = 4;
CMTStatus.Assistant = 8;
CMTStatus.Frozen = 16;
CMTStatus.LikedByOrg = 32;
CMTStatus.constList = [
  "Archived",
  "Removed",
  "Organizer",
  "Assistant",
  "Frozen",
  "LikedByOrg",
];
CMTStatus.prefix = "CMT";
window["CMTStatus"] = CMTStatus;
class CRSStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isConfirmed(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isRejected(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isUnderConsideration(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isPendingConsideration(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isHidden(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isPromo(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
}
CRSStatus.Archived = 1;
CRSStatus.Removed = 2;
CRSStatus.Confirmed = 4;
CRSStatus.Rejected = 8;
CRSStatus.UnderConsideration = 16;
CRSStatus.PendingConsideration = 32;
CRSStatus.Hidden = 64;
CRSStatus.Promo = 128;
CRSStatus.constList = [
  "Archived",
  "Removed",
  "Confirmed",
  "Rejected",
  "UnderConsideration",
  "PendingConsideration",
  "Hidden",
  "Promo",
];
CRSStatus.prefix = "CRS";
window["CRSStatus"] = CRSStatus;
class FILEStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isDirectory(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isEmpty(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isProcessing(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isFinished(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isPreviewLoaded(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isHidden(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
}
FILEStatus.Archived = 1;
FILEStatus.Removed = 2;
FILEStatus.Directory = 4;
FILEStatus.Empty = 8;
FILEStatus.Processing = 16;
FILEStatus.Finished = 32;
FILEStatus.PreviewLoaded = 64;
FILEStatus.Hidden = 128;
FILEStatus.constList = [
  "Archived",
  "Removed",
  "Directory",
  "Empty",
  "Processing",
  "Finished",
  "PreviewLoaded",
  "Hidden",
];
FILEStatus.prefix = "FILE";
window["FILEStatus"] = FILEStatus;
class GRDStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isCompleted(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
}
GRDStatus.Archived = 1;
GRDStatus.Removed = 2;
GRDStatus.Completed = 4;
GRDStatus.constList = ["Archived", "Removed", "Completed"];
GRDStatus.prefix = "GRD";
window["GRDStatus"] = GRDStatus;
class I18NStatus extends Status {
  isDirectory(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isMarkdown(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
}
I18NStatus.Archived = 1;
I18NStatus.Directory = 2;
I18NStatus.Markdown = 4;
I18NStatus.Removed = 8;
I18NStatus.constList = ["Archived", "Directory", "Markdown", "Removed"];
I18NStatus.prefix = "I18N";
window["I18NStatus"] = I18NStatus;
class KYCStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isVerified(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isRejected(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isWaitingVerification(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isExpired(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isInBlackList(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isVerifiedRemote(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isRejectedRemote(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isWaitingRemoteVerification(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isSelfEmployedVerified(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
}
KYCStatus.Archived = 1;
KYCStatus.Removed = 2;
KYCStatus.Verified = 4;
KYCStatus.Rejected = 8;
KYCStatus.WaitingVerification = 16;
KYCStatus.Expired = 32;
KYCStatus.InBlackList = 64;
KYCStatus.VerifiedRemote = 128;
KYCStatus.RejectedRemote = 256;
KYCStatus.WaitingRemoteVerification = 512;
KYCStatus.SelfEmployedVerified = 1024;
KYCStatus.constList = [
  "Archived",
  "Removed",
  "Verified",
  "Rejected",
  "WaitingVerification",
  "Expired",
  "InBlackList",
  "VerifiedRemote",
  "RejectedRemote",
  "WaitingRemoteVerification",
  "SelfEmployedVerified",
];
KYCStatus.prefix = "KYC";
window["KYCStatus"] = KYCStatus;
class MBStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isBanned(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isPayStarted(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isPayCompleted(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isActive(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isHidden(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isHasReview(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isHasReviewAnswer(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isInactive(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
}
MBStatus.Archived = 1;
MBStatus.Removed = 2;
MBStatus.Banned = 4;
MBStatus.PayStarted = 8;
MBStatus.PayCompleted = 16;
MBStatus.Active = 32;
MBStatus.Hidden = 64;
MBStatus.HasReview = 128;
MBStatus.HasReviewAnswer = 256;
MBStatus.Inactive = 512;
MBStatus.constList = [
  "Archived",
  "Removed",
  "Banned",
  "PayStarted",
  "PayCompleted",
  "Active",
  "Hidden",
  "HasReview",
  "HasReviewAnswer",
  "Inactive",
];
MBStatus.prefix = "MB";
window["MBStatus"] = MBStatus;
class MSGStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRead(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isSystem(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isMuted(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
}
MSGStatus.Archived = 1;
MSGStatus.Removed = 2;
MSGStatus.Read = 4;
MSGStatus.System = 8;
MSGStatus.Muted = 16;
MSGStatus.constList = ["Archived", "Removed", "Read", "System", "Muted"];
MSGStatus.prefix = "MSG";
window["MSGStatus"] = MSGStatus;
class NFStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
NFStatus.Archived = 1;
NFStatus.Removed = 2;
NFStatus.constList = ["Archived", "Removed"];
NFStatus.prefix = "NF";
window["NFStatus"] = NFStatus;
class ODINStatus extends Status {}
ODINStatus.Archived = 1;
ODINStatus.constList = ["Archived"];
ODINStatus.prefix = "ODIN";
window["ODINStatus"] = ODINStatus;
class PLCStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
PLCStatus.Archived = 1;
PLCStatus.Removed = 2;
PLCStatus.constList = ["Archived", "Removed"];
PLCStatus.prefix = "PLC";
window["PLCStatus"] = PLCStatus;
class PREStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isSolved(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
}
PREStatus.Archived = 1;
PREStatus.Removed = 2;
PREStatus.Solved = 4;
PREStatus.constList = ["Archived", "Removed", "Solved"];
PREStatus.prefix = "PRE";
window["PREStatus"] = PREStatus;
class SCDStatus extends Status {
  isPeriodic(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isFinished(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isKilled(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isPending(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
}
SCDStatus.Archived = 1;
SCDStatus.Periodic = 2;
SCDStatus.Finished = 4;
SCDStatus.Killed = 8;
SCDStatus.Pending = 16;
SCDStatus.Removed = 32;
SCDStatus.constList = [
  "Archived",
  "Periodic",
  "Finished",
  "Killed",
  "Pending",
  "Removed",
];
SCDStatus.prefix = "SCD";
window["SCDStatus"] = SCDStatus;
class SCHStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
SCHStatus.Archived = 1;
SCHStatus.Removed = 2;
SCHStatus.constList = ["Archived", "Removed"];
SCHStatus.prefix = "SCH";
window["SCHStatus"] = SCHStatus;
class SSNStatus extends Status {
  isClosed(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isConnected(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
}
SSNStatus.Archived = 1;
SSNStatus.Closed = 2;
SSNStatus.Removed = 4;
SSNStatus.Connected = 8;
SSNStatus.constList = ["Archived", "Closed", "Removed", "Connected"];
SSNStatus.prefix = "SSN";
window["SSNStatus"] = SSNStatus;
class SGStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isStarted(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isFinished(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isGroup(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isPaid(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isHasGroupChat(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isHasChatWithOrg(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isHasChatBetweenMembers(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isHasRating(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isAvailableImmediately(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isMoneyWithdrawn(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
  isStartAfterRegistration(v) {
    v === undefined && (v = this._value);
    return (v & 4096) !== 0;
  }
  isDisplayReviews(v) {
    v === undefined && (v = this._value);
    return (v & 8192) !== 0;
  }
  isMoneyWithdrawStarted(v) {
    v === undefined && (v = this._value);
    return (v & 16384) !== 0;
  }
  isArchivedForFront(v) {
    v === undefined && (v = this._value);
    return (v & 32768) !== 0;
  }
  isAvailableInStore(v) {
    v === undefined && (v = this._value);
    return (v & 65536) !== 0;
  }
  isRegistrationAvailable(v) {
    v === undefined && (v = this._value);
    return (v & 131072) !== 0;
  }
  isWithoutCheckHometask(v) {
    v === undefined && (v = this._value);
    return (v & 262144) !== 0;
  }
  isShowParticipantCount(v) {
    v === undefined && (v = this._value);
    return (v & 524288) !== 0;
  }
  isSellAtApple(v) {
    v === undefined && (v = this._value);
    return (v & 1048576) !== 0;
  }
  isHasCallsWithOrg(v) {
    v === undefined && (v = this._value);
    return (v & 2097152) !== 0;
  }
  isHasCallsBetweenMembers(v) {
    v === undefined && (v = this._value);
    return (v & 4194304) !== 0;
  }
}
SGStatus.Archived = 1;
SGStatus.Removed = 2;
SGStatus.Started = 4;
SGStatus.Finished = 8;
SGStatus.Group = 16;
SGStatus.Paid = 32;
SGStatus.HasGroupChat = 64;
SGStatus.HasChatWithOrg = 128;
SGStatus.HasChatBetweenMembers = 256;
SGStatus.HasRating = 512;
SGStatus.AvailableImmediately = 1024;
SGStatus.MoneyWithdrawn = 2048;
SGStatus.StartAfterRegistration = 4096;
SGStatus.DisplayReviews = 8192;
SGStatus.MoneyWithdrawStarted = 16384;
SGStatus.ArchivedForFront = 32768;
SGStatus.AvailableInStore = 65536;
SGStatus.RegistrationAvailable = 131072;
SGStatus.WithoutCheckHometask = 262144;
SGStatus.ShowParticipantCount = 524288;
SGStatus.SellAtApple = 1048576;
SGStatus.HasCallsWithOrg = 2097152;
SGStatus.HasCallsBetweenMembers = 4194304;
SGStatus.constList = [
  "Archived",
  "Removed",
  "Started",
  "Finished",
  "Group",
  "Paid",
  "HasGroupChat",
  "HasChatWithOrg",
  "HasChatBetweenMembers",
  "HasRating",
  "AvailableImmediately",
  "MoneyWithdrawn",
  "StartAfterRegistration",
  "DisplayReviews",
  "MoneyWithdrawStarted",
  "ArchivedForFront",
  "AvailableInStore",
  "RegistrationAvailable",
  "WithoutCheckHometask",
  "ShowParticipantCount",
  "SellAtApple",
  "HasCallsWithOrg",
  "HasCallsBetweenMembers",
];
SGStatus.prefix = "SG";
window["SGStatus"] = SGStatus;
class SRVStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isChecking(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
}
SRVStatus.Archived = 1;
SRVStatus.Removed = 2;
SRVStatus.Checking = 4;
SRVStatus.constList = ["Archived", "Removed", "Checking"];
SRVStatus.prefix = "SRV";
window["SRVStatus"] = SRVStatus;
class TRStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isFirst(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isAccepted(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isSecured(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isDeclined(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isRejected(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isFailed(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isAcceptedLocally(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isWaitingForLocalConfirmation(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isWaitingForRemoteConfirmation(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isWaitingForPaymentInfo(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
  isDeclinedLocally(v) {
    v === undefined && (v = this._value);
    return (v & 4096) !== 0;
  }
  isHolded(v) {
    v === undefined && (v = this._value);
    return (v & 8192) !== 0;
  }
  isWaitingForRemoteRefund(v) {
    v === undefined && (v = this._value);
    return (v & 16384) !== 0;
  }
  isRefunded(v) {
    v === undefined && (v = this._value);
    return (v & 32768) !== 0;
  }
  isAuthorized(v) {
    v === undefined && (v = this._value);
    return (v & 65536) !== 0;
  }
}
TRStatus.Archived = 1;
TRStatus.Removed = 2;
TRStatus.First = 4;
TRStatus.Accepted = 8;
TRStatus.Secured = 16;
TRStatus.Declined = 32;
TRStatus.Rejected = 64;
TRStatus.Failed = 128;
TRStatus.AcceptedLocally = 256;
TRStatus.WaitingForLocalConfirmation = 512;
TRStatus.WaitingForRemoteConfirmation = 1024;
TRStatus.WaitingForPaymentInfo = 2048;
TRStatus.DeclinedLocally = 4096;
TRStatus.Holded = 8192;
TRStatus.WaitingForRemoteRefund = 16384;
TRStatus.Refunded = 32768;
TRStatus.Authorized = 65536;
TRStatus.constList = [
  "Archived",
  "Removed",
  "First",
  "Accepted",
  "Secured",
  "Declined",
  "Rejected",
  "Failed",
  "AcceptedLocally",
  "WaitingForLocalConfirmation",
  "WaitingForRemoteConfirmation",
  "WaitingForPaymentInfo",
  "DeclinedLocally",
  "Holded",
  "WaitingForRemoteRefund",
  "Refunded",
  "Authorized",
];
TRStatus.prefix = "TR";
window["TRStatus"] = TRStatus;
class USRStatus extends Status {
  isOnline(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isBanned(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isFired(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isInternal(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isAuthenticated(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
}
USRStatus.Archived = 1;
USRStatus.Online = 2;
USRStatus.Banned = 4;
USRStatus.Fired = 8;
USRStatus.Internal = 16;
USRStatus.Authenticated = 32;
USRStatus.Removed = 64;
USRStatus.constList = [
  "Archived",
  "Online",
  "Banned",
  "Fired",
  "Internal",
  "Authenticated",
  "Removed",
];
USRStatus.prefix = "USR";
window["USRStatus"] = USRStatus;
class USGStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
USGStatus.Archived = 1;
USGStatus.Removed = 2;
USGStatus.constList = ["Archived", "Removed"];
USGStatus.prefix = "USG";
window["USGStatus"] = USGStatus;
class UTLStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
UTLStatus.Archived = 1;
UTLStatus.Removed = 2;
UTLStatus.constList = ["Archived", "Removed"];
UTLStatus.prefix = "UTL";
window["UTLStatus"] = UTLStatus;
class CARDStatus extends Status {
  isShowAge(v) {
    v === undefined && (v = this._value);
    return (v & 1) !== 0;
  }
  isShowCity(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isShowPhoneNumber(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isShowEmail(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isShowEducation(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isShowWork(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
}
CARDStatus.ShowAge = 1;
CARDStatus.ShowCity = 2;
CARDStatus.ShowPhoneNumber = 4;
CARDStatus.ShowEmail = 8;
CARDStatus.ShowEducation = 16;
CARDStatus.ShowWork = 32;
CARDStatus.constList = [
  "ShowAge",
  "ShowCity",
  "ShowPhoneNumber",
  "ShowEmail",
  "ShowEducation",
  "ShowWork",
];
CARDStatus.prefix = "CARD";
window["CARDStatus"] = CARDStatus;
class NTFYStatus extends Status {
  isStudentReceiveMessagesCreated(v) {
    v === undefined && (v = this._value);
    return (v & 1) !== 0;
  }
  isStudentReceiveCommentsCreated(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isReceiveLessonActivated(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isReceiveHometaskAnswerRejected(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isReceiveHometaskAnswerConfirmed(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isOrgReceiveMessagesCreated(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isOrgReceiveCommentsCreated(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isReceiveCourseUnderConsideration(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isReceiveCourseConfirmed(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isReceiveCourseRejected(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isReceiveMemberCreated(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isReceiveHometaskAnswerCreated(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
  isReceiveHometaskAnswerUpdated(v) {
    v === undefined && (v = this._value);
    return (v & 4096) !== 0;
  }
  isReceiveReviewCreated(v) {
    v === undefined && (v = this._value);
    return (v & 8192) !== 0;
  }
  isReceiveReviewAnswerCreated(v) {
    v === undefined && (v = this._value);
    return (v & 16384) !== 0;
  }
  isReceiveReviewUpdated(v) {
    v === undefined && (v = this._value);
    return (v & 32768) !== 0;
  }
  isReceiveReviewAnswerUpdated(v) {
    v === undefined && (v = this._value);
    return (v & 65536) !== 0;
  }
  isReceiveReviewRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 131072) !== 0;
  }
  isReceiveReviewAnswerRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 262144) !== 0;
  }
  isReceiveTransactionCreated(v) {
    v === undefined && (v = this._value);
    return (v & 524288) !== 0;
  }
  isReceiveTransactionAccepted(v) {
    v === undefined && (v = this._value);
    return (v & 1048576) !== 0;
  }
  isReceiveTransactionAcceptedLocally(v) {
    v === undefined && (v = this._value);
    return (v & 2097152) !== 0;
  }
  isReceiveTransactionDeclined(v) {
    v === undefined && (v = this._value);
    return (v & 4194304) !== 0;
  }
  isReceiveTransactionSecured(v) {
    v === undefined && (v = this._value);
    return (v & 8388608) !== 0;
  }
  isReceiveTransactionWaitingForLocalConfirmation(v) {
    v === undefined && (v = this._value);
    return (v & 16777216) !== 0;
  }
  isReceiveTransactionWaitingForRemoteConfirmation(v) {
    v === undefined && (v = this._value);
    return (v & 33554432) !== 0;
  }
  isReceiveStudyGroupAutoFinishRejected(v) {
    v === undefined && (v = this._value);
    return (v & 67108864) !== 0;
  }
  isAllowIncomingCalls(v) {
    v === undefined && (v = this._value);
    return (v & 134217728) !== 0;
  }
}
NTFYStatus.StudentReceiveMessagesCreated = 1;
NTFYStatus.StudentReceiveCommentsCreated = 2;
NTFYStatus.ReceiveLessonActivated = 4;
NTFYStatus.ReceiveHometaskAnswerRejected = 8;
NTFYStatus.ReceiveHometaskAnswerConfirmed = 16;
NTFYStatus.OrgReceiveMessagesCreated = 32;
NTFYStatus.OrgReceiveCommentsCreated = 64;
NTFYStatus.ReceiveCourseUnderConsideration = 128;
NTFYStatus.ReceiveCourseConfirmed = 256;
NTFYStatus.ReceiveCourseRejected = 512;
NTFYStatus.ReceiveMemberCreated = 1024;
NTFYStatus.ReceiveHometaskAnswerCreated = 2048;
NTFYStatus.ReceiveHometaskAnswerUpdated = 4096;
NTFYStatus.ReceiveReviewCreated = 8192;
NTFYStatus.ReceiveReviewAnswerCreated = 16384;
NTFYStatus.ReceiveReviewUpdated = 32768;
NTFYStatus.ReceiveReviewAnswerUpdated = 65536;
NTFYStatus.ReceiveReviewRemoved = 131072;
NTFYStatus.ReceiveReviewAnswerRemoved = 262144;
NTFYStatus.ReceiveTransactionCreated = 524288;
NTFYStatus.ReceiveTransactionAccepted = 1048576;
NTFYStatus.ReceiveTransactionAcceptedLocally = 2097152;
NTFYStatus.ReceiveTransactionDeclined = 4194304;
NTFYStatus.ReceiveTransactionSecured = 8388608;
NTFYStatus.ReceiveTransactionWaitingForLocalConfirmation = 16777216;
NTFYStatus.ReceiveTransactionWaitingForRemoteConfirmation = 33554432;
NTFYStatus.ReceiveStudyGroupAutoFinishRejected = 67108864;
NTFYStatus.AllowIncomingCalls = 134217728;
NTFYStatus.constList = [
  "StudentReceiveMessagesCreated",
  "StudentReceiveCommentsCreated",
  "ReceiveLessonActivated",
  "ReceiveHometaskAnswerRejected",
  "ReceiveHometaskAnswerConfirmed",
  "OrgReceiveMessagesCreated",
  "OrgReceiveCommentsCreated",
  "ReceiveCourseUnderConsideration",
  "ReceiveCourseConfirmed",
  "ReceiveCourseRejected",
  "ReceiveMemberCreated",
  "ReceiveHometaskAnswerCreated",
  "ReceiveHometaskAnswerUpdated",
  "ReceiveReviewCreated",
  "ReceiveReviewAnswerCreated",
  "ReceiveReviewUpdated",
  "ReceiveReviewAnswerUpdated",
  "ReceiveReviewRemoved",
  "ReceiveReviewAnswerRemoved",
  "ReceiveTransactionCreated",
  "ReceiveTransactionAccepted",
  "ReceiveTransactionAcceptedLocally",
  "ReceiveTransactionDeclined",
  "ReceiveTransactionSecured",
  "ReceiveTransactionWaitingForLocalConfirmation",
  "ReceiveTransactionWaitingForRemoteConfirmation",
  "ReceiveStudyGroupAutoFinishRejected",
  "AllowIncomingCalls",
];
NTFYStatus.prefix = "NTFY";
window["NTFYStatus"] = NTFYStatus;
class ASSTStatus extends Status {
  isCanEditTemplate(v) {
    v === undefined && (v = this._value);
    return (v & 1) !== 0;
  }
  isCanStartCourse(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isCanCheckHometask(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isCanChat(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isCanStreaming(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isAddToAllCourses(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isCanAddAssistant(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
}
ASSTStatus.CanEditTemplate = 1;
ASSTStatus.CanStartCourse = 2;
ASSTStatus.CanCheckHometask = 4;
ASSTStatus.CanChat = 8;
ASSTStatus.CanStreaming = 16;
ASSTStatus.AddToAllCourses = 32;
ASSTStatus.CanAddAssistant = 64;
ASSTStatus.constList = [
  "CanEditTemplate",
  "CanStartCourse",
  "CanCheckHometask",
  "CanChat",
  "CanStreaming",
  "AddToAllCourses",
  "CanAddAssistant",
];
ASSTStatus.prefix = "ASST";
window["ASSTStatus"] = ASSTStatus;
class LSNStatus extends Status {
  isActive(v) {
    v === undefined && (v = this._value);
    return (v & 1) !== 0;
  }
  isHasHometask(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
LSNStatus.Active = 1;
LSNStatus.HasHometask = 2;
LSNStatus.constList = ["Active", "HasHometask"];
LSNStatus.prefix = "LSN";
window["LSNStatus"] = LSNStatus;
class ANSStatus extends Status {
  isSendForCheck(v) {
    v === undefined && (v = this._value);
    return (v & 1) !== 0;
  }
  isConfirmed(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRejected(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isChecked(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
}
ANSStatus.SendForCheck = 1;
ANSStatus.Confirmed = 2;
ANSStatus.Rejected = 4;
ANSStatus.Checked = 8;
ANSStatus.constList = ["SendForCheck", "Confirmed", "Rejected", "Checked"];
ANSStatus.prefix = "ANS";
window["ANSStatus"] = ANSStatus;
class APLStatus extends Status {
  isIapCreated(v) {
    v === undefined && (v = this._value);
    return (v & 1) !== 0;
  }
  isIapPriceCreated(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isIapLocalizationCreated(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isIapScreenshotCreated(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isIapScreenshotCommited(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isIapReviewSubmitted(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isIapCompleted(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
}
APLStatus.IapCreated = 1;
APLStatus.IapPriceCreated = 2;
APLStatus.IapLocalizationCreated = 4;
APLStatus.IapScreenshotCreated = 8;
APLStatus.IapScreenshotCommited = 16;
APLStatus.IapReviewSubmitted = 32;
APLStatus.IapCompleted = 64;
APLStatus.constList = [
  "IapCreated",
  "IapPriceCreated",
  "IapLocalizationCreated",
  "IapScreenshotCreated",
  "IapScreenshotCommited",
  "IapReviewSubmitted",
  "IapCompleted",
];
APLStatus.prefix = "APL";
window["APLStatus"] = APLStatus;
window.kv = {
  services: {
    study_group: {
      can_finish_errors: {
        root: {
          already_finished: "Поток уже завершен",
          allocated_time_has_not_passed:
            "Время, отведенное на курс, еще не прошло",
          members_with_not_started_lessons: "Не все уроки стартовали",
          not_all_homework_is_checked: "Есть непроверенные домашние задания",
          registration_closed: "Регистрация на поток закрыта",
        },
      },
      const: [
        "Removed",
        "Started",
        "Finished",
        "Group",
        "Paid",
        "HasGroupChat",
        "HasChatWithOrg",
        "HasChatBetweenMembers",
        "HasRating",
        "AvailableImmediately",
        "MoneyWithdrawn",
        "StartAfterRegistration",
        "DisplayReviews",
        "MoneyWithdrawStarted",
        "ArchivedForFront",
        "AvailableInStore",
        "RegistrationAvailable",
        "WithoutCheckHometask",
        "ShowParticipantCount",
        "SellAtApple",
        "HasCallsWithOrg",
        "HasCallsBetweenMembers",
      ],
      custom_const: {
        APL: [
          "IapCreated",
          "IapPriceCreated",
          "IapLocalizationCreated",
          "IapScreenshotCreated",
          "IapScreenshotCommited",
          "IapReviewSubmitted",
          "IapCompleted",
        ],
      },
      debug_level: 2,
      elastic: {
        state: {
          mapping: {
            status: "integer",
            created_at: "date",
            created_by: "term",
            created_by_lastname: "short_text",
            created_by_firstname: "short_text",
            created_by_status: "integer",
            started_at: "date",
            finished_at: "date",
            title: "short_text",
            description: "long_text",
            crs_id: "term",
            crs_id_title: "short_text",
            crs_id_description: "long_text",
            course_price: { amount: "integer", currency: "term" },
            start_type: "term",
            interval: "term",
            member_count: "integer",
            max_member_count: "integer",
            type: "term",
            crs_id_category: "term",
            crs_id_unique_view_count: "integer",
            crs_id_avg_rating: "double",
            view_count: "integer",
            course_type: "term",
            available_until: "date",
            register_before: "date",
            apple_status: "integer",
            assistants: {
              cl_id: "term",
              status: "integer",
              created_at: "date",
            },
          },
          resolve: {
            query: {
              fields: [
                "created_by.last_name",
                "created_by.first_name",
                "created_by.status",
                "crs_id.title",
                "crs_id.description",
                "crs_id.category",
                "crs_id.avg_rating",
                "crs_id.unique_view_count",
              ],
            },
            fields: [
              {
                new_field: "created_by_lastname",
                field: "created_by",
                path: "last_name",
              },
              {
                new_field: "created_by_firstname",
                field: "created_by",
                path: "first_name",
              },
              {
                new_field: "created_by_middlename",
                field: "created_by",
                path: "status",
              },
              { new_field: "crs_id_title", field: "crs_id", path: "title" },
              {
                new_field: "crs_id_description",
                field: "crs_id",
                path: "description",
              },
              {
                new_field: "crs_id_category",
                field: "crs_id",
                path: "category",
              },
              {
                new_field: "crs_id_avg_rating",
                field: "crs_id",
                path: "avg_rating",
              },
              {
                new_field: "crs_id_unique_view_count",
                field: "crs_id",
                path: "unique_view_count",
              },
            ],
          },
          function_score: {
            field_value_factor: {
              field: "crs_id_unique_view_count",
              modifier: "none",
              missing: 1,
            },
            boost_mode: "multiply",
          },
          pipeline: {
            id: "stidy_group_fix_null_value",
            body: {
              processors: [
                {
                  set: {
                    field: "crs_id_unique_view_count",
                    value: 0,
                    override: false,
                  },
                },
                {
                  set: {
                    field: "crs_id_avg_rating",
                    value: -1,
                    override: false,
                  },
                },
                {
                  script: {
                    lang: "painless",
                    ignore_failure: true,
                    source:
                      "if (ctx['course_type'] == 'group') {ctx['available_until'] = ctx['started_at'];} else {if (ctx['type'] != 'child') {ctx['available_until'] = ctx['register_before'];}}",
                  },
                },
              ],
            },
          },
        },
      },
      foreign_const: ["course", "member", "chat", "client", "chat_member"],
      lesson_interval: {
        "once-a-day": [1, "days"],
        "once-a-week": [1, "weeks"],
        test: [5, "minutes"],
      },
      lesson_interval_dictionary: {
        root: {
          "once-a-day": "Раз в сутки",
          "once-a-week": "Раз в неделю",
          test: "Каждые 5 минут",
        },
      },
      notification_list: [
        "StudyGroupStarted",
        "StudyGroupFinished",
        "StudyGroupFinishedWithoutMembers",
        "StudyGroupCreated",
        "StudyGroupAutoFinishRejected",
        "StudyGroupRemoved",
        "StudyGroupTotalUnreadMessageCountIncremented",
        "StudyGroupTotalUnreadMessageCountDecremented",
        "StudyGroupAssistantTotalUnreadMessageCountIncremented",
        "StudyGroupAssistantTotalUnreadMessageCountDecremented",
        "StudyGroupAssistantAdded",
        "StudyGroupAssistantRemoved",
        "StudyGroupAssistantPermissionsSet",
      ],
      pg: {
        tables: [
          {
            name: "study_group",
            drop_cascade: true,
            structure: {
              fields: [
                { name: "_id", type: "varchar(256)", options: "NOT NULL" },
                { name: "_hid", type: "bigint" },
                { name: "status", type: "INT" },
                { name: "_offset", type: "bigint" },
                { name: "crs_id", type: "varchar(256)" },
                { name: "created_by", type: "varchar(256)" },
                { name: "created_at", type: "timestamp with time zone" },
                { name: "title", type: "text" },
                { name: "interval", type: "varchar(256)" },
                { name: "start_type", type: "varchar(256)" },
                { name: "max_member_count", type: "INT" },
                { name: "member_count", type: "INT" },
                { name: "started_at", type: "timestamp with time zone" },
                { name: "finished_at", type: "timestamp with time zone" },
                { name: "cht_id", type: "varchar(256)" },
                { name: "lessons", type: "jsonb[]" },
                { name: "course_type", type: "varchar(256)" },
                { name: "type", type: "varchar(256)" },
                { name: "sg_id", type: "varchar(256)" },
                { name: "withdraw_tr_id", type: "varchar(256)" },
              ],
              options: ["constraint pk_study_group primary key (_id)"],
              indices: [{ field: "crs_id" }, { field: "sg_id" }],
            },
            maintenance_schedule: [
              {
                name: "vacuum_full",
                at: "01:21:00Z",
                interval: 604800,
                command: "VACUUM (full, analyze) %table",
              },
              {
                name: "vacuum_analyze",
                at: "11:03:00Z",
                interval: 86400,
                command: "VACUUM (analyze) %table",
              },
            ],
          },
          {
            name: "study_group_assistant",
            data_processor: ["study_group_assistant"],
            structure: {
              fields: [
                { name: "_id", type: "varchar(256)", options: "NOT NULL" },
                { name: "cl_id", type: "varchar(256)" },
                { name: "status", type: "INT" },
              ],
              options: [
                "constraint pk_study_group_assistant primary key (_id, cl_id)",
              ],
              indices: [{ field: "_id" }, { field: "cl_id" }],
            },
            maintenance_schedule: [
              {
                name: "vacuum_full",
                at: "01:23:00Z",
                interval: 604800,
                command: "VACUUM (full, analyze) %table",
              },
              {
                name: "vacuum_analyze",
                at: "11:05:00Z",
                interval: 86400,
                command: "VACUUM (analyze) %table",
              },
            ],
          },
        ],
      },
      prefix: "SG",
      promocode_count: 5,
      resolve: {
        notify: [
          "_id",
          "title",
          "course_type",
          "author_img_id.0.url",
          "author_img_id.0.small_img_url",
          "author_nickname",
          "member_count",
          "started_at",
          "finished_at",
          "status",
          "counter",
          "mb_id",
          "cl_id.last_name",
          "cl_id.first_name",
          "cl_id.nickname",
          "cl_id._id",
          "total_unread_message_count",
        ],
      },
    },
    course: {
      categories: {
        root: {
          development: "Разработка",
          business: "Бизнес",
          "finance-and-accounting": "Финансы и бухгалтерский учет",
          "IT-and-software": "ИТ и ПО",
          "personal growth": "Личностный рост",
          design: "Дизайн",
          marketing: "Маркетинг",
          "life-style": "Стиль жизни",
          "photography-and-video": "Фотография и видео",
          "health-and-fitness": "Здоровье и фитнес",
          music: "Музыка",
          academic: "Учебные и академические дисциплины",
        },
      },
      category_groups: {
        localization: {
          root: {
            financy: "Финансы",
            "personal-growth": "Саморазвитие",
            "beauty-and-health": "Красота и здоровье",
            creation: "Творчество",
            profession: "Профессия",
            "life-style": "Быт",
          },
        },
        images: {
          financy:
            "https://storage.yandexcloud.net/udeu/menuimg/*/s-wallet.png",
          "personal-growth":
            "https://storage.yandexcloud.net/udeu/menuimg/*/s-self.png",
          "beauty-and-health":
            "https://storage.yandexcloud.net/udeu/menuimg/*/s-beauty.png",
          creation:
            "https://storage.yandexcloud.net/udeu/menuimg/*/s-creative.png",
          profession:
            "https://storage.yandexcloud.net/udeu/menuimg/*/s-prof.png",
          "life-style":
            "https://storage.yandexcloud.net/udeu/menuimg/*/s-househ.png",
        },
      },
      const: [
        "Removed",
        "Confirmed",
        "Rejected",
        "UnderConsideration",
        "PendingConsideration",
        "Hidden",
        "Promo",
      ],
      elastic: {
        state: {
          mapping: {
            status: "integer",
            created_at: "date",
            created_by: "term",
            created_by_lastname: "short_text",
            created_by_firstname: "short_text",
            created_by_status: "integer",
            started_at: "date",
            finished_at: "term",
            title: "short_text",
            description: "long_text",
            unique_view_count: "integer",
            avg_rating: "double",
          },
          resolve: {
            query: {
              fields: [
                "created_by.last_name",
                "created_by.first_name",
                "created_by.status",
              ],
            },
            fields: [
              {
                new_field: "created_by_lastname",
                field: "created_by",
                path: "last_name",
              },
              {
                new_field: "created_by_firstname",
                field: "created_by",
                path: "first_name",
              },
              {
                new_field: "created_by_middlename",
                field: "created_by",
                path: "status",
              },
            ],
          },
        },
      },
      foreign_const: ["member", "study_group", "client"],
      metrics: ["course_unique_view_count"],
      notification_list: [
        "CourseUnderConsiderationSet",
        "CourseConfirmed",
        "CourseRejected",
      ],
      pg: {
        tables: [
          {
            structure: {
              fields: [
                { name: "_id", type: "varchar(256)", options: "NOT NULL" },
                { name: "_hid", type: "bigint" },
                { name: "status", type: "INT" },
                { name: "_offset", type: "bigint" },
                { name: "created_by", type: "varchar(256)" },
                { name: "created_at", type: "timestamp with time zone" },
                { name: "updated_by", type: "varchar(256)" },
                { name: "updated_at", type: "timestamp with time zone" },
                { name: "confirmed_by", type: "varchar(256)" },
                { name: "confirmed_at", type: "timestamp with time zone" },
                { name: "title", type: "text" },
                { name: "started_at", type: "timestamp with time zone" },
              ],
              options: ["constraint pk_course primary key (_id)"],
              indices: [{ field: "created_by" }],
            },
            maintenance_schedule: [
              {
                name: "vacuum_full",
                at: "01:15:00Z",
                interval: 604800,
                command: "VACUUM (full, analyze) %table",
              },
              {
                name: "vacuum_analyze",
                at: "11:00:00Z",
                interval: 86400,
                command: "VACUUM (analyze) %table",
              },
            ],
          },
        ],
      },
      prefix: "CRS",
      resolve: {
        state: [
          "_id",
          "title",
          "description",
          "created_by._id",
          "created_by.first_name",
          "created_by.last_name",
          "created_at",
          "confirmed_by._id",
          "confirmed_by.first_name",
          "confirmed_by.last_name",
          "confirmed_at",
          "updated_by._id",
          "updated_by.first_name",
          "updated_by.last_name",
          "updated_at",
          "intro_blocks.*.type",
          "intro_blocks.*.value._id",
          "intro_blocks.*.value.filename",
          "intro_blocks.*.value.small_filename",
          "intro_blocks.*.value.medium_filename",
          "lessons.*.title",
          "lessons.*.blocks.*.type",
          "lessons.*.blocks.*.value",
          "lessons.*.blocks.*.value._id",
          "lessons.*.blocks.*.value.filename",
          "lessons.*.blocks.*.value.small_filename",
          "lessons.*.blocks.*.value.medium_filename",
          "lessons.*.hometasks.*.blocks.*.type",
          "lessons.*.hometasks.*.blocks.*.value",
          "lessons.*.hometasks.*.blocks.*.value._id",
          "lessons.*.hometasks.*.blocks.*.value.filename",
          "lessons.*.hometasks.*.blocks.*.value.small_filename",
          "lessons.*.hometasks.*.blocks.*.value.medium_filename",
          "final_lesson.*.title",
          "final_lesson.*.blocks.*.type",
          "final_lesson.*.blocks.*.type",
          "final_lesson.*.blocks.*.value",
          "final_lesson.*.blocks.*.value._id",
          "final_lesson.*.blocks.*.value.filename",
          "final_lesson.*.blocks.*.value.small_filename",
          "final_lesson.*.blocks.*.value.medium_filename",
          "final_lesson.*.hometasks.*.blocks.*.type",
          "final_lesson.*.hometasks.*.blocks.*.value",
          "final_lesson.*.hometasks.*.blocks.*.value._id",
          "final_lesson.*.hometasks.*.blocks.*.value.filename",
          "final_lesson.*.hometasks.*.blocks.*.value.small_filename",
          "final_lesson.*.hometasks.*.blocks.*.value.medium_filename",
          "introductory_lesson.*.title",
          "introductory_lesson.*.blocks.*.type",
          "introductory_lesson.*.blocks.*.type",
          "introductory_lesson.*.blocks.*.value",
          "introductory_lesson.*.blocks.*.value._id",
          "introductory_lesson.*.blocks.*.value.filename",
          "introductory_lesson.*.blocks.*.value.small_filename",
          "introductory_lesson.*.blocks.*.value.medium_filename",
          "introductory_lesson.*.hometasks.*.blocks.*.type",
          "introductory_lesson.*.hometasks.*.blocks.*.value",
          "introductory_lesson.*.hometasks.*.blocks.*.value._id",
          "introductory_lesson.*.hometasks.*.blocks.*.value.filename",
          "introductory_lesson.*.hometasks.*.blocks.*.value.small_filename",
          "introductory_lesson.*.hometasks.*.blocks.*.value.medium_filename",
          "status",
          "removed_at",
          "update_count",
        ],
        notify: [
          "_id",
          "title",
          "rejected_at",
          "reject_comment",
          "confirmed_at",
          "status",
        ],
      },
    },
    transaction: {
      apple: {
        app_id: "1574167472",
        shared_secret: "5b4dfe63cf764f608805e683de60ac2a",
        deposit: {
          mode: "dev",
          dev: { check_url: "https://sandbox.itunes.apple.com/verifyReceipt" },
          prod: { check_url: "https://buy.itunes.apple.com/verifyReceipt" },
        },
        withdraw_delay: [30, "days"],
      },
      autodecline_interval: [1, "minutes"],
      const: [
        "Removed",
        "First",
        "Accepted",
        "Secured",
        "Declined",
        "Rejected",
        "Failed",
        "AcceptedLocally",
        "WaitingForLocalConfirmation",
        "WaitingForRemoteConfirmation",
        "WaitingForPaymentInfo",
        "DeclinedLocally",
        "Holded",
        "WaitingForRemoteRefund",
        "Refunded",
        "Authorized",
      ],
      elastic: {
        state: {
          refresh: true,
          mapping: {
            status: "integer",
            initiator: {
              sg_id: "term",
              group_title: "short_text",
              group_parent_id: "term",
              group_status: "integer",
              group_created_by_id: "term",
              group_crs_id: "term",
              group_created_by_displayname: "short_text",
              group_created_by_firstname: "short_text",
              group_created_by_lastname: "short_text",
              group_created_by_nickname: "short_text",
            },
            cl_id: "term",
            client_lastname: "short_text",
            client_firstname: "short_text",
            client_nickname: "short_text",
            client_displayname: "short_text",
            currency: "term",
            external: "boolean",
            action: "term",
            amount: "long",
            created_at: "date",
            local_accepted_at: "date",
            accepted_at: "date",
            secured_at: "date",
            service: "term",
            payment: "term",
            commission: "long",
            withdraw_amount: "long",
            withdraw_commission: "long",
          },
          pipeline: {
            id: "transaction_fix_null_value",
            body: {
              processors: [
                {
                  set: { field: "client_lastname", value: "", override: false },
                },
                {
                  set: {
                    field: "client_firstname",
                    value: "",
                    override: false,
                  },
                },
                {
                  set: { field: "client_nickname", value: "", override: false },
                },
                {
                  set: {
                    field: "initiator.group_created_by_firstname",
                    value: "",
                    override: false,
                  },
                },
                {
                  set: {
                    field: "initiator.group_created_by_lastname",
                    value: "",
                    override: false,
                  },
                },
                {
                  set: {
                    field: "initiator.group_created_by_nickname",
                    value: "",
                    override: false,
                  },
                },
                {
                  script: {
                    lang: "painless",
                    ignore_failure: true,
                    source:
                      "if (ctx['initiator']['group_created_by_nickname'] == '') {String str = ctx['initiator']['group_created_by_firstname'] + ' ' + ctx['initiator']['group_created_by_lastname']; str = str.trim(); ctx['initiator']['group_created_by_displayname'] = str;} else {ctx['initiator']['group_created_by_displayname'] = ctx['initiator']['group_created_by_nickname'];}",
                  },
                },
                {
                  script: {
                    lang: "painless",
                    ignore_failure: true,
                    source:
                      "if (ctx['client_nickname'] == '') {String str = ctx['client_firstname'] + ' ' + ctx['client_lastname']; str = str.trim(); ctx['client_displayname'] = str;} else {ctx['client_displayname'] = ctx['client_nickname'];}",
                  },
                },
              ],
            },
          },
          resolve: {
            query: {
              fields: [
                "cl_id.last_name",
                "cl_id.first_name",
                "cl_id.nickname",
                "initiator.sg_id.title",
                "initiator.sg_id.sg_id",
                "initiator.sg_id.status",
                "initiator.sg_id.created_by._id",
                "initiator.sg_id.created_by.first_name",
                "initiator.sg_id.created_by.last_name",
                "initiator.sg_id.created_by.nickname",
                "initiator.sg_id.crs_id",
              ],
            },
            fields: [
              {
                new_field: "client_lastname",
                field: "cl_id",
                path: "last_name",
              },
              {
                new_field: "client_firstname",
                field: "cl_id",
                path: "first_name",
              },
              {
                new_field: "client_nickname",
                field: "cl_id",
                path: "nickname",
              },
              {
                new_field: "initiator.group_title",
                field: "initiator",
                path: "sg_id.title",
              },
              {
                new_field: "initiator.group_parent_id",
                field: "initiator",
                path: "sg_id.sg_id",
              },
              {
                new_field: "initiator.group_status",
                field: "initiator",
                path: "sg_id.status",
              },
              {
                new_field: "initiator.group_created_by_id",
                field: "initiator",
                path: "sg_id.created_by._id",
              },
              {
                new_field: "initiator.group_crs_id",
                field: "initiator",
                path: "sg_id.crs_id",
              },
              {
                new_field: "initiator.group_created_by_firstname",
                field: "initiator",
                path: "sg_id.created_by.last_name",
              },
              {
                new_field: "initiator.group_created_by_lastname",
                field: "initiator",
                path: "sg_id.created_by.first_name",
              },
              {
                new_field: "initiator.group_created_by_nickname",
                field: "initiator",
                path: "sg_id.created_by.nickname",
              },
            ],
          },
        },
      },
      foreign_const: ["client", "kyc"],
      notification_list: [
        "TransactionCreated",
        "TransactionAccepted",
        "TransactionAcceptedLocally",
        "TransactionDeclined",
        "TransactionDeclinedLocally",
        "TransactionSecured",
        "TransactionFailed",
        "TransactionRefunded",
      ],
      pg: {
        tables: [
          {
            name: "transaction",
            data_processor: ["transaction"],
            structure: {
              fields: [
                { name: "_id", type: "varchar(256)", options: "NOT NULL" },
                { name: "_hid", type: "bigint" },
                { name: "_offset", type: "bigint" },
                { name: "status", type: "integer" },
                { name: "service", type: "varchar(256)" },
                { name: "currency", type: "varchar(256)" },
                { name: "action", type: "varchar(256)" },
                { name: "amount", type: "bigint" },
                { name: "created_at", type: "timestamp with time zone" },
                { name: "secured_at", type: "timestamp with time zone" },
                { name: "local_accepted_at", type: "timestamp with time zone" },
                { name: "cl_id", type: "varchar(256)" },
                { name: "initiator_sg_id", type: "varchar(256)" },
                { name: "payment", type: "varchar(256)" },
                { name: "commission", type: "bigint" },
                { name: "withdraw_amount", type: "bigint" },
                { name: "withdraw_commission", type: "bigint" },
              ],
              options: ["constraint pk_transaction primary key (_id)"],
              indices: [{ field: "cl_id" }, { field: "initiator_sg_id" }],
            },
            vacuum_schedule: { at: "04:45:00Z", interval: 604800 },
            maintenance_schedule: [
              {
                name: "vacuum_full",
                at: "04:45:00Z",
                interval: 604800,
                command: "VACUUM (full, analyze) %table",
              },
              {
                name: "vacuum_analyze",
                at: "16:00:00Z",
                interval: 86400,
                command: "VACUUM (analyze) %table",
              },
            ],
          },
        ],
      },
      prefix: "TR",
      resolve: {
        notify: [
          "_id",
          "action",
          "initiator.sg_id._id",
          "initiator.sg_id.title",
          "initiator.sg_id.sg_id",
          "initiator.sg_id.created_at",
          "initiator.mb_id",
          "cl_id.last_name",
          "cl_id.first_name",
          "cl_id.nickname",
          "cl_id._id",
          "cl_id.status",
          "status",
          "local_accepted_at",
          "declined_at",
          "declined_reason",
          "failed_at",
          "failed_reason",
          "error_code",
          "secured_at",
          "accepted_at",
          "amount",
          "currency",
          "real_amount",
        ],
      },
      target_notification_list: { TransactionCreated: { target_field: [] } },
      tinkoff: {
        notification_mask: "91.194.226.0/23",
        account_number: "40702810310000924225",
        deposit: {
          mode: "dev",
          prod: { password: "l1uwmmwrd3e0f7c0", terminal_key: "1642496460072" },
          dev: {
            password: "5vcq1ay9zplhhfv2",
            terminal_key: "1642496460072DEMO",
          },
          init_url: "https://securepay.tinkoff.ru/v2/Init",
          confirm_url: "https://securepay.tinkoff.ru/v2/Confirm",
          cancel_url: "https://securepay.tinkoff.ru/v2/Cancel",
          get_card_list_url: "https://securepay.tinkoff.ru/v2/GetCardList",
        },
        withdraw: {
          mode: "prod",
          prod: {
            password: "l1uwmmwrd3e0f7c0",
            terminal_key: "1647964258593E2C",
          },
          dev: {
            password: "5vcq1ay9zplhhfv2",
            terminal_key: "1647964258593E2CDEMO",
          },
          init_url: "https://securepay.tinkoff.ru/e2c/v2/Init/",
          payment_url: "https://securepay.tinkoff.ru/e2c/v2/Payment/",
          access_token:
            "t.knyFQjRqMjuqscR9BPeuN3-dhc_461wcIlsE-RRYoXJZCkXgIR1N4qKacsRUCbhI_H5CUPek3bOSspLeLuJZCA",
          check_customer_url:
            "https://business.tinkoff.ru/openapi/api/v1/safe-deal/beneficiaries",
          check_result_url:
            "https://business.tinkoff.ru/openapi/api/v1/safe-deal/beneficiaries/result",
          add_beneficiary_url:
            "https://secured-openapi.business.tinkoff.ru/api/v1/nominal-accounts/beneficiaries",
          check_beneficiary_result_url:
            "https://secured-openapi.business.tinkoff.ru/api/v2/nominal-accounts/beneficiaries/scoring",
          get_card_list_url: "https://securepay.tinkoff.ru/e2c/v2/GetCardList",
          add_card_url: "https://securepay.tinkoff.ru/e2c/v2/AddCard",
          remove_card_url: "https://securepay.tinkoff.ru/e2c/v2/RemoveCard",
          add_customer_url: "https://securepay.tinkoff.ru/e2c/v2/AddCustomer",
          remove_customer_url:
            "https://securepay.tinkoff.ru/e2c/v2/RemoveCustomer",
          get_customer_url: "https://securepay.tinkoff.ru/e2c/v2/GetCustomer",
          get_payment_list_url:
            "https://business.tinkoff.ru/openapi/api/v1/bank-statement",
          get_accounts_url:
            "https://business.tinkoff.ru/openapi/api/v3/bank-accounts",
        },
        errors: {
          1: "Параметры не сопоставлены",
          2: "Отсутствуют обязательные параметры",
          3: "Внутренняя ошибка системы интернет эквайринга",
          4: "Запрашиваемое состояние транзакции является неверным",
          5: "Неверный запрос",
          6: "Неверный статус карты",
          7: "Покупатель не найден",
          8: "Неверный статус транзакции",
          10: "Метод Charge заблокирован для данного терминала",
          11: "Невозможно выполнить платеж",
          12: "Неверный параметр RedirectDueDate",
          13: "Оплата с мобильного телефона недоступна",
          14: "Платеж неверный",
          18: "Не удалось осуществить платеж через MC",
          20: "Ошибка повторного идентификатора заказа",
          50: "Ошибка отправки нотификации",
          51: "Ошибка отправки Email",
          52: "Ошибка отправки Sms",
          53: "Обратитесь к продавцу",
          97: "Ошибка Jasper",
          98: "Ошибка SubExt",
          99: "Платеж отклонен",
          100: "Повторите попытку позже",
          101: "Не пройдена идентификация 3DS",
          102: "Операция отклонена, пожалуйста обратитесь в интернет-магазин или воспользуйтесь другой картой",
          103: "Повторите попытку позже",
          104: "Ошибка выполения рекуррента",
          105: "По картам Maestro невозможно провести рекуррентный платеж",
          106: "Карта не поддерживает 3DS проверку. Попробуйте другую карту",
          107: "Неверно введен CardId. Проверьте, что такая карта была ранее привязана",
          108: "Оплата разрешена только по 3DS картам. Попробуйте другую карту",
          109: "Не найден dsTranId для сессии",
          110: "Не передан cres",
          111: "Передан некорректный cres",
          119: "Превышено кол-во запросов на авторизацию",
          191: "Некорректный статус договора, обратитесь к вашему менеджеру",
          202: "Терминал заблокирован",
          203: "Параметры запроса не должны быть пустыми",
          204: "Неверный токен. Проверьте пару TerminalKey/SecretKey",
          206: "Email не может быть пустым",
          208: "Наименование ключа из параметра DATA превышает максимально допустимый размер",
          209: "Значение ключа из параметра DATA превышает максимально допустимый размер",
          211: "Неверный формат IP",
          219: "Неверный срок действия карты",
          224: "Неверный формат Email",
          243: "Ошибка шифрования карточных данных",
          244: "Ошибка сопоставления карточных данных",
          245: "Параметр AddCard не сопоставлен",
          246: "Параметр SendEmail не сопоставлен",
          247: "Параметр Amount не сопоставлен",
          248: "Параметр CVV не сопоставлен",
          249: "Параметр Currency не сопоставлен",
          250: "Параметр DATA не сопоставлен",
          252: "Срок действия карты истек",
          254: "Дополнительные возможности отключены",
          255: "Платеж не найден",
          258: "Неверный параметр Ean13",
          259: "Параметр EncryptedPaymentData не сопоставлен",
          300: "Ошибка регистрации чека в АТОЛ",
          301: "Ошибка получения статуса чека из АТОЛ",
          302: "Ошибка получения токена из АТОЛ",
          303: "Неверные логин или пароль",
          304: "Неверный код группы",
          305: "Ошибка проверки поля",
          306: "Ошибка связи АТОЛ",
          307: "Ошибка ответа АТОЛ",
          308: "Суммы в чеке и в платеже не совпадают",
          309: "Поле Receipt не должно быть пустым",
          311: "Ошибка регистрации чека в Receipt Service",
          312: "Ошибка получения чека из Receipt Service",
          313: "Ошибка создания организации в Receipt Service",
          314: "Ошибка создания кассы в Receipt Service",
          315: "Касса не найдена",
          316: "Максимальная длина номера телефона 19 символов",
          317: "Неверное значение поля agentSign",
          318: "Поле AgentSign не должно быть пустым",
          319: "Поле SupplierInfo не должно быть пустым",
          320: "Поле Inn в объекте SupplierInfo не должно быть пустым",
          401: "Внутренняя ошибка системы",
          402: "Повторите попытку позже",
          500: "Добавление карты к данному терминалу запрещено",
          501: "Терминал не найден",
          502: "Карта по requestKey не найдена",
          503: "CustomerKey не найден",
          504: "Не удалось провести платеж при привязке карты",
          505: "Не удалось привязать карту. Внутренняя ошибка",
          507: "Карта не поддерживает 3DS проверку. Попробуйте другую карту",
          508: "Неверный номер карты",
          509: "Не удалось выполнить отмену при привязке карты",
          510: "Карта уже привязана к переданному CustomerKey",
          511: "Проверка 3DS не пройдена",
          512: "Не удалось выполнить запрос на проверку 3DS",
          513: "Не удалось выполнить платеж после прохождения 3DS",
          514: "Введена неверная сумма холдирования",
          515: "Внутренняя ошибка",
          600: "Интернет-магазин отклонил операцию по данной карте. Обратитесь в интернет-магазин для выяснения причин отказа в платеже",
          601: "Разрешены операции только по MasterCard",
          603: "Превышено количество попыток оплаты с данной карты",
          700: "Список карт Masterpass недоступен для данного магазина",
          701: "Сервис MasterPass недоступен",
          1001: "Свяжитесь с банком, выпустившим карту, чтобы провести платеж",
          1003: "Неверный merchant ID",
          1004: "Карта украдена. Свяжитесь с банком, выпустившим карту",
          1005: "Платеж отклонен банком, выпустившим карту",
          1006: "Свяжитесь с банком, выпустившим карту, чтобы провести платеж",
          1007: "Карта украдена. Свяжитесь с банком, выпустившим карту",
          1008: "Платеж отклонен, необходима идентификация",
          1012: "Такие операции запрещены для этой карты",
          1013: "Повторите попытку позже",
          1014: "Карта недействительна. Свяжитесь с банком, выпустившим карту",
          1015: "Попробуйте снова или свяжитесь с банком, выпустившим карту",
          1019: "Платеж отклонен — попробуйте снова",
          1030: "Повторите попытку позже",
          1033: "Истек срок действия карты. Свяжитесь с банком, выпустившим карту",
          1034: "Попробуйте повторить попытку позже",
          1038: "Превышено количество попыток ввода ПИН-кода",
          1039: "Платеж отклонен — счет не найден",
          1041: "Карта утеряна. Свяжитесь с банком, выпустившим карту",
          1043: "Карта украдена. Свяжитесь с банком, выпустившим карту",
          1051: "Недостаточно средств на карте",
          1053: "Платеж отклонен — счет не найден",
          1054: "Истек срок действия карты",
          1055: "Неверный ПИН",
          1057: "Такие операции запрещены для этой карты",
          1058: "Такие операции запрещены для этой карты",
          1059: "Подозрение в мошенничестве. Свяжитесь с банком, выпустившим карту",
          1061: "Превышен дневной лимит платежей по карте",
          1062: "Платежи по карте ограничены",
          1063: "Операции по карте ограничены",
          1064: "Проверьте сумму",
          1065: "Превышен дневной лимит транзакций",
          1075: "Превышено число попыток ввода ПИН-кода",
          1076: "Платеж отклонен — попробуйте снова",
          1077: "Коды не совпадают — попробуйте снова",
          1080: "Неверный срок действия",
          1082: "Неверный CVV",
          1086: "Платеж отклонен — не получилось подтвердить ПИН-код",
          1088: "Ошибка шифрования. Попробуйте снова",
          1089: "Попробуйте повторить попытку позже",
          1091: "Банк, выпустивший карту недоступен для проведения авторизации",
          1092: "Платеж отклонен — попробуйте снова",
          1093: "Подозрение в мошенничестве. Свяжитесь с банком, выпустившим карту",
          1094: "Системная ошибка",
          1096: "Повторите попытку позже",
          1202: "Попробуйте повторить попытку позже",
          1203: "Воспользуйтесь другой картой или обратитесь к продавцу",
          2015: "Mерчант с таким именем и паролем не найден",
          2200: "Превышено допустимое количество запросов авторизации операции",
          3002: "Платеж отменить нельзя",
          3003: "Отмена платежа в связи с мошенничеством",
          9999: "Внутренняя ошибка системы",
        },
      },
    },
    client: {
      auth: {
        vk: {
          code_uri: "https://oauth.vk.com/authorize",
          token_uri: "https://oauth.vk.com/access_token",
          redirect_uri: "http://bh-proxy.nsolid.ru/auth/result/vk",
          client_id: 7414695,
          scope: "friends,offline",
          response_type: "code",
          v: "5.52",
          display: "page",
          client_secret: "sO5csc6plXmcFu9k7Rhw",
        },
      },
      const: [
        "Removed",
        "Authenticated",
        "Online",
        "ConfirmedSMS",
        "RegistrationCompleted",
        "Developer",
        "Fake",
        "Blocked",
        "TechnicalSupport",
        "Organizer",
        "CommonChatsDenied",
        "Verified",
        "CallsAllowed",
      ],
      create_command: "register",
      custom_const: {
        CARD: [
          "ShowAge",
          "ShowCity",
          "ShowPhoneNumber",
          "ShowEmail",
          "ShowEducation",
          "ShowWork",
        ],
        NTFY: [
          "StudentReceiveMessagesCreated",
          "StudentReceiveCommentsCreated",
          "ReceiveLessonActivated",
          "ReceiveHometaskAnswerRejected",
          "ReceiveHometaskAnswerConfirmed",
          "OrgReceiveMessagesCreated",
          "OrgReceiveCommentsCreated",
          "ReceiveCourseUnderConsideration",
          "ReceiveCourseConfirmed",
          "ReceiveCourseRejected",
          "ReceiveMemberCreated",
          "ReceiveHometaskAnswerCreated",
          "ReceiveHometaskAnswerUpdated",
          "ReceiveReviewCreated",
          "ReceiveReviewAnswerCreated",
          "ReceiveReviewUpdated",
          "ReceiveReviewAnswerUpdated",
          "ReceiveReviewRemoved",
          "ReceiveReviewAnswerRemoved",
          "ReceiveTransactionCreated",
          "ReceiveTransactionAccepted",
          "ReceiveTransactionAcceptedLocally",
          "ReceiveTransactionDeclined",
          "ReceiveTransactionSecured",
          "ReceiveTransactionWaitingForLocalConfirmation",
          "ReceiveTransactionWaitingForRemoteConfirmation",
          "ReceiveStudyGroupAutoFinishRejected",
          "AllowIncomingCalls",
        ],
        ASST: [
          "CanEditTemplate",
          "CanStartCourse",
          "CanCheckHometask",
          "CanChat",
          "CanStreaming",
          "AddToAllCourses",
          "CanAddAssistant",
        ],
      },
      debug_level: 1,
      elastic: {
        state: {
          mapping: {
            status: "integer",
            email: "short_text",
            first_name: "short_text",
            last_name: "short_text",
            nickname: "short_text",
            gender: "term",
            country: "term",
            phone_number: "short_text",
            registered_at: "date",
            charity: {
              title: "short_text",
              description: "long_text",
              email: "short_text",
            },
            ref_id: "term",
            display_name: "short_text",
            _suggest: "short_suggest",
          },
          settings: { elastic: { number_of_shards: 1, number_of_replicas: 0 } },
          pipeline: {
            id: "add_display_name",
            body: {
              processors: [
                { set: { field: "last_name", value: "", override: false } },
                { set: { field: "first_name", value: "", override: false } },
                {
                  script: {
                    lang: "painless",
                    ignore_failure: true,
                    source:
                      "if (ctx['nickname'] == null) {String lastname; if (ctx['last_name'] == null) {lastname = '';} else {lastname = ctx['last_name'];} String firstname; if (ctx['first_name'] == null) {firstname = '';} else {firstname = ctx['first_name'];} String str = firstname + ' ' + lastname; str = str.trim(); ctx['display_name'] = str;} else {ctx['display_name'] = ctx['nickname'];}",
                  },
                },
              ],
            },
          },
        },
      },
      foreign_const: ["session", "member", "study_group"],
      notification_list: [
        "ClientUnreadChatSet",
        "ClientUnreadChatUnset",
        "ClientUnreadStudentActivityCountIncremented",
        "ClientUnreadOrgActivityCountIncremented",
        "ClientUnreadStudentActivityCountDecremented",
        "ClientUnreadOrgActivityCountDecremented",
        "ClientСardLinkRejected",
        "ClientCardLinkCompleted",
        "ClientSubscriptionUpdated",
        "ClientWithdrawSumIncremented",
        "ClientAssistantAdded",
        "ClientAssistantRemoved",
        "ClientUnreadAssistantActivityCountIncremented",
        "ClientUnreadAssistantActivityCountDecremented",
      ],
      options: {
        try_time_per_ip: 5000,
        block_by_ip_interval: 60000,
        try_time: 10000,
        try_count: 10,
        block_interval: 600000,
        max_session_count: 5,
        captcha: false,
      },
      pg: {
        tables: [
          {
            name: "client",
            drop_cascade: true,
            structure: {
              fields: [
                { name: "_id", type: "varchar(256)", options: "NOT NULL" },
                { name: "_hid", type: "bigint" },
                { name: "status", type: "INT" },
                { name: "nickname", type: "varchar(256)" },
                { name: "first_name", type: "varchar(256)" },
                { name: "last_name", type: "varchar(256)" },
                { name: "gender", type: "varchar(2)" },
                { name: "country", type: "varchar(10)" },
                { name: "registered_at", type: "timestamp with time zone" },
                { name: "_offset", type: "bigint" },
                { name: "subscriber_count", type: "INT" },
                { name: "subscription_count", type: "INT" },
                { name: "helped_count", type: "INT" },
                { name: "unread_activity_count", type: "int" },
                { name: "unread_chat", type: "text[]" },
                { name: "support_cht_id", type: "varchar(256)" },
              ],
              options: ["constraint pk_client primary key (_id)"],
            },
            vacuum_schedule: { at: "01:45:00Z", interval: 604800 },
            maintenance_schedule: [
              {
                name: "vacuum_full",
                at: "01:45:00Z",
                interval: 604800,
                command: "VACUUM (full, analyze) %table",
              },
              {
                name: "vacuum_analyze",
                at: "11:30:00Z",
                interval: 86400,
                command: "VACUUM (analyze) %table",
              },
            ],
          },
          {
            name: "client_assistant",
            data_processor: ["client_assistant"],
            processed_events: [
              "ClientAssistantRemoved",
              "ClientAssistantAdded",
              "ClientAssistantPermissionsSet",
              "_CompensationReceived",
              "ClientAuthenticated",
            ],
            structure: {
              fields: [
                { name: "_id", type: "varchar(256)", options: "NOT NULL" },
                { name: "cl_id", type: "varchar(256)" },
                { name: "link", type: "varchar(8)" },
                { name: "status", type: "INT" },
              ],
              options: [
                "constraint pk_client_assistant primary key (_id, cl_id)",
              ],
              indices: [
                { field: "_id" },
                { field: "cl_id" },
                { field: "link" },
              ],
            },
            maintenance_schedule: [
              {
                name: "vacuum_full",
                at: "01:27:00Z",
                interval: 604800,
                command: "VACUUM (full, analyze) %table",
              },
              {
                name: "vacuum_analyze",
                at: "11:07:00Z",
                interval: 86400,
                command: "VACUUM (analyze) %table",
              },
            ],
          },
        ],
      },
      prefix: "CL",
      resolve: {
        notify: [
          "_id",
          "last_name",
          "first_name",
          "status",
          "nickname",
          "unread_activity_count",
          "notify_status",
          "unread_student_activity_count",
          "unread_org_activity_count",
          "unread_assistant_activity_count",
        ],
      },
      sms_gate: {
        greensms: {
          active: true,
          username: "fairywalls",
          password: "jYZ64J8BYG",
          sender_name: "Udeu",
          phone_mask: "^\\+79\\d{9}$",
          token_expire_time_sec: 60,
          try_time_per_phone_ms: 60000,
          try_count_per_interval: 10,
          try_interval_ms: 3600000,
          attempt_count: 4,
          actions: [
            {
              url: "https://api3.greensms.ru/sms/send",
              method: "POST",
              name: "send_sms",
              auth_order: 1,
            },
            {
              url: "https://api3.greensms.ru/call/send",
              method: "POST",
              name: "send_call",
              auth_order: 0,
            },
            {
              url: "https://api3.greensms.ru/account/token",
              method: "POST",
              name: "get_token",
              available: false,
            },
          ],
        },
        twilio: {
          active: false,
          account_sid: "AC23f1be134f9f34a10229ac9196a9d6bc",
          auth_token: "cb779cefe8f639d561c306e888d99e1c",
          phone_number: "+17657344229",
        },
      },
      state_pg: {
        maintenance_schedule: [
          {
            name: "vacuum_full",
            at: "01:20:00Z",
            interval: 604800,
            command: "VACUUM (full, analyze) %table",
          },
          {
            name: "vacuum_analyze",
            at: "09:20:00Z",
            interval: 86400,
            command: "VACUUM (analyze) %table",
          },
        ],
      },
      subscription: {
        base: {
          i18n: "Базовый",
          study_group: { max_paid_count: 3, max_free_count: 3 },
          max_file_size: 214748364,
          available_blocks: [
            "header1",
            "header2",
            "text",
            "photo",
            "video",
            "highlight",
            "audio",
            "builtInPDF",
          ],
          paid: false,
          commission: 9,
          minmax_amount: [30000, 300000],
          chat_streaming: false,
          chat_calls: false,
          withdraw_limit_month: 100000,
          withdraw_limit_year: 1000000,
          apple_commission: 50,
        },
        advanced: {
          i18n: "Продвинутый",
          study_group: { max_paid_count: -1, max_free_count: 6 },
          max_file_size: 814748364,
          available_blocks: [
            "header1",
            "header2",
            "text",
            "photo",
            "video",
            "highlight",
            "audio",
            "builtInPDF",
          ],
          paid: false,
          commission: 8,
          minmax_amount: [30000, 3000000],
          chat_streaming: false,
          chat_calls: true,
          withdraw_limit_month: 100000,
          withdraw_limit_year: 1000000,
          apple_commission: 50,
        },
        premium: {
          i18n: "Премиум",
          study_group: { max_paid_count: -1, max_free_count: -1 },
          max_file_size: 2147483648,
          available_blocks: [
            "header1",
            "header2",
            "text",
            "photo",
            "video",
            "highlight",
            "audio",
            "builtInPDF",
            "html",
          ],
          paid: true,
          commission: 7,
          payment_interval: [1, "month"],
          minmax_amount: [3000, 3000000],
          chat_streaming: true,
          chat_calls: true,
          withdraw_limit_month: 100000,
          withdraw_limit_year: 1000000,
          apple_commission: 50,
        },
      },
      video_hints: {
        org_start_page: {
          _id: "FILE-494A1A1",
          bucket: "marathon-temp",
          options: {
            size: 10909261,
            width: 640,
            height: 640,
            duration: 46000,
            filename: "IMG_0017.MP4",
          },
          content_type: "video/mp4",
          filename:
            "source/FILE-494A1A1_4bd69dea99b8d90131b14d114f9f686f2b8c28c7d39ccc4834e9232e41e34f99.mp4",
          video_preview:
            "preview/FILE-494A1A1_4bd69dea99b8d90131b14d114f9f686f2b8c28c7d39ccc4834e9232e41e34f99.jpeg",
        },
        org_create_course: {
          _id: "FILE-494A1A1",
          bucket: "marathon-temp",
          options: {
            size: 10909261,
            width: 640,
            height: 640,
            duration: 46000,
            filename: "IMG_0017.MP4",
          },
          content_type: "video/mp4",
          filename:
            "source/FILE-494A1A1_4bd69dea99b8d90131b14d114f9f686f2b8c28c7d39ccc4834e9232e41e34f99.mp4",
          video_preview:
            "preview/FILE-494A1A1_4bd69dea99b8d90131b14d114f9f686f2b8c28c7d39ccc4834e9232e41e34f99.jpeg",
        },
      },
    },
  },
  customCldrData: {
    main: {
      root: {
        numbers: {
          currencies: {
            XBT: {
              displayName: "Bitcoin",
              "displayName-count-one": "bitcoin",
              "displayName-count-other": "bitcoins",
              symbol: "XBT",
              "symbol-alt-narrow": "XBT",
            },
          },
        },
      },
      ru: {
        numbers: {
          currencies: {
            XBT: {
              displayName: "Биткоин",
              "displayName-count-one": "биткоин",
              "displayName-count-few": "биткоина",
              "displayName-count-many": "биткоинов",
              "displayName-count-other": "биткоина",
              symbol: "XBT",
              "symbol-alt-narrow": "XBT",
            },
          },
        },
      },
    },
  },
  prefixMap: {
    SCH: "search",
    USR: "user",
    UTL: "utils",
    FILE: "file",
    BUS: "bus",
    I18N: "i18n",
    PLC: "policy",
    USG: "user_group",
    RS: "resource",
    SCD: "scheduler",
    NF: "notification",
    SSN: "session",
    CL: "client",
    MSG: "message",
    CHT: "chat",
    ACT: "activity",
    PRE: "pretension",
    TR: "transaction",
    CRS: "course",
    MB: "member",
    CHM: "chat_member",
    SG: "study_group",
    SRV: "survey",
    KYC: "kyc",
    GRD: "grade",
    ACH: "achievement",
  },
  uploadsBasePath: "https://bh.nsolid.ru",
  locales: [
    "ar",
    "bg",
    "ca",
    "cs",
    "da",
    "de",
    "el",
    "en",
    "es",
    "eu",
    "fa",
    "fi",
    "fr",
    "ga",
    "gl",
    "hi",
    "hu",
    "hy",
    "id",
    "it",
    "ja",
    "jv",
    "ko",
    "ku",
    "lt",
    "lv",
    "nl",
    "no",
    "pt",
    "ro",
    "ru",
    "sv",
    "th",
    "tr",
    "zh",
    "ur",
    "ky",
    "tg",
    "uz",
    "kk",
    "ms",
    "pl",
    "iw",
  ],
  denominationCoef: { XBT: 1000000000, "*": 100 },
  currencies: [
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYN",
    "BZD",
    "CAD",
    "CDF",
    "CHF",
    "CLF",
    "CLP",
    "CNH",
    "CNY",
    "COP",
    "CRC",
    "CUC",
    "CUP",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GGP",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "IMP",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JEP",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRO",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MYR",
    "MZN",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "RWF",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLL",
    "SOS",
    "SRD",
    "SSP",
    "STD",
    "SVC",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TOP",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "UYU",
    "UZS",
    "VEF",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XAG",
    "XAU",
    "XBT",
    "XCD",
    "XDR",
    "XOF",
    "XPD",
    "XPF",
    "XPT",
    "YER",
    "ZAR",
    "ZMW",
    "ZWL",
    "bonus_points",
  ],
  countries: [
    "AU",
    "AT",
    "AZ",
    "AX",
    "AL",
    "DZ",
    "AS",
    "AI",
    "AO",
    "AD",
    "AG",
    "AR",
    "AM",
    "AW",
    "AF",
    "BS",
    "BD",
    "BB",
    "BH",
    "BY",
    "BZ",
    "BE",
    "BJ",
    "BM",
    "BG",
    "BO",
    "BA",
    "BW",
    "BR",
    "IO",
    "BN",
    "BF",
    "BI",
    "BT",
    "VU",
    "VA",
    "GB",
    "HU",
    "VE",
    "VG",
    "VI",
    "TL",
    "VN",
    "GA",
    "HT",
    "GY",
    "GM",
    "GH",
    "GP",
    "GT",
    "GN",
    "GW",
    "DE",
    "GI",
    "HN",
    "HK",
    "GD",
    "GL",
    "GR",
    "GE",
    "GU",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EG",
    "ZM",
    "EH",
    "ZW",
    "IL",
    "IN",
    "ID",
    "JO",
    "IQ",
    "IR",
    "IS",
    "ES",
    "IT",
    "YE",
    "CV",
    "KZ",
    "KY",
    "KH",
    "CM",
    "CA",
    "QA",
    "KE",
    "CY",
    "KG",
    "KI",
    "CN",
    "KP",
    "CC",
    "CO",
    "KM",
    "CG",
    "CD",
    "CR",
    "CI",
    "CU",
    "KW",
    "LA",
    "LV",
    "LS",
    "LR",
    "LB",
    "LY",
    "LT",
    "LI",
    "LU",
    "MU",
    "MR",
    "MG",
    "YT",
    "MO",
    "MK",
    "MW",
    "MY",
    "ML",
    "MV",
    "MT",
    "MA",
    "MQ",
    "MH",
    "MX",
    "MZ",
    "MD",
    "MC",
    "MN",
    "MS",
    "MM",
    "NA",
    "NR",
    "NP",
    "NE",
    "NG",
    "NL",
    "NI",
    "NU",
    "NZ",
    "NC",
    "NO",
    "NF",
    "CX",
    "SH",
    "CK",
    "TC",
    "AE",
    "OM",
    "PK",
    "PW",
    "PS",
    "PA",
    "PG",
    "PY",
    "PE",
    "PL",
    "PT",
    "PR",
    "KR",
    "RE",
    "RU",
    "RW",
    "RO",
    "SV",
    "WS",
    "SM",
    "ST",
    "SA",
    "SZ",
    "MP",
    "SC",
    "PM",
    "SN",
    "VC",
    "KN",
    "LC",
    "RS",
    "SG",
    "SY",
    "SK",
    "SI",
    "US",
    "SB",
    "SO",
    "SD",
    "SR",
    "SL",
    "TJ",
    "TH",
    "TW",
    "TZ",
    "TG",
    "TK",
    "TO",
    "TT",
    "TV",
    "TN",
    "TM",
    "TR",
    "UG",
    "UZ",
    "UA",
    "WF",
    "UY",
    "FO",
    "FM",
    "FJ",
    "PH",
    "FI",
    "FK",
    "FR",
    "GF",
    "PF",
    "HR",
    "CF",
    "TD",
    "ME",
    "CZ",
    "CL",
    "CH",
    "SE",
    "SJ",
    "LK",
    "EC",
    "GQ",
    "ER",
    "EE",
    "ET",
    "ZA",
    "JM",
    "JP",
  ],
  geocodingApiKey: { google: "AIzaSyCzHIBJuzlvzdOlx1KrdzkZZvffQv2rvps" },
  googleTranslationApiKey: "AIzaSyA0zDa3QLkoSDEvu6Mx0uL_beCir7Lerwk",
  kycType: {
    person: {
      enabled: true,
      fields: [
        {
          name: "residency",
          value: "RU",
          joi: ["string", "required"],
          component: {
            name: "SelectPicker",
            props: { items: [{ label: "Россия", value: "RU" }] },
          },
          label: "Место жительства",
        },
        {
          name: "first_name",
          value: "",
          multiline: true,
          joi: ["string", "trim", "required", ["max", 16]],
          component: { name: "TextInput", props: {} },
          label: "Имя",
        },
        {
          name: "surname",
          value: "",
          multiline: true,
          joi: ["string", "trim", "required", ["max", 16]],
          component: { name: "TextInput", props: {} },
          label: "Фамилия",
        },
        {
          name: "patronymic",
          value: "",
          multiline: true,
          joi: ["string", "trim", ["max", 100], ["allow", ""]],
          component: { name: "TextInput", props: {} },
          label: "Отчество (если есть)",
        },
        {
          name: "date_of_birth",
          joi: ["date", "required"],
          component: { name: "DataPicker", props: { format: "DD.MM.YYYY" } },
          label: "Дата рождения",
        },
        {
          name: "place_of_birth",
          value: "",
          multiline: true,
          joi: ["string", "trim", "required", ["max", 1000]],
          component: {
            name: "TextInput",
            props: { inputStyle: { minHeight: 100, textAlignVertical: "top" } },
          },
          label: "Место рождения",
        },
        {
          name: "itn",
          value: "",
          multiline: true,
          joi: ["string", ["max", 20], "required"],
          component: {
            name: "TextInput",
            props: { keyboardType: "number-pad" },
          },
          label: "ИНН",
        },
        { component: { name: "View", props: { style: { height: 10 } } } },
        {
          name: "passport.series",
          value: "",
          multiline: true,
          joi: ["string", "required", ["min", 4], ["max", 6]],
          component: {
            name: "TextInput",
            props: { keyboardType: "number-pad" },
          },
          label: "Серия паспорта",
        },
        {
          name: "passport.number",
          value: "",
          multiline: true,
          joi: ["string", "required", ["min", 6], ["max", 10]],
          component: {
            name: "TextInput",
            props: { keyboardType: "number-pad" },
          },
          label: "Номер паспорта",
        },
        {
          name: "passport.issued_at",
          joi: ["date", "required"],
          component: { name: "DataPicker", props: { format: "DD.MM.YYYY" } },
          label: "Когда выдан",
        },
        {
          name: "passport.issued_by",
          value: "",
          multiline: true,
          joi: ["string", "required", ["max", 1000]],
          component: {
            name: "TextInput",
            props: { inputStyle: { minHeight: 100, textAlignVertical: "top" } },
          },
          label: "Кем выдан",
        },
        {
          name: "passport.department_code",
          value: "",
          multiline: true,
          joi: ["string", "trim", "required", ["min", 2], ["max", 7]],
          component: { name: "TextInput", props: {} },
          label: "Код департамента",
        },
        {
          name: "registration_address",
          value: "",
          multiline: true,
          joi: ["string", "trim", "required", ["max", 1000]],
          component: {
            name: "TextInput",
            props: { inputStyle: { minHeight: 100, textAlignVertical: "top" } },
          },
          label: "Адрес регистрации",
        },
      ],
    },
    self_employed: {
      enabled: true,
      fields: [
        {
          name: "residency",
          value: "RU",
          joi: ["string", "required"],
          component: {
            name: "SelectPicker",
            props: { items: [{ label: "Россия", value: "RU" }] },
          },
          label: "Место жительства",
        },
        {
          name: "first_name",
          value: "",
          multiline: true,
          joi: ["string", "trim", "required", ["max", 16]],
          component: { name: "TextInput", props: {} },
          label: "Имя",
        },
        {
          name: "surname",
          value: "",
          multiline: true,
          joi: ["string", "trim", "required", ["max", 16]],
          component: { name: "TextInput", props: {} },
          label: "Фамилия",
        },
        {
          name: "patronymic",
          value: "",
          multiline: true,
          joi: ["string", "trim", ["max", 100], ["allow", ""]],
          component: { name: "TextInput", props: {} },
          label: "Отчество (если есть)",
        },
        {
          name: "date_of_birth",
          joi: ["date", "required"],
          component: { name: "DataPicker", props: { format: "DD.MM.YYYY" } },
          label: "Дата рождения",
        },
        {
          name: "place_of_birth",
          value: "",
          multiline: true,
          joi: ["string", "trim", "required", ["max", 1000]],
          component: {
            name: "TextInput",
            props: { inputStyle: { minHeight: 100, textAlignVertical: "top" } },
          },
          label: "Место рождения",
        },
        {
          name: "itn",
          value: "",
          multiline: true,
          joi: ["string", ["max", 20], "required"],
          component: {
            name: "TextInput",
            props: { keyboardType: "number-pad" },
          },
          label: "ИНН",
        },
        { component: { name: "View", props: { style: { height: 10 } } } },
        {
          name: "passport.series",
          value: "",
          multiline: true,
          joi: ["string", "required", ["min", 4], ["max", 6]],
          component: {
            name: "TextInput",
            props: { keyboardType: "number-pad" },
          },
          label: "Серия паспорта",
        },
        {
          name: "passport.number",
          value: "",
          multiline: true,
          joi: ["string", "required", ["min", 6], ["max", 10]],
          component: {
            name: "TextInput",
            props: { keyboardType: "number-pad" },
          },
          label: "Номер паспорта",
        },
        {
          name: "passport.issued_at",
          joi: ["date", "required"],
          component: { name: "DataPicker", props: { format: "DD.MM.YYYY" } },
          label: "Когда выдан",
        },
        {
          name: "passport.issued_by",
          value: "",
          multiline: true,
          joi: ["string", "trim", "required", ["max", 1000]],
          component: {
            name: "TextInput",
            props: { inputStyle: { minHeight: 100, textAlignVertical: "top" } },
          },
          label: "Кем выдан",
        },
        {
          name: "passport.department_code",
          value: "",
          multiline: true,
          joi: ["string", "required", ["min", 2], ["max", 7]],
          component: { name: "TextInput", props: {} },
          label: "Код департамента",
        },
        {
          name: "registration_address",
          value: "",
          multiline: true,
          joi: ["string", "trim", "required", ["max", 1000]],
          component: {
            name: "TextInput",
            props: { inputStyle: { minHeight: 100, textAlignVertical: "top" } },
          },
          label: "Адрес регистрации",
        },
      ],
    },
    company: {
      enabled: true,
      fields: [
        {
          name: "legal_form",
          value: "",
          multiline: true,
          joi: ["string", "required"],
          component: {
            name: "SelectPicker",
            props: {
              items: [
                { label: "ИП", value: "IP" },
                { label: "ООО", value: "LTD" },
                { label: "ОАО", value: "JSC" },
              ],
            },
          },
          label: "Организационно-правовая форма",
        },
        {
          name: "name",
          value: "",
          multiline: true,
          joi: ["string", "required", "trim", ["max", 200]],
          component: { name: "TextInput", props: {} },
          label: "Название юрлица",
        },
        {
          name: "itn",
          value: "",
          multiline: true,
          joi: ["string", "required", ["min", 10], ["max", 20]],
          component: {
            name: "TextInput",
            props: { keyboardType: "number-pad" },
          },
          label: "ИНН",
        },
        {
          name: "ogrn",
          value: "",
          multiline: true,
          joi: ["string", "required", ["min", 13], ["max", 13]],
          component: {
            name: "TextInput",
            props: { keyboardType: "number-pad" },
          },
          label: "ОГРН",
        },
        {
          name: "kpp",
          value: "",
          multiline: true,
          joi: ["string", "required", ["min", 9], ["max", 9]],
          component: {
            name: "TextInput",
            props: { keyboardType: "number-pad" },
          },
          label: "КПП",
        },
        {
          name: "bank_details",
          value: "",
          multiline: true,
          joi: ["string", "trim", "required", ["max", 1000]],
          component: {
            name: "TextInput",
            props: { inputStyle: { minHeight: 100, textAlignVertical: "top" } },
          },
          label: "Банковские реквизиты",
        },
      ],
    },
  },
  appleSellEnable: true,
  _endpointToPrefix: {
    searches: "SCH",
    users: "USR",
    utils: "UTL",
    files: "FILE",
    buses: "BUS",
    i18n: "I18N",
    policies: "PLC",
    user_groups: "USG",
    resources: "RS",
    schedulers: "SCD",
    notifications: "NF",
    sessions: "SSN",
    clients: "CL",
    messages: "MSG",
    chats: "CHT",
    activities: "ACT",
    pretensions: "PRE",
    transactions: "TR",
    courses: "CRS",
    members: "MB",
    chat_members: "CHM",
    study_groups: "SG",
    surveys: "SRV",
    kyc: "KYC",
    grades: "GRD",
    achievements: "ACH",
  },
  _prefixToEndpoint: {
    SCH: "searches",
    USR: "users",
    UTL: "utils",
    FILE: "files",
    BUS: "buses",
    I18N: "i18n",
    PLC: "policies",
    USG: "user_groups",
    RS: "resources",
    SCD: "schedulers",
    NF: "notifications",
    SSN: "sessions",
    CL: "clients",
    MSG: "messages",
    CHT: "chats",
    ACT: "activities",
    PRE: "pretensions",
    TR: "transactions",
    CRS: "courses",
    MB: "members",
    CHM: "chat_members",
    SG: "study_groups",
    SRV: "surveys",
    KYC: "kyc",
    GRD: "grades",
    ACH: "achievements",
  },
  prefix_map: {
    SCH: "search",
    USR: "user",
    UTL: "utils",
    FILE: "file",
    BUS: "bus",
    I18N: "i18n",
    PLC: "policy",
    USG: "user_group",
    RS: "resource",
    SCD: "scheduler",
    NF: "notification",
    SSN: "session",
    CL: "client",
    MSG: "message",
    CHT: "chat",
    ACT: "activity",
    PRE: "pretension",
    TR: "transaction",
    CRS: "course",
    MB: "member",
    CHM: "chat_member",
    SG: "study_group",
    SRV: "survey",
    KYC: "kyc",
    GRD: "grade",
    ACH: "achievement",
  },
};
window.calcDenomination = function (_from, _to) {
  const from =
    window.kv.denominationCoef[_from] || window.kv.denominationCoef["*"];
  const to = window.kv.denominationCoef[_to] || window.kv.denominationCoef["*"];
  return to / from;
};
window.baseToMinor = function (value, currency) {
  const coef =
    window.kv.denominationCoef[currency] || window.kv.denominationCoef["*"];
  return Math.round(value * coef);
};
window.minorToBase = function (value, currency) {
  const coef =
    window.kv.denominationCoef[currency] || window.kv.denominationCoef["*"];
  return value / coef;
};
