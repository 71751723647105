import moment from "moment";
import React, { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { getChats, getRequests } from "../../api";
import Link from "../../components/Link";
import InnerHeader from "../../components/Page/InnerHeader";
import Table from "../../components/Table";
import TableEnd from "../../components/TableEnd";
import { getClasses } from "../../constants/theme";
import { omitUndefined } from "../../utils";
import RadioTabs from "../../components/RadioTabs";
import StatusList from "../../components/Status";

const getColumns = (classes) => ({
  _id: {
    f: 0.1,
    title: "ID",
    converter: (_id) => {
      return <Link to={`/requests/${_id}`}>{_id}</Link>;
    },
  },
  created_at: {
    f: 0.6,
    title: "Создан",
    converter: (created_at) => {
      return moment(created_at).format('DD MMMM HH:mm')
    },
  },
  client: {
    title: "Пользователь",
    converter: (client, item) => {
      return (
        <Link to={`/chats/${client._id}`}>
          {`${client.first_name} ${client.last_name}`}
        </Link>
      );
    },
  },
  title: {
    f: 1,
    title: "Запрос"
  },
  _timestamp: {
    f: 1,
    title: "Статус",
    converter: (client_firstname, item) => {



      return <StatusList statusStrings={
        [
          { title: 'Новый', mode: 'green', value: !item.canceled && !item.completed && !item.confirmed },
          { title: 'В обработке', mode: 'orange', value: !item.canceled && !item.completed && item.confirmed },
          { title: 'Завершен', value: !item.canceled && item.completed && item.confirmed },
          { title: 'Отклонен', mode: 'red', value: item.canceled }
        ].filter(t => !!t.value)
      } />
    },
  }
});

export default function RequestList() {
  const classes = getClasses(dynamicStyle);
  const table = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const columns = useMemo(() => getColumns(classes), []);
  const serachOptions = useRef({
    query: searchParams.get("query") ?? void 0,
    mode: searchParams.get("mode") ?? "new",
  });

  return (
    <div className={classes("wrapper")}>
      <InnerHeader
        defaultQuery={serachOptions.current.query}
        onQuerySubmit={(query) => {
          if (query) {
            serachOptions.current.query = query;
          } else {
            delete serachOptions.current.query;
          }

          setSearchParams(omitUndefined(serachOptions.current));

          table.current.reload();
        }}
        title={"Запросы"}
      />
      <div className={classes("content")}>
        <div className={classes("filtersSection")}>
          <RadioTabs
            value={serachOptions.current.mode}
            onChanged={(v) => {
              serachOptions.current.mode = v;
              setSearchParams(omitUndefined(serachOptions.current));

              table.current.reload();
            }}
            default={"new"}
            items={[
              {
                value: "new",
                name: "Новые",
              },
              {
                value: "processing",
                name: "В обработке",
              },
              {
                value: "completed",
                name: "Завершенные",
              },
              {
                value: "canceled",
                name: "Отмененные",
              },
              {
                value: "all",
                name: "Все",
              },
            ]}
          />
        </div>
        <Table
          offset={130}
          ref={table}
          endComponent={<TableEnd />}
          limit={1000}
          columns={columns}
          fetchData={(param) => {
            const params = {};

            if (serachOptions.current.mode === 'new') {
              params.confirmed = false;
              params.completed = false;
              params.canceled = false;
            }

            if (serachOptions.current.mode === 'processing') {
              params.confirmed = true;
              params.completed = false;
              params.canceled = false;
            }

            if (serachOptions.current.mode === 'completed') {
              params.confirmed = true;
              params.completed = true;
              params.canceled = false;
            }

            if (serachOptions.current.mode === 'canceled') {
              params.canceled = true;
            }

            return getRequests({ ...param, params })
          }
          }
        />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    filtersSection: {
      marginBottom: 32,
    },
    content: {
      paddingLeft: 40,
      paddingTop: 30,
    },
    searchItem: {
      marginBottom: 4,
    },
    searchLabel: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    arraowDown: {
      color: Colors.accent2,
    },
    arraowUp: {
      color: Colors.color1,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  };
};
