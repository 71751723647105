import { getClasses } from "../../constants/theme";
import Title2 from "./Title2";

export default function Section({ children, title }) {
  const classes = getClasses(dynamicStyle);

  return (
    <div className={classes("container")}>
      {title && <Title2>{title}</Title2>}
      {children}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    container: {
      color: Colors.n90,
      marginBottom: 48,
    },
  };
};
