import {Storage} from './storage';
import Emitter from './emitter';
import {useEffect, useState} from 'react';

class Store extends Emitter {
  constructor({key, defaultSchema = {}}, func = {}) {
    super();

    this.defaultSchema = defaultSchema;
    this.storageKey = key;
    this.temporary = key !== undefined ? false : true;
    this._ = {};
    this.__ = {};
    this._prepare = func?.prepare;
    this._inited = false;
  }

  async init() {
    if (this.temporary === false) {
      const _ = await Storage.getJSON(this.storageKey);

      this._ = _ || {};
    } else {
      this._ = {};
    }

    if (this._prepare) {
      await this._prepare();
    }

    this._inited = true;
    this.memorize('event-inited', true);
  }

  get(oKey, def = undefined, handler = void 0) {
    if (this._inited === false) {
      throw new Error(`Store not inited ${this.storageKey} get`);
    }

    if (oKey === undefined) return this._;

    let [key] = oKey.split(Emitter.delimiter);
    let off = () => {};

    const isFunction = typeof handler === 'function';

    const rRes = get(this.__, key);

    if (isFunction) {
      off = this.on(oKey, handler);
    }

    if (rRes !== undefined) {
      if (isFunction) {
        handler({next: rRes, def});
        return off;
      } else {
        return rRes;
      }
    }

    const res = get(this._, key);

    if (res !== undefined) {
      if (isFunction) {
        handler({next: res, def});
        return off;
      } else {
        return res;
      }
    }

    const dRes = get(this.defaultSchema, key);
    if (dRes !== undefined) {
      if (isFunction) {
        handler({next: dRes, def});
        return off;
      } else {
        return dRes;
      }
    }

    if (def !== undefined) {
      if (isFunction) {
        handler({next: def, def});
        return off;
      } else {
        return def;
      }
    }

    if (isFunction) {
      handler({next: undefined, def});
      return off;
    } else {
      return undefined;
    }
  }

  remove(okey) {
    if (this._inited === false) {
      throw new Error(`Store not inited ${this.storageKey} remove`);
    }

    this.clear(okey);

    this.off(okey);
  }

  clear(key) {
    if (this._inited === false) {
      throw new Error(`Store not inited' ${this.storageKey} clear`);
    }

    if (get(this._, key) !== undefined) {
      this.set(key, void 0);
      this.save();
    } else {
      this.memorize(key, void 0);
    }
  }

  set(key, value, temp = this.temporary) {
    if (this._inited === false) {
      throw new Error(`Store not inited ${this.storageKey} set`);
    }

    [key] = key.split(Emitter.delimiter);

    const kArr = key.split('.');

    if (!temp) {
      const prev = get(this._, key);

      // TODO - обратить внимание

      if (prev === value) return;

      set(this._, key, value);

      kArr.reduce((acc, elem) => {
        acc.push(elem);

        const currentKey = acc.join('.');

        this.emit(currentKey, {prev, next: get(this._, currentKey)});

        return acc;
      }, []);

      // emit to up

      traverse(this._map, kArr, path => {
        const _prev = get(prev, [...path].splice(kArr.length, path.length));
        const next = get(this._, path);

        if (_prev !== next) {
          this.emit(path, {prev: _prev, next});
        }
      });

      this.save();
    } else {
      const prev = get(this.__, key);

      set(this.__, key, value);

      kArr.reduce((acc, elem) => {
        acc.push(elem);

        const currentKey = acc.join('.');

        this.emit(currentKey, {prev, next: get(this.__, currentKey)});

        return acc;
      }, []);

      // emit to up

      traverse(this._map, kArr, path => {
        const _prev = get(prev, [...path].splice(kArr.length, path.length));
        const next = get(this.__, path);

        if (_prev !== next) {
          this.emit(path, {prev: _prev, next});
        }
      });
    }

    return true;
  }

  has(key) {
    if (this._inited === false) {
      throw new Error(`Store not inited ${this.storageKey} has`);
    }

    return !!this.get(key);
  }

  memorize(key, value) {
    if (this._inited === false) {
      throw new Error(`Store not inited ${this.storageKey} memorize`);
    }

    return this.set(key, value, true);
  }

  memorizeAssign(key, object) {
    if (this._inited === false) {
      throw new Error(`Store not inited ${this.storageKey} memorizeAssign`);
    }

    if (this.__[key]) {
      this.__[key] = Object.assign({}, this.__[key], object);

      this.emit(key, {prev: {}, next: this.__[key]});
    }
  }

  async save() {
    if (this._inited === false) {
      throw new Error(`Store not inited ${this.storageKey} save`);
    }

    if (this.temporary === false) {
      await Storage.setJSON(this.storageKey, this._);
    }
  }

  Store(path) {
    const _this = this;
    const prefix = 'pr' + ~~(Math.random() * 100000);

    return (props = {}) => {
      const startValue = _this.get(path);
      const [state, setState] = useState({
        next: startValue,
        prev: startValue,
      });

      useEffect(() => _this.on(path + ':' + prefix, setState), []);

      if (props.children) {
        if (typeof props.children === 'function') {
          return props.children(state);
        } else {
          return props.children;
        }
      } else {
        return state;
      }
    };
  }
}

export {Store};

const traverse = (map, kArr, cb) => {
  const last = get(map, kArr);

  if (last !== undefined) {
    for (let _ in last) {
      if (_ === 'count') {
        continue;
      }
      const currentKey = [...kArr, _];

      cb(currentKey);

      traverse(map, currentKey, cb);
    }
  }
};

export const get = (obj, path, defaultValue) => {
  if (typeof path === 'string') path = path.split('.');

  let node = obj;

  for (let i = 0; i < path.length; i++) {
    const key = path[i];

    if (!node) {
      node = undefined;

      break;
    }

    node = node[key];
  }

  return typeof node === 'undefined' ? defaultValue : node;
};

export const set = (obj, path, defaultValue, setter) => {
  if (typeof path === 'string') path = path.split('.');

  if (typeof setter === 'undefined') setter = defaultValue;

  let value = get(obj, path);

  if (
    (typeof value === 'undefined' ||
      (typeof value === 'number' && isNaN(value))) &&
    typeof defaultValue !== 'function'
  ) {
    value = defaultValue;
  }

  let node = obj;

  for (var i = 0; i < path.length - 1; i++) {
    const key = path[i];
    const nextKey = +path[i + 1];

    if (!node[key]) node[key] = isNaN(nextKey) ? {} : [];

    node = node[key];
  }

  node[path[i]] = typeof setter === 'function' ? setter(value) : setter;

  return obj;
};
