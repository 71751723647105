import Joi from 'joi';

export const filePreSchema = () => {
  return Joi.object({
    _id: Joi.string().required(),
  });
};

export const blockJoiPreSchema = () => {
  return Joi.object({
    type: Joi.string().required(),
    value: Joi.any()
      .when('type', {is: 'photo', then: filePreSchema()})
      .when('type', {is: 'video', then: filePreSchema()})
      .when('type', {is: 'audio', then: filePreSchema()})
      .when('type', {is: 'builtInPDF', then: filePreSchema()})
      .when('type', {is: 'text', then: Joi.string().required()})
      .when('type', {is: 'header1', then: Joi.string().required()})
      .when('type', {is: 'header2', then: Joi.string().required()})
      .when('type', {is: 'highlight', then: Joi.string().required()})
  });
};