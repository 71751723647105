import moment from "moment";
import React, { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { getChats, getInvoices, getPartners, getRequestTemplate, getRequests } from "../../api";
import Link from "../../components/Link";
import InnerHeader from "../../components/Page/InnerHeader";
import Table from "../../components/Table";
import TableEnd from "../../components/TableEnd";
import { getClasses } from "../../constants/theme";
import { omitUndefined } from "../../utils";

const getColumns = (classes) => ({

  title: {
    f:3,
    title: "Название",
    converter: (title, item) => {
      return <Link to={`/request_template/${item._id}`}>{title}</Link>;
    },

  },
  _timestamp: {
    f: 1,
    title: "Timestamp",
    converter: (_timestamp) => {
      return moment(_timestamp).format('D.MM HH:mm:ss');
    },
  },
  _id: {
    f: 1,
    title: "ID",
    converter: (_id) => {
      return <Link to={`/request_template/${_id}`}>{_id}</Link>;
    },
  },
});

export default function RequestTemplateList() {
  const classes = getClasses(dynamicStyle);
  const table = useRef();
  const columns = useMemo(() => getColumns(classes), []);


  return (
    <div className={classes("wrapper")}>
      <InnerHeader
        title={"Партнеры"}
      />
      <div className={classes("content")}>
        <Table
          offset={130}
          ref={table}
          endComponent={<TableEnd />}
          limit={1000}
          columns={columns}
          fetchData={(param) =>
            getRequestTemplate({ ...param})
          }
        />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    filtersSection: {
      marginBottom: 32,
    },
    content: {
      paddingLeft: 40,
      paddingTop: 30,
    },
    searchItem: {
      marginBottom: 4,
    },
    searchLabel: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    arraowDown: {
      color: Colors.accent2,
    },
    arraowUp: {
      color: Colors.color1,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  };
};
