import { useEffect, useState } from "react";
import { getClasses } from "../constants/theme";
import { Settings } from "../stores/Settings";

export default function Dialog(props) {
  const classes = getClasses(dynamicStyle);
  const [state, setState] = useState(false);

  useEffect(() => {
    const off1 = Settings.on("show_dialog", (payload) => {
      if (payload === false) return hide();
      setState({
        title: payload.title,
        text: payload.text,
        onCancel: payload.onCancel,
        onAccept: payload.onAccept,
        onHide: payload.onHide,
        cancelText: payload.cancelText,
        acceptText: payload.acceptText,
        Comment: payload.Comment,
      });
    });

    const off2 = Settings.on("hide_dialog", (payload) => {
      hide();
    });

    return () => {
      off1();
      off2();
    }
  }, []);

  const hide = () => {
    state.onHide && state.onHide();

    setState(false);
  };

  const onAccept = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    state.onAccept && state.onAccept();

    setState(false);
  };

  const onCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    state.onCancel && state.onCancel();

    setState(false);
  };

  if (!state) return null;

  return (
    <>
      <div className={classes("layout")}>
        <div onClick={hide} className={classes("overlay")}></div>
        <div>
          <div className={classes("conatiner")}>
            <div className={classes("wapper")}>
              {state?.title && (
                <div className={classes("title")}>{state?.title}</div>
              )}
              {state?.text && (
                <div className={classes("text")}>{state?.text}</div>
              )}
              {state.Comment && state.Comment}
              {(state?.acceptText || state?.cancelText) && (
                <div className={classes("controls")}>
                  {state?.acceptText && (
                    <div onClick={onAccept} className={classes("accept")}>
                      {state.acceptText}
                    </div>
                  )}
                  {state?.cancelText && (
                    <div onClick={onCancel} className={classes("cancel")}>
                      {state.cancelText}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const dynamicStyle = (Colors, Fonts) => {
  return {
    overlay: {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      flex: 1,
      zIndex: -1,
    },
    dialog: {
      display: "flex",
      zIndex: 1,
      position: "absolute",
      alignItems: "center",
    },
    title: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 12,
    },
    text: {
      ...Fonts.subhead1,
      color: Colors.neutral1,
    },
    controls: {
      marginTop: 16,
      display: "flex",
      justifyContent: "flex-end",
    },
    cancel: {
      ...Fonts.subhead2,
      color: Colors.accent2,
      cursor: "pointer",
      marginLeft: 24,
    },
    accept: {
      ...Fonts.subhead2,
      color: Colors.color1,
      cursor: "pointer",
    },
    layout: {
      position: "fixed",

      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    conatiner: {
      backgroundColor: Colors.backgroundLayout,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 24,
      paddingBottom: 24,
      maxWidth: 500,
      borderRadius: 8,
      boxShadow: `0px 16px 16px rgba(50, 50, 71, 0.16), 0px 16px 32px rgba(50, 50, 71, 0.12)`,
    },
    wapper: {},
  };
};
