import { useEffect, useState } from "react";
import { History } from "../stores/History";
import { Loader } from "../stores/Loader";
import { Settings } from "../stores/Settings";

export default function useStore() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    Promise.all([Settings.init(), Loader.init(), History.init()]).then(() =>
      setReady(true)
    );
  }, []);

  return ready;
}
