import Joi from "joi";
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth, errorHandler } from "../../api";
import Button from "../../components/Button";
import TextInput from "../../components/Form/TextInput";
import Logo from "../../components/Logo";
import { getClasses, useTheme } from "../../constants/theme";
import { useFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";

export default function AuthPage() {
  const styles = useTheme(dynamicStyle);
  const classes = getClasses(dynamicStyle);
  const navigate = useNavigate();

  const startAuth = async () => {
    try {
      form.validate();
      const res = await auth(form.get());
      Settings.set("authorizationToken", res.token);
      Settings.memorize("me", {
        first_name: res.first_name,
        last_name: res.last_name,
        _id: res._id
      });

      navigate("/dasboard");
    } catch (err) {
      errorHandler(err);
    }
  };

  const form = useFormCollector(
    {
      email: "",
      password: "",
      remember_me: true,
    },
    Joi.object({
      email: Joi.string().required(),
      password: Joi.string().required(),
      remember_me: Joi.boolean().default(true),
    })
  );

  return (
    <div className={classes("container")}>
      <div className={classes("wrapper")}>
        <div className={classes("title")}>RC Club backoffice</div>
        <div>
          <TextInput
            containerStyle={styles.textInputontainerStyle}
            name={`email`}
            collector={form}
            placeholder={"Email"}
          />
          <TextInput
            containerStyle={styles.textInputontainerStyle}
            name={`password`}
            collector={form}
            type={"password"}
            placeholder={"Password"}
          />

          <Button mode={'blue'} onClick={startAuth} text={"Войти"} />
        </div>
      </div>

      <Logo containerStyle={styles.logoWrapper} />
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    wrapper: {
      width: 500,
    },
    textInputontainerStyle: {
      marginBottom: 16,
    },
    logoWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "flex-end",
      position: "absolute",
      bottom: 24,
      right: 16,
    },
    title: {
      ...Fonts.title1,
      color: Colors.neutral1,
      margin: 24,
    },
    container: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
    },
  };
};
