import { Settings } from "../stores/Settings";
import { courseSerialize, noop } from "../utils";
import form, { rehydrate as rehydrateForm } from "../forms/createNewCourse";
import { createCourse, errorHandler, updateCourse } from "../api";
import moment from "moment";
import { History } from "../stores/History";

export default async function saveCourse({ before = noop, silent = false }) {
  const needWite = rehydrateForm();

  if (needWite.length > 0 && silent === false) {
    Settings.emit("local_notification", {
      text: "Необходимо дождаться дозагрузки файлов",
      level: "warn",
    });

    return false;
  }

  await before();

  try {
    !silent && Settings.emit("loading", "Загрузка");

    form.validate();
    const course = form.get();
    History.add(course);
    const scourse = courseSerialize(course);
    if (course._id === undefined) {
      const res = await createCourse(scourse);

      form.set("_id", res._id);

      !silent && Settings.emit("local_notification", "Курс сохранен");
      Settings.memorize("course_saved", moment().format());
    } else {
      await updateCourse(scourse);
      Settings.memorize("course_saved", moment().format());
      !silent && Settings.emit("local_notification", "Курс обновлен");
    }
    return true;
  } catch (e) {
    e.i18n = {
      title: "Ошибка валидации",
      message: e.message,
    };
    !silent && errorHandler(e);
    return false;
  } finally {
    !silent && Settings.emit("loading", false);
  }
}
