import moment from "moment";
import React, { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { getChats, getEvents, getInvoices, getNews, getRequests, getServices } from "../../api";
import Link from "../../components/Link";
import InnerHeader from "../../components/Page/InnerHeader";
import Table from "../../components/Table";
import TableEnd from "../../components/TableEnd";
import { getClasses } from "../../constants/theme";
import { omitUndefined } from "../../utils";

const getColumns = (classes) => ({
  _id: {
    f: 0.1,
    title: "ID",
    converter: (_id) => {
      return <Link to={`/services/${_id}`}>{_id}</Link>;
    },
  },
  name: {
    f: 2,
    title: "Сервис",
    converter: (name, item) => {
      return <Link to={`/services/${item._id}`}>{name}</Link>;
    },
  },

  event_id: {
    f: 0.5,

    title: "Событие",
    converter: (event_id, item) => {
      return event_id ? (
        <Link to={`/events/${event_id}`}>
          Перейти
        </Link>
      ) : null
    },
  },
  template_id: {
    f:1,

    title: "Шаблон формы",
    converter: (template_id, item) => {
      return  template_id ? (
        <Link to={`/request_template/${template_id}`}>
          {template_id}
        </Link>
      ) : null
    },
  },
  created_at:{
    f: 0.6,
    title: "Создан",
    converter: (created_at) => {
      return moment(created_at).format('D.MM.YY HH:mm');
    },
  },
});

export default function ServicesList() {
  const classes = getClasses(dynamicStyle);
  const table = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const columns = useMemo(() => getColumns(classes), []);
  const serachOptions = useRef({
    query: searchParams.get("query") ?? void 0,
    action: searchParams.get("action") ?? "all",
  });

  return (
    <div className={classes("wrapper")}>
      <InnerHeader
        defaultQuery={serachOptions.current.query}
        title={"Сервисы"}
      />
      <div className={classes("content")}>
        <Table
          offset={130}
          ref={table}
          endComponent={<TableEnd />}
          limit={1000}
          columns={columns}
          fetchData={(param) =>
            getServices({ ...param, ...serachOptions.current })
          }
        />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    filtersSection: {
      marginBottom: 32,
    },
    content: {
      paddingLeft: 40,
      paddingTop: 30,
    },
    searchItem: {
      marginBottom: 4,
    },
    searchLabel: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    arraowDown: {
      color: Colors.accent2,
    },
    arraowUp: {
      color: Colors.color1,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  };
};
