import React from "react";
import { getClasses } from "./../constants/theme/index";
import logo from "./../assets/logo.svg";
import { useNavigate } from "react-router-dom";

export default function Logo(props) {
  const classes = getClasses(dynamicStyle);
  const navigate = useNavigate();
  return (
    <div
      className={classes("container")}
      onClick={() => {
        navigate("/");
      }}
      style={props.containerStyle}
    >
      <img style={{height: 40}} src={logo} alt="logo" />
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    container: {
      cursor: "pointer",
    },
    logoText: {
      ...Fonts.title2,
      color: Colors.neutral1,
      marginLeft: 8,
    },
  };
};
