import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import Section from "../../components/Layout/Section";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import { getClasses } from "../../constants/theme";
import { useFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";
import Joi from "joi";
import TextInput from "../../components/Form/TextInput";
import PhotoLoader2 from "../../components/Form/PhotoLoader2";
import SelectPicker from "../../components/Form/SelectPicker";
import { errorHandler, getEventSimpleList, getNewsCategories, getNewsEntity, getRequestTemplate, getServiceCatigories, getServiceEntity, getServiceType } from "../../api";



const useEntitty = (id, form) => {
  const [state, setState] = useState({
    entity: void 0,
    _ready: false,
    requestTemplates: [],
    events: [],
    serviceCatigories: [],
    types: []
  });

  useEffect(() => {
    Promise.all([getRequestTemplate({ limit: 1000 }),
    id === 'create' ?
      Promise.resolve(void 0) :
      getServiceEntity(id), getEventSimpleList({ limit: 1000 }),
    getServiceCatigories(),
    getServiceType()]
    )
      .then(([requestTemplates, entity, events, serviceCatigories, types]) => {

        console.log('eventseventseventseventsevents', events)

        if (entity) {
          form.reset(entity);
        }
        setState({
          entity: entity,
          _ready: true,
          events: events.data,
          requestTemplates: requestTemplates.data,
          serviceCatigories: serviceCatigories.data,
          types: types.data
        })



      }).catch(err => errorHandler(err))

  }, [])

  return state
}


export default function CreateNewService(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('Название')

  const form = useFormCollector({
    event_id: void 0,
    img: void 0,
    name: '',
    template_id: void 0,
    _id: void 0,
    category_id: void 0,
    service_type_id: void 0,
  }, Joi.object({
    _id: Joi.number().integer().min(1),
    name: Joi.string().min(1).required(),
    img: Joi.object(),
    event_id: Joi.number().integer(),
    category_id: Joi.number().integer().required(),
    service_type_id: Joi.number().integer(),
    template_id: Joi.string(),
  }), (k, v) => {
    if (k === 'title') {
      setTitle(v)
    }
  })

  const { requestTemplates, events, serviceCatigories, types } = useEntitty(id, form)


  const upsert = async () => {
    try {
      const data = form.get()

      form.validate()

      console.log(data)


    } catch (err) {
      errorHandler(err)
    }

    console.log(form)
  }


  return (
    <InnerPage
      ready={true}
      Header={<InnerHeader breadcrumbs={[["Ноаости", "/news"], [title]]} />}
      MainContent={
        <div className={classes("content")}>
          <Section title={"Контент"}>
            <PageLineData
              title={"Заголовок"}
              value={<TextInput collector={form} name={'name'} />}
            />
            <PageLineData
              title={"Обложка"}
              value={<PhotoLoader2
                collector={form}
                name={'img'}
              />}
            />


            <PageLineData
              title={"Шаблон запроса"}
              value={<SelectPicker
                name={"template_id"}
                collector={form}
                placeholder={{
                  value: undefined,
                  label: 'Нет привязки шаблона'
                }}
                items={requestTemplates.map((cat) => {
                  return {
                    value: cat._id,
                    label: cat.title,
                  };
                })}
              />}
            />


            <PageLineData
              title={"Событие"}
              value={<SelectPicker
                name={"event_id"}
                collector={form}
                placeholder={{
                  value: undefined,
                  label: 'Нет привязки к событию'
                }}
                items={events.map((cat) => {
                  return {
                    value: cat._id,
                    label: cat.title,
                  };
                })}
              />}
            />

            <PageLineData
              title={"Категория"}
              value={<SelectPicker
                name={"category_id"}
                collector={form}
                placeholder={{
                  value: undefined,
                  label: 'Выберите категорию'
                }}
                items={serviceCatigories.map((cat) => {
                  return {
                    value: cat._id,
                    label: cat.name,
                  };
                })}
              />}
            />


            <PageLineData
              title={"Тип сервиса"}
              value={<SelectPicker
                name={"service_type_id"}
                collector={form}
                placeholder={{
                  value: undefined,
                  label: 'Выберите тип сервиса'
                }}
                items={types.map((cat) => {
                  return {
                    value: cat._id,
                    label: cat.name,
                  };
                })}
              />}
            />





          </Section>


        </div>
      }
      RightContent={
        <div className={classes("rightConent")}>
          <Button
            containerStyle={{ marginBottom: 8 }}
            text={"Сохранить"}
            onClick={upsert}
            mode={"green"}
          />
        </div>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    clientType: {
      color: Colors.color1,
    },
    buttonLink: {
      cursor: "pointer",
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
    },
    rejectText: {
      color: Colors.red90,
    },
    commentWrapper: {
      marginTop: 16,
    },
    content: {},
    rightConent: {},
  };
};
