//<Outlet />;

import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { errorHandler, getCurrentCounters } from "../../api";
import { getClasses } from "../../constants/theme";
import { Settings } from "../../stores/Settings";
import LeftMenu from "./components/LeftMenu";

export default function Main(props) {
  const classes = getClasses(dynamicStyle);

  const getCounters = () => {
    getCurrentCounters()
      .then((res) => Settings.memorize("notify_counters", res))
      .catch((err) => {});
  };

  useEffect(() => {
    const timer = setInterval(getCounters, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div id={"container"} className={classes("container")}>
      <div className={classes("body")}>
        <LeftMenu />
        <div className={classes("content")}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    body: {
      display: "flex",
      flex: 1,
    },
    content: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      // marginLeft: 292,
    },
    container: {
      display: "flex",
      minHeight: "100%",
      flexDirection: "column",
      overflow: "hidden",
      position: "fixed",
      width: "100%",
    },
  };
};
