import { Link as L } from "react-router-dom";
import { getClasses } from "../constants/theme";

export default function Link(props) {
  const classes = getClasses(dynamicStyle);

  return <L to={props.to} className={classes('link')}>{props.children}</L>;
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    link: {
        color: Colors.blue90,
        textDecoration: 'none',
    },
  };
};
