export const title1 = {
  fontWeight: "600",
  fontSize: 28,
  fontFamily: "Manrope, sans-serif",
  lineHeight: "32px",
};

export const title2 = {
  fontWeight: "600",
  fontSize: 22,
  fontFamily: "Manrope, sans-serif",
  lineHeight: "27px",
};

export const headline1 = {
  fontWeight: "600",
  fontSize: 17,
  fontFamily: "Manrope, sans-serif",
  lineHeight: "21px",
};

export const body = {
  fontSize: 17,
  fontFamily: "Manrope, sans-serif",
  lineHeight: "22px",
};

export const subhead1 = {
  fontSize: 15,
  fontFamily: "Manrope, sans-serif",
  lineHeight: "21px",
};

export const subhead2 = {
  fontWeight: "600",
  fontSize: 15,
  fontFamily: "Manrope, sans-serif",
  lineHeight: "20px",
};

export const footnote = {
  fontSize: 13,
  fontFamily: "Manrope, sans-serif",
  lineHeight: "17px",
};

export const caption1 = {
  fontSize: 12,
  fontFamily: "Manrope, sans-serif",
  lineHeight: "16px",
};

export const tagline3 = {
  fontWeight: "600",
  fontSize: 11,
  fontFamily: "Manrope, sans-serif",
  lineHeight: "16px",
};

///// new

export const special1 = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 36,
  lineHeight: '49px',
};

export const special2 = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '33px',
};

export const specialText = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '25px',
};

export const pageTitle = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 28,
  lineHeight: '33px',
};

export const h1 = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '33px',
};

export const h2 = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '27px',
};

export const h3 = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '27px',
};

export const button = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '25px',
};

export const mainMenu = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '22px',
};

export const inputData = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '22px',
};

export const inputHeader = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '22px',
};

export const tableHeader = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '20px',
};

export const tableData = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 15,
  lineHeight: '20px',
};

export const tag = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '18px',
};

export const subMenuHeader = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '20px',
};

export const subMenu = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 15,
  lineHeight: '20px',
};
