import { Settings } from "../stores/Settings";
import axios from "axios";
import { wait } from "../utils";

export const fetchData = async (
  uri,
  body = {},
  token = false,
  method = "POST"
) => {
  // await wait(1000);
  const url = `${Settings.get("server.proxy_host")}${uri}`;
  const headers = {
    "Content-Type": "application/json",
    "Accept-Language": "ru",
  };

  if (Settings.has("authorizationToken")) {
    headers.authorization = `Bearer ${Settings.get("authorizationToken")}`;
  } else if (token) {
    // console.log({url, method, token});

    const error = new Error();

    error.exception = "PermissionDenied";
    error.details = {};
    error.message = "You need to log in";
    error.name = "Permission Denied";
    error.parsed = true;

    // console.log(uri);
    throw error;
  }
  // console.log(url, method, body);

  try {
    const res = await fetch(url, {
      method,
      body: JSON.stringify(body),
      headers,
    });

    if (res.ok) {
      const json = await res.json();

      //console.log(url, method, body, json);

      return json;
    } else {
      const e = await res.json();
      // console.log('!!!!', url, method, body, e);

      const error = new Error(e.http_error);

      //console.log(url, method, body, e);

      error.exception = e.exception;
      error.details = e.details;
      error.message = e.message;
      error.name = e.http_error;
      error.parsed = true;

      throw error;
    }
  } catch (e) {
    console.log(url, method, body);

    console.error(e);
    if (!e.parsed) {
      // eslint-disable-next-line no-ex-assign
      e = new Error();
      e.name = "Service Unavailable";
      e.message = "Service Temporarily Unavailable";
      e.code = 503;
      e.hidden = true;
      e.parsed = true;
    }

    throw e;
  }
};

export const validate = (schema, payload, options = {}) => {
  const { error, value } = schema.validate(payload, {
    ...options,
    allowUnknown: true,
  });

  if (!error) {
    return value;
  } else {
    const e = new Error();

    e.details = error.details;
    e.message = error.message;
    e.name = error.name;
    e.parsed = true;

    return e;
  }
};

export const errorHandler = function (error) {
  console.log("errorHandler", error);
  Settings.emit("local_notification", {
    title: "Ошибка запроса",
    level: "red",
    text: error.message,
  });
};

export const VideoExtensions = {
  "3g2": "video/3gpp2",
  "3gp": "video/3gpp",
  aac: "audio/x-aac",
  au: "audio/basic",
  bmp: "image/bmp",
  gif: "image/gif",
  h261: "video/h261",
  h263: "video/h263",
  h264: "video/h264",
  ice: "x-conference/x-cooltalk",
  ico: "image/x-icon",
  ief: "image/ief",
  jpe: "image/jpeg",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  jpgm: "video/jpm",
  jpgv: "video/jpeg",
  jpm: "video/jpm",
  m1v: "video/mpeg",
  m2a: "audio/mpeg",
  m2v: "video/mpeg",
  m3a: "audio/mpeg",
  m3u: "audio/x-mpegurl",
  m4u: "video/vnd.mpegurl",
  m4v: "video/x-m4v",
  mov: "video/quicktime",
  movie: "video/x-sgi-movie",
  mp2: "audio/mpeg",
  mp2a: "audio/mpeg",
  mp3: "audio/mpeg",
  mp4: "video/mp4",
  mp4a: "audio/mp4",
  mp4v: "video/mp4",
  mpa: "video/mpeg",
  mpe: "video/mpeg",
  mpeg: "video/mpeg",
  mpg: "video/mpeg",
  mpg4: "video/mp4",
  mpga: "audio/mpeg",
  oga: "audio/ogg",
  ogg: "audio/ogg",
  ogv: "video/ogg",
  pic: "image/x-pict",
  png: "image/png",
  psd: "image/vnd.adobe.photoshop",
  qt: "video/quicktime",
  spx: "audio/ogg",
  svg: "image/svg+xml",
  svgz: "image/svg+xml",
  viv: "video/vnd.vivo",
  wav: "audio/x-wav",
  wbmp: "image/vnd.wap.wbmp",
  wm: "video/x-ms-wm",
  wma: "audio/x-ms-wma",
  wmv: "video/x-ms-wmv",
  avi: "video/avi",
};

export const uploadToGCS = async (url, data, contentType) => {
  const res = await axios.put(url, data, {
    headers: {
      "Content-Type": contentType,
    },
  });

  return res;
};

export const uploadBinaryFile = ({
  upload_url,
  content_type,
  file,
  onProgress,
}) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status === 200) {
        // succeeded
        resolve(xhr.response);
      } else {
        reject(xhr.response);
      }
    };

    xhr.onerror = (error) => {
      reject(error);
    };

    xhr.open("PUT", upload_url);
    xhr.setRequestHeader("content-type", content_type);
    xhr.upload.onprogress = function (event) {
      console.log("progress", event);
      if (onProgress) {
        onProgress(Math.round((event.loaded / event.total) * 100), event);
      }
    };
    xhr.send(file);
  });
};

export const createCourse = async (payload) => {
  const res = await fetchData("courses", payload, true);

  return res;
};

export const getClientInfo = async (_id) => {

  const res = await Settings.get('ws').rpc('client.getEntity', {
    list_name: 'client_entity',
    _id
  })

  return res.data[0];
};

export const updateCourse = async (payload) => {
  const res = await fetchData("courses", payload, true, "PUT");

  return res;
};

export const removeCourse = async (payload) => {
  const res = await fetchData("courses", payload, true, "DELETE");

  return res;
};

export const getMe = async (body = {}) => {

  const res = await Settings.get('ws').rpc('users.me', {})
  //const res = await fetchData("users/me", body, true);

  return res;
};


export const checkToken = async (token) => {

  const res = await Settings.get('ws').rpc('users.checkToken', { token })
  //const res = await fetchData("users/me", body, true);

  return res;
};

export const getTransactions = async (param) => {
  const res = await fetchData("transactions/list", param, true);

  return res;
};

export const declineTransaction = async ({ comment, tr_id }) => {
  const res = await fetchData(
    "transactions/decline_locally",
    { local_declined_reason: comment, tr_id },
    true
  );

  return res;
};

export const acceptTransaction = async ({ tr_id }) => {
  const res = await fetchData("transactions/accept_locally", { tr_id }, true);

  return res;
};

export const getPretensions = async (param) => {
  const res = await fetchData("pretensions/list", param, true);

  return res;
};

export const solvePretenstion = async ({ _id, comment }) => {
  const res = await fetchData(
    "pretensions/solve",
    { solved_comment: comment, _id },
    true
  );

  return res;
};

export const coursesСonsider = async (_id) => {
  const res = await fetchData("courses/consider", { _id }, true);

  return res;
};

export const coursesReject = async (_id, reject_comment) => {
  const res = await fetchData("courses/reject", { _id, reject_comment }, true);

  return res;
};

export const coursesConfirm = async (_id, reject_comment) => {
  const res = await fetchData("courses/confirm", { _id, reject_comment }, true);

  return res;
};

export const getPretenstion = async (_id) => {
  const res = await fetchData("pretensions/entity", { _id }, true);

  return res;
};

export const getCoueses = async (param) => {
  const res = await fetchData("courses/list", param, true);

  return res;
};

export const getCourse = async (_id) => {
  const res = await fetchData("courses/entity", { _id }, true);

  return res;
};

export const getStudyGroup = async (_id) => {
  const res = await fetchData("study_groups/entity", { _id }, true);

  return res;
};

export const studyGroupIap = async (_id) => {
  const res = await fetchData("study_groups/iap", { _id }, true);

  return res;
};

export const getStudyGroups = async (param) => {
  const res = await fetchData("study_groups/list", param, true);

  return res;
};

export const getClients = async (param) => {
  const res = await fetchData("clients/list", param, true);

  return res;
};

export const getKYC = async (param) => {
  const res = await fetchData("kyc/list", param, true);

  return res;
};

export const getKYCEnity = async (_id) => {
  const res = await fetchData("kyc/entity", { _id }, true);

  return res;
};

export const getKYCResult = async (cl_id) => {
  const res = await fetchData("kyc/get_check_result", { cl_id }, true);

  return res;
};

export const verifyKYCEnity = async (param = {}) => {
  const res = await fetchData("kyc/verify", param, true);

  return res;
};

export const rejectKYCEnity = async (param = {}) => {
  const res = await fetchData("kyc/reject", param, true);

  return res;
};

export const sendToVerifyKYC = async (param = {}) => {
  const res = await fetchData("kyc/send_to_verify", param, true);

  return res;
};

export const verifySelfEmployed = async (param = {}) => {
  const res = await fetchData("kyc/verify_self_employed", param, true);

  return res;
};

export const getChatMember = async (_id) => {

  const res = await Settings.get('ws').rpc('client.getEntity', {
    list_name: 'chat_list',
    _id
  })

  return res.data[0];
};


export const getRequest = async (_id) => {
  const res = await Settings.get('ws').rpc('request.getEntity', {
    list_name: 'request_list',
    _id
  })

  return res.data[0];
};

export const confirmRequest = async ({ _id, comment }) => {
  const res = await Settings.get('ws').rpc("request.confirm", { _id, comment });

  return res;
};


export const completeRequest = async ({ _id, comment }) => {
  const res = await Settings.get('ws').rpc("request.complete", { _id, comment });

  return res;
};


export const cancelRequest = async ({ _id, comment }) => {
  const res = await Settings.get('ws').rpc("request.cancel", { _id, comment });

  return res;
};

export const readChat = async (_id) => {
  const res = await fetchData("chats/read", { _id }, true);

  return res;
};



export const getPartners = async (params = {}) => {
  return await Settings.get('ws').rpc('partner.getList', params)

};

export const getRequestTemplate = async (params = {}) => {
  return await Settings.get('ws').rpc('request_template.getList', params)

};

export const getNewsCategories = async (param) => {
  return await Settings.get('ws').rpc('client.getList', { list_name: 'news_category' })
};

export const getNewsEntity = async (_id) => {
  const res = await Settings.get('ws').rpc('news.getEntity', {
    _id
  })

  return res.data[0]
};


export const getServiceEntity = async (_id) => {
  const res = await Settings.get('ws').rpc('service.getEntity', {
    _id
  })

  return res.data[0]
};


export const getPartnerEntity = async (_id) => {
  const res = await Settings.get('ws').rpc('partner.getEntity', {
    _id
  })

  return res.data[0]
};

export const getEventEntity = async (_id) => {
  const res = await Settings.get('ws').rpc('event.getEntity', {
    _id
  });

  return res.data[0]
};





export const getNewChats = async (param) => {
  return await Settings.get('ws').rpc('users.getNewChats', {
    list_name: 'chat_list',
   ...param
  })
};

export const getMyChats = async (param) => {
  return await Settings.get('ws').rpc('client.getList', {
    list_name: 'chat_list',
    params: {
      last_message_id: '{NOT_NULL}',
      session_usr_id: '{caller_id}'
    },
    ...param
  })
};


export const getChats = async (param) => {
  return await Settings.get('ws').rpc('client.getList', { list_name: 'chat_list', ...param })
};


export const getRequests = async (param) => {
  return await Settings.get('ws').rpc('client.getList', { list_name: 'request_list', ...param })
};

export const getInvoices = async (param) => {
  return await Settings.get('ws').rpc('client.getList', { list_name: 'invoice_view', ...param })
};

export const getNews = async (param) => {
  return await Settings.get('ws').rpc('client.getList', { list_name: 'news_view', ...param })
};


export const getEvents = async (param) => {
  return await Settings.get('ws').rpc('client.getList', { list_name: 'event_view', ...param })
};

export const getEventSimpleList = async (param = {}) => {
  return await Settings.get('ws').rpc('event.getList', param)
};

export const getServiceCatigories = async (param = {}) => {
  return await Settings.get('ws').rpc('service.getList', {list_name: 'service_category', ...param})
};

export const getServiceType = async (param = {}) => {
  return await Settings.get('ws').rpc('service.getList', {list_name: 'service_type', ...param})
};




export const getServices = async (param) => {
  return await Settings.get('ws').rpc('client.getList', { list_name: 'service_view', ...param })
};



export const getChatMessages = async (body = {}) => {

  return await Settings.get('ws').rpc('message.getList', body)
};

export const getTechClients = async (param) => {
  const res = await fetchData("chats/user/available_members", param, true);

  return res;
};

export const getClientsChatMember = async (cl_id) => {
  const res = await fetchData("chats/client/member", { cl_id }, true);

  return res;
};

export const sendMessage = async (body = {}) => {
  return await Settings.get('ws').rpc('message.send', body)
};

export const getTransaction = async (_id) => {
  const res = await fetchData("transactions/entity", { _id }, true);

  return res;
};

export const getMyCourses = async (param) => {
  const res = await fetchData("courses/org/search", param, true);

  return res;
};

export const getMyStudyGroup = async (body) => {
  const res = await fetchData("study_groups/org/search", body, true);

  return res;
};

export const createFile = async (body) => {
  //const res = await fetchData("files", body, true);
  const res = await Settings.get('ws').rpc('file.getUploadUrl', {
    content_type: body.content_type,
    origin_uri: body.temp_url
  })

  return res;
};

export const removeFile = async (_id) => {
  const res = await fetchData("files", { _id }, true, "DELETE");

  return res;
};

export const fileIsReady = async (_id) => {
  const res = await fetchData("files/ready", { _id });

  return res;
};

export const getCreateCourseOptions = async function () {
  const res = await fetchData("courses/org/options", {}, true);

  return res;
};

export const getMyCourse = async (_id) => {
  const res = await fetchData("courses/org/entity", { _id }, true);

  return res;
};

export const auth = async (payload = {}) => {
  const res = await Settings.get('ws').rpc('users.auth', payload)
  return res;
};

export const deauth = async () => {
  const res = await fetchData("users/deauth", {}, true);

  return res;
};

export const parseSoclialLink = async (text) => {
  const res = await fetchData("social_links/check", { text }, true);

  return res;
};

export const getCurrentCounters = async (text) => {
  const res = await Settings.get('ws').rpc('users.getUpdates', {})

  return res;
};

export const getNewKYC = async (param) => {
  const res = await fetchData("updates/kyc", param, true);

  return res;
};

export const getNewCourses = async (param) => {
  const res = await fetchData("updates/courses", param, true);

  return res;
};

export const getNewTransactions = async (param) => {
  const res = await fetchData("updates/transactions", param, true);

  return res;
};
export const getNewPretensions = async (param) => {
  const res = await fetchData("updates/pretensions", param, true);

  return res;
};

export const getClientEnity = async (_id) => {

  const res = await Settings.get('ws').rpc('client.getEntity', {
    list_name: 'client_entity',
    _id
  })

  return res;
};

export const getSubscriptions = async (_id) => {
  const res = await fetchData("clients/subscriptions", {}, true);

  return res;
};

export const updateSubscription = async (body = {}) => {
  const res = await fetchData("clients/update_subscription", body, true);

  return res;
};

export const getArticles = async (param) => {
  const res = await fetchData("articles/list", param, true);

  return res;
};

export const getComments = async (param) => {
  const res = await fetchData("comments/list", param, true);

  return res;
};

export const getArticle = async (_id) => {
  const res = await fetchData("articles/entity", { _id }, true);

  return res;
};

export const articleСonsider = async (_id) => {
  const res = await fetchData("articles/consider", { _id }, true);

  return res;
};

export const articleReject = async (_id, reject_comment) => {
  const res = await fetchData("articles/reject", { _id, reject_comment }, true);

  return res;
};

export const articleConfirm = async (_id) => {
  const res = await fetchData("articles/confirm", { _id }, true);

  return res;
};

export const updateDescription = async (payload = {}) => {
  const res = await fetchData("study_groups/update/by_user", payload, true);

  return res;
};

export const getCategories = async (payload = {}) => {
  const res = await fetchData("courses/categories/list", payload, true);

  return res;
};

export const updateArticle = async (payload = {}) => {
  const res = await fetchData("articles", payload, true, "PUT");

  return res;
};

export const changeArticleAuthor = async (payload = {}) => {
  const res = await fetchData("articles/author/change", payload, true);

  return res;
};
