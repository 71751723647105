import Joi from "joi";
import { useFormCollector } from "../../stores/core/collector";
import TextInput from "../Form/TextInput";

export default function SearchBar(props) {

  const form = useFormCollector(
    {
      query: props.defaultQuery,
    },
    Joi.object({
      query: Joi.string().required().min(3).allow(""),
    })
  );

  const onKeyPress = (e) => {
    const keyCode = e.code || e.key;

    if (keyCode === "NumpadEnter" || keyCode === "Enter") {
      const v = form.validate2();

      if (v.error === null) {
        Promise.resolve(props.onQuerySubmit(form.get("query")));
      }
    }
  };

  return (
    <div>
      <TextInput
        onBlur={() => form.clearError("query")}
        onKeyPress={onKeyPress}
        name={`query`}
        collector={form}
        placeholder={"Поиск"}
      />
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {};
};
