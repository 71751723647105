import moment from "moment";
import React, { useMemo, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getChats, getInvoices, getNews, getRequests } from "../../api";
import Link from "../../components/Link";
import InnerHeader from "../../components/Page/InnerHeader";
import Table from "../../components/Table";
import TableEnd from "../../components/TableEnd";
import { getClasses } from "../../constants/theme";
import { omitUndefined } from "../../utils";
import Button from "../../components/Button";

const getColumns = (classes) => ({
  _id: {
    f: 0.1,
    title: "ID",
    converter: (_id) => {
      return <Link to={`/news/${_id}`}>{_id}</Link>;
    },
  },
  category_title:{
    f:.2,
    title: "Категория"
  },
  title: {
    f:2,
    title: "Заголовок"
  },
  published_at:{
    f: 0.5,
    title: "Опубликовано",
    converter: (published_at) => {
      return moment(published_at).format('D MMMM YY HH:mm');
    },
  }
});

export default function NewsList() {
  const classes = getClasses(dynamicStyle);
  const table = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const columns = useMemo(() => getColumns(classes), []);
  const serachOptions = useRef({
    query: searchParams.get("query") ?? void 0,
    action: searchParams.get("action") ?? "all",
  });

  const addNews = () => {
    navigate(`/news/create`)

  }

  return (
    <div className={classes("wrapper")}>
      <InnerHeader
        defaultQuery={serachOptions.current.query}
        onQuerySubmit={(query) => {
          if (query) {
            serachOptions.current.query = query;
          } else {
            delete serachOptions.current.query;
          }

          setSearchParams(omitUndefined(serachOptions.current));

          table.current.reload();
        }}
        title={"Чаты"}
      />
      <div className={classes("content")}>
      <div className={classes("filtersSection")}>
          <div className={classes('actions')}>
            <Button onClick={addNews} containerStyle={{paddingTop:8, paddingBottom:8, lineHeight:'normal'}} mode={'green'} text={'Добавить новость'} />
          </div>
        </div>

        <Table
          offset={130}
          ref={table}
          endComponent={<TableEnd />}
          limit={1000}
          columns={columns}
          fetchData={(param) =>
            getNews({ ...param, ...serachOptions.current })
          }
        />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    actions:{
      display:'flex',
      flex:1,
      justifyContent:'flex-end',
      marginRight:40
    },

    filtersSection: {
      marginBottom: 32,
    },
    content: {
      paddingLeft: 40,
      paddingTop: 30,
    },
    searchItem: {
      marginBottom: 4,
    },
    searchLabel: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    arraowDown: {
      color: Colors.accent2,
    },
    arraowUp: {
      color: Colors.color1,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  };
};
