import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import Section from "../../components/Layout/Section";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import { getClasses } from "../../constants/theme";
import {  useFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";
import Joi from "joi";
import TextInput from "../../components/Form/TextInput";
import PhotoLoader2 from "../../components/Form/PhotoLoader2";
import SelectPicker from "../../components/Form/SelectPicker";
import { errorHandler, getEventEntity, getNewsCategories, getNewsEntity, getPartnerEntity } from "../../api";
import JSONArea from "../../components/Form/JSONArea";



const useEntitty = (id, form) => {
  const [state, setState] = useState({
    entity: void 0,
    _ready: false,
    categories: []
  });

  useEffect(() => {
    Promise.all([id === 'create' ?
      Promise.resolve(void 0) :
      getEventEntity(id)])
      .then(([entity]) => {

        if (entity){
          entity.items = JSON.stringify(entity.items, null, ' ')
          form.reset(entity);
        }
        setState({
          entity: entity,
          _ready: true,
        })



      }).catch(err => errorHandler(err))

  }, [])

  return state
}


export default function CreateNewEvent(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('Название')

  const form = useFormCollector({
    _id: void 0,
    city: '',
    external:false,
    finished_at: moment().format(), 
    started_at:  moment().format(), 
    items: [],
    subtitle: '',
    title : '',
    url: void 0,
    logo: void 0,
    img: void 0,
    description:  '',
  }, Joi.object({
    _id: Joi.number().integer().min(1),
    title: Joi.string().min(1).required(),
    subtitle: Joi.string().min(1),
    img: Joi.object(),
    logo: Joi.object(),
    city: Joi.string().min(1),
    items: Joi.array().default([]),
    external: Joi.boolean().default(false),
    description: Joi.string().min(1),
    finished_at: Joi.date().required(),
    started_at: Joi.date().required(),
    url: Joi.string().min(1),



  }), (k, v) => {
    if (k === 'title') {
      setTitle(v)
    }
  })
  
  useEntitty(id, form)

  const upsert = async () => {
    try {
      const data = form.get()
      
      form.validate()

      console.log(data)


    } catch (err) {
      errorHandler(err)
    }

    console.log(form)
  }


  return (
    <InnerPage
      ready={true}
      Header={<InnerHeader breadcrumbs={[["Ноаости", "/news"], [title]]} />}
      MainContent={
        <div className={classes("content")}>
          <Section title={"Контент"}>
            <PageLineData
              title={"Заголовок"}
              value={<TextInput collector={form} name={'title'} />}
            />
            <PageLineData
              title={"Обложка"}
              value={<PhotoLoader2
                collector={form}
                name={'img'}
              />}
            />

            <PageLineData
              title={"Логотип"}
              value={<PhotoLoader2
                collector={form}
                name={'logo'}
              />}
            />

            <PageLineData
              title={"Событие Росконгресса"}
              value={<SelectPicker
                name={"external"}
                collector={form}
       
                items={[
                  {
                    value: true,
                    label: 'Да',
                  },  {
                    value: false,
                    label: 'Нет',
                  }
                  
                ]}
              />}
            />
            <PageLineData
              title={"Подзаголовок"}
              value={<TextArea collector={form} name={'subtitle'} />}
            />

            <PageLineData
              title={"Описание"}
              value={<TextArea collector={form} name={'description'} />}
            />

            <PageLineData
              title={"Ссылка на сайт"}
              value={<TextInput collector={form} name={'url'} />}
            />


            <PageLineData
              title={"Адресс"}
              value={<TextInput collector={form} name={'city'} />}
            />

            <PageLineData
              title={"Таймлайн"}
              value={<JSONArea collector={form} name={'items'} />}
            />


          </Section>


        </div>
      }
      RightContent={
        <div className={classes("rightConent")}>
          <Button
            containerStyle={{ marginBottom: 8 }}
            text={"Сохранить"}
            onClick={upsert}
            mode={"green"}
          />
        </div>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    clientType: {
      color: Colors.color1,
    },
    buttonLink: {
      cursor: "pointer",
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
    },
    rejectText: {
      color: Colors.red90,
    },
    commentWrapper: {
      marginTop: 16,
    },
    content: {},
    rightConent: {},
  };
};
