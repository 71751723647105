const CurrencyCode = {
  USD: {
    symbol: "$",
    name: "US Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "USD",
    name_plural: "US dollars",
  },
  EUR: {
    symbol: "€",
    name: "Euro",
    symbol_native: "€",
    decimal_digits: 2,
    rounding: 0,
    code: "EUR",
    name_plural: "euros",
  },
  RUB: {
    symbol: "RUB",
    name: "Russian Ruble",
    symbol_native: "₽",
    decimal_digits: 2,
    rounding: 0,
    code: "RUB",
    name_plural: "Russian rubles",
  },
};

const kv = {
  denominationCoef: { XBT: 1000000000, "*": 100 },
};

export const getCurrency = (code, type = "symbol_native") => {
  if (CurrencyCode[code] && CurrencyCode[code][type]) {
    return CurrencyCode[code][type];
  } else {
    return code;
  }
};

export const calcDenomination = function (_from, _to) {
  const from = kv.denominationCoef[_from] || kv.denominationCoef["*"];
  const to = kv.denominationCoef[_to] || kv.denominationCoef["*"];
  return to / from;
};

export const baseToMinor = function (value, currency) {
  const coef = kv.denominationCoef[currency] || kv.denominationCoef["*"];
  return Math.round(value * coef);
};

export const minorToBase = function (value, currency) {
  const coef = kv.denominationCoef[currency] || kv.denominationCoef["*"];
  return value / coef;
};

export const beautifyAmount = (
  { amount, currency, action },
  withAction = true,
  needConvertToBase = true
) => {

  console.log( amount, currency)
  if (needConvertToBase) {
    amount = minorToBase(amount, currency);
  }

  let v = amount;
  let operator = "";

  if (global.HermesInternal != null) {
    const separator = "ru";
    v = String(amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + separator);
  } else {
    v = (+amount).toLocaleString("ru");
  }

  if (action !== undefined && withAction) {
    if (action === "withdraw") {
      operator = "-";
    } else {
      operator = "+";
    }
  }

  return `${operator}${v} ${getCurrency(currency)}`;
};
