import { forwardRef, useImperativeHandle, useRef } from "react";
import { ArrowUp, Paperclip, X } from "react-feather";
import { createFile, errorHandler, sendMessage, uploadBinaryFile } from "../../../api";
import AddAttachments from "../../../components/AddAttachments";
import { getClasses } from "../../../constants/theme";
import { useFormCollectorWithState } from "../../../stores/core/collector";
import { UUIDGeneratorBrowser, getQp } from "../../../utils";
import moment from "moment";
import Message from "./Message";

export default forwardRef(function ChatInput(props, ref) {
  const fFile = useRef();
  const classes = getClasses(dynamicStyle);


  useImperativeHandle(ref, () => ({
    addAnswer: (msg) => {
      form.set('message', msg)
    },
  }));

  const [form, state] = useFormCollectorWithState({
    text: "",
    attachments: [],
    loading: false,
    message: void 0,
  });

  const send = async () => {
    try {
      const data = form.get();

      if (data.message) {
        data.message.message = void 0;
      }

      const res = await sendMessage({
        text: data.text || '',
        _rmid: UUIDGeneratorBrowser('msg:'),
        type: 'text',
        attachments: [],
        request: void 0,
        message: data.message,
        cl_id: props.clId,
        created_at: moment().format(),
      });



      props.list.current.insertBefore(res);
      props.onMessageSent(res);
      form.set("text", "");
      form.set("message", void 0);
    } catch (err) {
      errorHandler(err);
    }
  };

  const onFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    try {
      const res = await createFile({
        content_type: file.type,
        temp_url: file.name
      });


      await uploadBinaryFile({
        upload_url: res.upload_url,
        content_type: file.type,
        file: file,
        onProgress: progress => {
          console.log(progress)
        },
      });

      const msg = await sendMessage({
        text: 'empty',
        _rmid: UUIDGeneratorBrowser('msg:'),
        type: 'attachment',
        attachments: [{
          content_type: file.type,
          filename: file.name,
          height: file.height,
          // loaded
          // progress
          size: file.size,
          storage_bucketname: res.storage_bucketname,
          storage_endpoint: res.storage_endpoint,
          type: "media",
          url: res.filename,
          width: file.width,
        }],
        request: void 0,
        message: void 0,
        cl_id: props.clId,
        created_at: moment().format(),
      });

      props.list.current.insertBefore(msg);

      props.onMessageSent(msg);

    } catch (err) {

    }
  }

  const onKeyDown = e => {
    if (e.key === 'Enter' && e.shiftKey === false){
      if (form.get('text').length > 1)
        send();
      e.target.style.height =  0 + "px";
    } else {
      e.target.style.height = "auto";
      e.target.style.height =  e.target.scrollHeight * 2 + "px";
    }
  }

  return (
    <>
      <div className={props.class}>
        {state.message && <div className={classes("answerMessage")}>
          <div className={classes('answerWrapper')}>
            <div className={classes('answerMessageLabel')}>Ответ на сообщение:</div>
            <Message hideActions={true} message={state.message} />
          </div>
          <div onClick={() => form.set('message', void 0)} className={classes('closeIcon')}>
            <X />
          </div>
        </div>}

        <div className={classes("container")}>
          <textarea
            value={state.text}
            onChange={(event) => form.set("text", event.target.value)}
            className={classes("input")}
            placeholder="Сообщение"
            onKeyDown={onKeyDown}

          />
          <Paperclip
            onClick={() => fFile.current.click()}
            size={24}
            className={classes("attachIcon")}
          />
          <div onClick={send} className={classes("sendButton")}>
            <ArrowUp size={24} className={classes("sendIcon")} />
          </div>
        </div>
      </div>
      <input
        ref={fFile}
        className={classes("fileInput")}
        type="file"
        onChange={onFileChange}
      />
    </>
  );
})

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    closeIcon: {
      marginTop: 8,
      marginRight: 16,
      cursor: 'pointer'
    },
    answerWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginBottom: 8
    },
    answerMessageLabel: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      paddingTop: 8,
      marginBottom: 8,
      marginLeft: -4
    },
    answerMessage: {
      backgroundColor: '#ffffff',
      marginLeft: -38,
      marginRight: -30,
      paddingLeft: 16,
      borderBottom: `1px solid ${Colors.neutral3}`,
      borderTop: `1px solid ${Colors.neutral4}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'

    },
    fileInput: {
      display: "none",
    },
    sendButton: {
      backgroundColor: Colors.green90,
      display: "flex",
      alignItems: "center",
      borderRadius: 24,
      height: 32,
      width: 32,
      justifyContent: "center",
      marginLeft: 8,
      cursor: "pointer",
    },
    sendIcon: {
      color: Colors.n0,
    },
    attachIcon: {
      cursor: "pointer",
      color: Colors.green90,
    },
    input: {
      marginLeft: 22,
      marginRight: 12,
      display: "flex",
      flex: 1,
      ...Fonts.tableData,
      color: Colors.n90,
      outline: "none",
      border: "none",
      paddingTop: 13
    },

    "input::placeholder": {
      color: Colors.n60,
      ...Fonts.tableData,
    },

    container: {
      minHeight: 60,
      backgroundColor: Colors.n0,
      marginLeft: -38,
      marginRight: -30,
      display: "flex",
      alignItems: "center",
      paddingRight: 22,
    },
  };
};
