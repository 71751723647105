import { getClasses } from "../../constants/theme";
import Link from "../Link";

export default function PageData1(props) {
  const classes = getClasses(dynamicStyle);

  return (
    <div className={classes("container", props.withoutMargin && 'withoutMargin')}>
      {props.title && <div className={classes("title")}>{props.title}</div>}
      <div className={classes("body")}>
        {props.data.map((item) => {
          return (
            <div className={classes("item")}>
              <div className={classes("itemTitle")}>{item.title}</div>
              {item.link ? (
                <div className={classes("itemValue")}>
                  <Link to={item.link}>{item.value}</Link>
                </div>
              ) : (
                <div className={classes("itemValue")}>{item.value}</div>
              )}
            </div>
          );
        })}
      </div>
      {props.children}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    item: {
      marginRight: 54,
    },
    itemTitle: {
      ...Fonts.inputHeader,
      color: Colors.n70,
      marginBottom:4
    },
    itemValue: {
      ...Fonts.inputData,
      color: Colors.n90,
    },
    title: {
      ...Fonts.h2,
      color: Colors.n90,
    },
    body: {
      display: "flex",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 24,
    },
    withoutMargin:{
      marginBottom: 0,
    }
  };
};
