import React, { useEffect, useRef, useState } from "react";
import { Settings } from "../stores/Settings";
import { getClasses } from "./../constants/theme/index";

export default function Notifications(props) {
  const classes = getClasses(dynamicStyle);
  const [state, setState] = useState();
  const interval = useRef();

  useEffect(() => {
    return Settings.on("local_notification", (n) => {
      if (state !== undefined){
        clearTimeout(interval.current);
        setState(void 0);
      }

      let level = "green";
      let title = "Уведомление";
      let text = "";

      if (typeof n === "string") {
        text = n;
      } else {
        n.level && (level = n.level);
        n.text && (text = n.text);
        n.title && (title = n.title);
      }

      setState({ level, title, text });

      interval.current = setTimeout(() => {
        setState(void 0);
      }, 15000);
    });
  }, []);

  if (state === undefined) return null;

  return (
    <div className={classes("container")}>
      <div className={classes("item", state.level)}>
        <div className={classes("title")}>{state.title}</div>
        <div className={classes("text")}>{state.text}</div>
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    red: {
      backgroundColor: Colors.accent2,
    },
    green: {
      backgroundColor: Colors.color1,
    },
    item: {
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 6,
      minWidth:300
    },
    title: {
      ...Fonts.mainMenu,
      color: Colors.n0,
    },
    text: {
      ...Fonts.tableData,
      color: Colors.n0,
    },
    container: {
      position: "fixed",
      top: 16,
      right: 24,
    },
    logoText: {
      ...Fonts.title2,
      color: Colors.neutral1,
      marginLeft: 8,
    },
  };
};
