import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  cancelRequest,
  completeRequest,
  confirmRequest,
  errorHandler,
  getRequest,

} from "../../api";
import Button from "../../components/Button";
import PageData1 from "../../components/Data/PageData1";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import InfinityList from "../../components/InfinityList";
import Title2 from "../../components/Layout/Title2";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import Statuses from "../../components/Statuses";
import { getClasses } from "../../constants/theme";
import { getFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";
import Section from "../../components/Layout/Section";
import ClientInfo from "../../components/ClientInfo";
import StatusList from "../../components/Status";
import { getTheme } from "../../utils";

const useRequest = (_id) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    getRequest(_id)
      .then((res) => setState({ ...res, _ready: true }))
      .catch((err) => errorHandler(err));
  }, []);

  return [state, (cb) => setState({ ...cb(state) })];
};

export default function Request(props) {
  const classes = getClasses(dynamicStyle);
  const styles = getTheme(dynamicInlineStyle);

  const { id } = useParams();
  const [request, updater] = useRequest(id);
  const list = useRef();
  const page = useRef();

  const form = getFormCollector(
    {
      text: request.comment,
    },
    {},
  );


  const confirm = async () => {
    // Взяьб в обработку
    try {
      let comment = form.get('text');
      if (comment == '')
        comment = undefined;

      const res = await confirmRequest({ _id: request._id, comment })

      updater(state => {
        state.confirmed = true;
        state.confirmed_at = res.confirmed_at;
        state.user = {
          last_name: Settings.get('me.last_name'),
          first_name: Settings.get('me.first_name')
        }
        return state;
      })

      console.log('text', res)
    } catch (err) {
      errorHandler(err)
    }
  }

  const complete = async () => {
    // Подтвердить
    try {
      let comment = form.get('text');
      if (comment == '')
        comment = undefined;

      const res = await completeRequest({ _id: request._id, comment })

      updater(state => {
        // state
        state.completed_at = res.completed_at;
        state.completed = true;

        return state;
      })

      console.log('text', res)
    } catch (err) {
      errorHandler(err)
    }
  }

  const cancel = async () => {
    // Отклонить
    try {
      let comment = form.get('text');
      if (comment == '')
        comment = undefined;

      const res = await cancelRequest({ _id: request._id, comment })

      updater(state => {
        // state
        state.canceled_at = res.canceled_at;
        state.canceled = true;

        return state;
      })

      console.log('text', res)
    } catch (err) {
      errorHandler(err)
    }
  }





  return (
    <InnerPage
      ref={page}
      ready={request._ready}
      Header={<InnerHeader breadcrumbs={[["Запросы", "/requests"], [request.title]]} />}
      contentClass={classes("contentClass")}
      MainContent={
        <div className={classes("content")}>
          <Section title={"Запрос"}>

            <PageData1
              data={[
                {
                  title: "ID Запроса",
                  value: request._id,
                },
                {
                  title: "Статус",
                  value: (
                    <StatusList statusStrings={
                      [
                        { title: 'Новый', mode: 'green', value: !request.canceled && !request.completed && !request.confirmed },
                        { title: 'В обработке', mode: 'orange', value: !request.canceled && !request.completed && request.confirmed },
                        { title: 'Завершен', value: !request.canceled && request.completed && request.confirmed },
                        { title: 'Отклонен', mode: 'red', value: request.canceled }
                      ].filter(t => !!t.value)
                    } />
                  ),
                },
                {
                  title: "Сообщение",
                  value: <Link to={`/chats/${request?.client?._id}?request_msg=${request.msg_id}`}>Перейти к сообщению</Link>,
                },

              ]}
            />

            <PageData1
              data={[
                {
                  title: "Дата создания",
                  value: moment(request.created_at).format("HH:mm DD.MM.YYYY"),
                }, {
                  title: "Взят в обработку",
                  value: request.confirmed_at ? moment(request.confirmed_at).format("HH:mm DD.MM.YYYY") : '-',
                },
                {
                  title: "Завершен",
                  value: request.completed_at ? moment(request.completed_at).format("HH:mm DD.MM.YYYY") : '-',
                },
                {
                  title: "Отменен",
                  value: request.canceled_at ? moment(request.canceled_at).format("HH:mm DD.MM.YYYY") : '-',
                },

              ]}
            />

            <PageLineData title={"Заголовок"} value={request.title} />
            <PageLineData title={"Описание"} value={request.text} />
            <PageLineData title={"Ответственный"} value={request.user ? `${request.user.first_name} ${request.user.last_name}` : 'Не назначен'} />

            <PageLineData title={"Комментарий клиенту"} value={<TextArea
              disabled={request.completed || request.canceled}
              containerStyle={styles.inputContainerStyle}
              inputStyle={styles.input}
              collector={form}
              name={"text"}
              placeholder={"Комментарий"}
            />} />




          </Section>


        </div>
      }
      RightContent={
        <div className={classes("rightConent")}>
          <Section title={"Действия"}>
            {request.confirmed === false && request.canceled !== true &&
              <Button
                containerStyle={{ marginBottom: 8 }}
                onClick={confirm}
                text={"Взять в обработку"}
              />}

            {request.confirmed && request.completed === false && request.canceled !== true &&  <Button
              containerStyle={{ marginBottom: 8 }}
              onClick={complete}
              text={"Подтвердить выполнение"}
              mode={'green'}
            />}

            {request.completed === false && request.canceled !== true &&  <Button
              containerStyle={{ marginBottom: 8 }}
              onClick={cancel}
              text={"Отклонить"}
              mode={'red'}
            />}
          </Section>



          <ClientInfo client={request.client} />

        </div>
      }
    />
  );
}

const dynamicInlineStyle = (Colors, Fonts) => {
  return {
    inputContainerStyle: {
      marginTop: 8,
      paddingLeft: 0,
      paddingRight: 0,
    },

    input: {
      ...Fonts.subhead1,
      color: Colors.neutral1,
    },
  };
};

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    clientnoreadLantMessage: {
      color: Colors.accent1,

    },
    clientreadLantMessage: {
      color: Colors.blue90,

    },
    inputContainer: {
      position: "fixed",
      bottom: 0,
      right: 419,
      left: 332,
    },
    listContainer: {},
    list: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      justifyContent: "flex-end",
      marginBottom: 90,
    },
    rejectText: {
      color: Colors.red90,
    },
    commentWrapper: {
      marginTop: 16,
    },
    contentClass: {
      backgroundColor: `${Colors.n20}!important`,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },

    rightConent: {},
    buttonLink: {
      marginTop: -8,
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
    },
  };
};
