import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button";
import AlertContainer from "../../components/Data/AlertContainer";
import PageData1 from "../../components/Data/PageData1";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import Section from "../../components/Layout/Section";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import Statuses from "../../components/Statuses";
import { getClasses } from "../../constants/theme";
import createChat from "../../methods/createChat";
import { getFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";


export default function KYC(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const navigate = useNavigate();


  return (
    <InnerPage
      ready={true}
      Header={<InnerHeader breadcrumbs={[["Чаты", "/kyc"], ['Новый']]} />}
      MainContent={
        <div className={classes("content")}>
          <Section title={"Новый клиент"}>
          </Section>


        </div>
      }
      RightContent={
        <div className={classes("rightConent")}>
          <Button
            containerStyle={{ marginBottom: 8 }}
            text={"Сохранить"}
            onClick={() => { }}
            mode={"green"}
          />
        </div>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    clientType: {
      color: Colors.color1,
    },
    buttonLink: {
      cursor: "pointer",
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
    },
    rejectText: {
      color: Colors.red90,
    },
    commentWrapper: {
      marginTop: 16,
    },
    content: {},
    rightConent: {},
  };
};
