/* eslint-disable no-unused-vars */
export const white = '#FFFFFF';
export const black = '#000000';

export const neutral1 = '#060606';
export const neutral2 = '#7E94AA';
export const neutral3 = '#AFC2D4';
export const neutral4 = '#F2F6F7';
export const neutral5 = '#FFFFFF';

export const color1 = '#17AD50';
export const color2 = '#00CE70';
export const color3 = '#80E7B7';
export const color4 = '#D8FDEC';

export const accent1 = '#F9A825';
export const accent2 = '#FF503F';
export const accent3 = '#FEEED3';

export const modal = '#00000066';

export const backgroundLayout = '#FFFFFF';
export const red = '#FF503F';

export const baseIconColor = '#241d35';
export const ripple = '#B3B3B3';

export const disabled = '#ACB7C2';

export const chatBackground = '#E5E5E5';
export const myMessageBackground = '#D8FFE7';
export const messageBackground = '#FFFFFF';

export const absoluteWhite = '#FFFFFF';
export const absoluteBlack = '#000000';

export const messageSelected = '#80E7B7';
export const content = '#251D35';


// new //

export const n90 = '#121420';
export const n70 = '#64677A';
export const n50 = '#BCC8D6';
export const n40 = '#E5EAEF';
export const n30 = '#F3F5F8';
export const n20 = '#FAFAFB';
export const n0 = '#FFFFFF';

export const green90 = '#09AF51';
export const green10 = '#EFFBEF';

export const red90 = '#F53B3B';
export const red10 = '#FFE8E1';

export const orange90 = '#FF961E';
export const orange10 = '#FAF8F4';

export const blue90 = '#0560FD';
export const blue10 = '#E9F5FF';

