import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import Section from "../../components/Layout/Section";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import { getClasses } from "../../constants/theme";
import {  useFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";
import Joi from "joi";
import TextInput from "../../components/Form/TextInput";
import PhotoLoader2 from "../../components/Form/PhotoLoader2";
import SelectPicker from "../../components/Form/SelectPicker";
import { errorHandler, getNewsCategories, getNewsEntity } from "../../api";



const useEntitty = (id, form) => {
  const [state, setState] = useState({
    entity: void 0,
    _ready: false,
    categories: []
  });

  useEffect(() => {
    Promise.all([getNewsCategories(),
    id === 'create' ?
      Promise.resolve(void 0) :
      getNewsEntity(id)])
      .then(([categories, entity]) => {

        if (entity){
          entity.paragraphs = entity.paragraphs.join('\r')
          form.reset(entity);
        }
        setState({
          entity: entity,
          _ready: true,
          categories: categories.data
        })



      }).catch(err => errorHandler(err))

  }, [])

  return state
}


export default function CreateNewNews(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('Название')

  const form = useFormCollector({
    _id: void 0,
    title: '',
    subtitle: '',
    img: void 0,
    category_id: void 0,
    paragraphs: void 0,
  }, Joi.object({
    _id: Joi.number().integer().min(1),
    title: Joi.string().min(1).required(),
    subtitle: Joi.string().min(1),
    img: Joi.object(),
    category_id: Joi.number().integer().min(1).required(),
    paragraphs: Joi.string().min(1),
  }), (k, v) => {
    if (k === 'title') {
      setTitle(v)
    }
  })
  
  const { categories } = useEntitty(id, form)


  const upsert = async () => {
    try {
      const data = form.get()
      
      form.validate()

      data.paragraphs = data.paragraphs.split('\r').filter(t => !!t)

      console.log(data)


    } catch (err) {
      errorHandler(err)
    }

    console.log(form)
  }


  return (
    <InnerPage
      ready={true}
      Header={<InnerHeader breadcrumbs={[["Ноаости", "/news"], [title]]} />}
      MainContent={
        <div className={classes("content")}>
          <Section title={"Контент"}>
            <PageLineData
              title={"Заголовок"}
              value={<TextInput collector={form} name={'title'} />}
            />
            <PageLineData
              title={"Обложка"}
              value={<PhotoLoader2
                collector={form}
                name={'img'}
              />}
            />


            <PageLineData
              title={"Категория"}
              value={<SelectPicker
                name={"category_id"}
                collector={form}
                placeholder={{
                  value: undefined,
                  label: 'Выберите категорию'
                }}
                items={categories.map((cat) => {
                  return {
                    value: cat._id,
                    label: cat.title,
                  };
                })}
              />}
            />

            <PageLineData
              title={"Подзаголовок"}
              value={<TextArea collector={form} name={'subtitle'} />}
            />

            <PageLineData
              title={"Новость"}
              value={<TextArea collector={form} name={'paragraphs'} />}
            />

          </Section>


        </div>
      }
      RightContent={
        <div className={classes("rightConent")}>
          <Button
            containerStyle={{ marginBottom: 8 }}
            text={"Сохранить"}
            onClick={upsert}
            mode={"green"}
          />
        </div>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    clientType: {
      color: Colors.color1,
    },
    buttonLink: {
      cursor: "pointer",
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
    },
    rejectText: {
      color: Colors.red90,
    },
    commentWrapper: {
      marginTop: 16,
    },
    content: {},
    rightConent: {},
  };
};
