import { getClasses } from "../constants/theme";


export default function StatusList(props) {
  const classes = getClasses(dynamicStyle);

  return (
    <div className={classes(props.rowMode ? "rowitems" : "items")}>
      {props.statusStrings.map((s) => {
        const {title, mode} = s;
        return <div className={classes(props.rowMode ? "status" : "status2", mode)}>{title}</div>;
      })}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    items: {
      display: "flex",
    },
    rowitems: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
    status: {
      ...Fonts.subhead2,
      color: Colors.neutral2,
      backgroundColor: "#F3F5F8",
      padding: "4px 12px",
      borderRadius: 8,
      marginBottom: 2,
    },
    status2: {
      ...Fonts.subhead2,
      color: Colors.neutral2,
      backgroundColor: "#F3F5F8",
      padding: "4px 12px",
      borderRadius: 8,
      marginRight:4
    },
    green: {
      color: "#09AF51",
      backgroundColor: "#EFFBEF",
    },
    red: {
      color: "#F53B3B",
      backgroundColor: "#FFEEE1",
    },
    orange: {
      color: "#FF961E",
      backgroundColor: "#FAF8F4",
    },
  };
};
