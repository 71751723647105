import React, { useState, useRef } from "react";
import { useTheme } from "../../constants/theme";
import Button from "../Button";
import { createFile, uploadBinaryFile } from "../../api";
import ViewBlockPhoto from "../BlockView/blocks/ViewBlockPhoto";

export default function PhotoLoader2({
  collector,
  name,
  label,
  multiline,
  disabled,
  hr,
  onTestValue,
  containerStyle = {},
  containerRef,
  ...props
}) {
  const [img, setImg] = useState(() => collector.get(name));
  const [error, setError] = useState();
  const inputRef = useRef();

  collector.resetCallback(name, (value) => setImg(value));
  collector.errorCallback(name, (value) => setError(value));

  const styles = useTheme(dynamicStyle);

  const onFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    try {
      const res = await createFile({
        content_type: file.type,
        temp_url: file.name
      });

      await uploadBinaryFile({
        upload_url: res.upload_url,
        content_type: file.type,
        file: file,
        onProgress: progress => {
          console.log(progress)
        },
      });


      const img = {
        content_type: file.type,
        filename: file.name,
        height: file.height,
        size: file.size,
        storage_bucketname: res.storage_bucketname,
        storage_endpoint: res.storage_endpoint,
        type: "media",
        url: res.filename,
        width: file.width,
      }

      collector.set(name, img, setImg, onTestValue)


    } catch (err) {

    }
  }

  return (
    <div
      style={{
        ...styles.container,
        ...containerStyle,

      }}
    >
      {img ? <>
        <div>
           <ViewBlockPhoto block={img} />
        </div>
      </> : <>
      <Button text={'Загрузить'} onClick={() => inputRef.current.click()} /></>}
      <input
        ref={inputRef}
        style={styles.fileInput}
        type="file"
        accept="image/jpeg,image/png"
        onChange={onFileChange}
      />
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    fileInput: {
      display: "none",
    },
    container: {
      flexDirection: "row",
      paddingHorizontal: 16,
      display: "flex",
    },
    input: {
      ...Fonts.subhead1,
      padding: 0,
      width: "100%",
      minHeight: 44,
      justifyContent: "center",
      color: Colors.neutral1,
      backgroundColor: Colors.neutral4,
      borderWidth: 0,
      borderRadius: 10,
      paddingLeft: 16,
      paddingRight: 16,
      display: "flex",
      outlineColor: Colors.neutral3,
    },
    disabled: {
      color: Colors.neutral2,
    },
    error: {
      backgroundColor: Colors.accent3,
      outline: `1px solid ${Colors.accent2}`
    },
  };
};
