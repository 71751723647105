import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import renderBlockHeader1 from "./blocks/renderBlockHeader1";
import renderBlockHeader2 from "./blocks/renderBlockHeader2";
import renderBlockText from "./blocks/renderBlockText";
import renderBlockHighlight from "./blocks/renderBlockHighlight";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { getUID } from "./../../utils";
import { Loader } from "../../stores/Loader";
import Button from "../Button";
import Switch from "../Switch";
import renderBlockPhoto from "./blocks/renderBlockPhoto";
import renderBlockVideo from "./blocks/renderBlockVideo";
import renderBlockAudio from "./blocks/renderBlockAudio";
import renderBlockBuiltInPDF from "./blocks/renderBlockBuiltInPDF";
import renderBlockHTMLEditor from "./blocks/renderBlockHTMLEditor";

const blocksTypes = {
  header1: renderBlockHeader1,
  header2: renderBlockHeader2,
  text: renderBlockText,
  highlight: renderBlockHighlight,
  photo: renderBlockPhoto,
  video: renderBlockVideo,
  audio: renderBlockAudio,
  builtInPDF: renderBlockBuiltInPDF,
  html: renderBlockHTMLEditor
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  paddingTop: 0.1,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({});

const MainLesonBlock = React.forwardRef(function (props, ref) {
  const formField = props.formField;
  const [blocks, setBlocks] = useState(props.form.link(formField,[]));
  const sw = useRef(void 0);
  const disabled = props.disabled;

  useImperativeHandle(ref, () => ({
    show: showDialog,
  }));

  useEffect(() => {
    if (props.emitter) {
      return props.emitter.on(formField, (b) => {
        setBlocks([...b]);
      });
    }
  }, [formField, props.emitter]);

  const removeBlock = useCallback(
    (item) => {
      const b = props.form.get(formField,[]).find((el) => el.id === item.id);

      if ((b.type === "photo" || b.type === "video") && b?.value?.tmpid) {
        Loader.delete(b?.value?.tmpid, b?.value?._id);
      }

      props.form.set(
        formField,
        props.form.get(formField,[]).filter((el) => el.id !== item.id)
      );
    },
    [props.form, formField]
  );

  const showDialog = useCallback((item, e) => {
    sw.current && sw.current.show(item, e);
  }, []);

  const moveBlock = (block, direction) => {
    const _blocks = props.form.get(formField,[]);
    const currentIndex = _blocks.findIndex((item) => item.id === block.id);
    _blocks.splice(currentIndex, 1);
    _blocks.splice(currentIndex + direction, 0, block);

    props.form.set(formField, [..._blocks]);
  };

  const addBlock = useCallback(
    (item, type, defValue) => {
      const el = {
        id: getUID(),
        type: type,
        value: defValue,
      };
      const _blocks = props.form.get(formField, []);
      const index =
        item === void 0
          ? _blocks.length
          : _blocks.findIndex((block) => block.id === item.id) + 1;
      _blocks.splice(index, 0, el);

      props.form.set(formField, [..._blocks]);
    },
    [props.form, formField]
  );

  const saveValue = (id, value) => {
    const _blocks = props.form.get(formField);
    const index = _blocks.findIndex((block) => block.id === id);

    props.form.set(`${formField}.${index}.value`, value);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      blocks,
      result.source.index,
      result.destination.index
    );

    props.form.set(formField, [...items]);
  };

  const onDragStart = () => {};

  return (
    <>
      <div style={props.containerStyle}>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {blocks.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {blocksTypes[item.type](
                            item,
                            props.scrollRef,
                            {
                              showDialog,
                              removeBlock,
                              moveBlock,
                              saveValue,
                            },
                            {
                              formType: props.formType,
                              disabled,
                              disableActions:
                                index === 0 &&
                                props.firstDisableAction !== undefined
                                  ? props.firstDisableAction
                                  : props.disableActions,
                              ...props.options[item.type],
                            }
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {disabled !== true && props.showAddButton === true && (
          <Button
            containerStyle={
              props.buttonContainerStyle
                ? props.buttonContainerStyle
                : { marginTop: 16 }
            }
            text={props.buttonText}
            onClick={(e) => showDialog(void 0, e)}
          />
        )}
      </div>

      <Switch
        ref={sw}
        data={[
          props.blocks.includes("header1") && {
            title: "Заголовок 1 уровня",
            handler: (item) => addBlock(item, "header1", ""),
          },
          props.blocks.includes("header2") && {
            title: "Заголовок 2 уровня",
            handler: (item) => addBlock(item, "header2", ""),
          },
          props.blocks.includes("text") && {
            title: "Текстовый блок",
            handler: (item) => addBlock(item, "text", ""),
          },
          props.blocks.includes("html") && {
            title: "Текстовый редактор",
            handler: (item) => addBlock(item, "html", ""),
          },
          props.blocks.includes("highlight") && {
            title: "Выделенный текст",
            handler: (item) => addBlock(item, "highlight", ""),
          },
          props.blocks.includes("photo") && {
            title: "Картинка",
            handler: (item) => addBlock(item, "photo", {}),
          },
          props.blocks.includes("builtInPDF") && {
            title: "Встроенный PDF",
            handler: (item) => addBlock(item, "builtInPDF", {}),
          },
          props.blocks.includes("video") && {
            title: "Видео",
            handler: (item) => addBlock(item, "video", {}),
          },
          props.blocks.includes("audio") && {
            title: "Аудио",
            handler: (item) => addBlock(item, "audio", {}),
          },
        ].filter((e) => !!e)}
      />
    </>
  );
});

MainLesonBlock.defaultProps = {
  blocks: [
    "photo",
    "video",
    "header1",
    "header2",
    "text",
    "highlight",
    "audio",
    "builtInPDF",
    "html"
  ],
  showAddButton: true,
  options: {
    photo: {},
    video: {},
    header1: {},
    header2: {},
    text: {},
    highlight: {},
  },
};

export default MainLesonBlock;
