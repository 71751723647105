import moment from "moment";
import React, { useMemo, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getChats, getMyChats, getNewChats } from "../../api";
import Link from "../../components/Link";
import InnerHeader from "../../components/Page/InnerHeader";
import Table from "../../components/Table";
import TableEnd from "../../components/TableEnd";
import { getClasses } from "../../constants/theme";
import { omitUndefined } from "../../utils";
import RadioTabs from "../../components/RadioTabs";
import Button from "../../components/Button";
import { PlusCircle } from "react-feather";

const getColumns = (classes) => ({
  _id: {
    f: 0.1,
    title: "ID",
    converter: (_id) => {
      return <Link to={`/chats/${_id}`}>{_id}</Link>;
    },
  },
  client_firstname: {
    title: "Пользователь",
    converter: (client_firstname, item) => {
      return (
        <Link to={`/chats/${item._id}`}>
          {`${item.first_name} ${item.last_name}`}
        </Link>
      );
    },
  },
  phone_number: {
    f: 0.6,
    title: "Телефон"
  },
  text: {
    f: 1,
    title: "Последнее сообщение",
    converter: (text, item) => {
      return text;
    },
  },
  message_usr_id:{   
    f: 0.3,
    title: "Есть ответ",
    converter: (message_usr_id, item) => {
      return !message_usr_id && item.session_usr_id  ? '❗' : ''
    }
  },
  has_unread_messages: {
    f: 0.6,
    title: "Есть непрочитанное",
    converter: (has_unread_messages) => {
      return has_unread_messages ? '💬' : ''
    },
  },
  session_first_name: {
    f: 1,
    title: "Оператор",
    converter: (client_firstname, item) => {
      if (!item.session_id) {
        return 'Нет ответственного'
      } else
        return (
          <Link to={`/chats/${item._id}`}>
            {`${item.session_first_name} ${item.session_last_name}`}
          </Link>
        );
    },
  }
});

export default function ChatList() {
  const classes = getClasses(dynamicStyle);
  const table = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const columns = useMemo(() => getColumns(classes), []);
  const navigate = useNavigate();

  const serachOptions = useRef({
    query: searchParams.get("query") ?? void 0,
    mode: searchParams.get("mode") ?? "all",
  });

  const goToAddNewClient = () => {
    navigate(`/chats/create`)
  }

  return (
    <div className={classes("wrapper")}>
      <InnerHeader
        defaultQuery={serachOptions.current.query}
        onQuerySubmit={(query) => {
          if (query) {
            serachOptions.current.query = query;
          } else {
            delete serachOptions.current.query;
          }

          setSearchParams(omitUndefined(serachOptions.current));

          table.current.reload();
        }}
        title={"Чаты"}
      />
      <div className={classes("content")}>
        <div className={classes("filtersSection")}>
          <RadioTabs
            value={serachOptions.current.mode}
            onChanged={(v) => {
              serachOptions.current.mode = v;
              setSearchParams(omitUndefined(serachOptions.current));

              table.current.reload();
            }}
            default={"all"}
            items={[
              {
                value: "all",
                name: "Все",
              },
              {
                value: "new",
                name: "Новые",
              },
              ,
              {
                value: "my",
                name: "Мои открытые",
              }
            ]}
          />
          <div className={classes('actions')}>
          <Button onClick={goToAddNewClient} containerStyle={{paddingTop:8, paddingBottom:8, lineHeight:'normal'}} mode={'green'} text={'Добавить клиента'} />

          </div>
        </div>

        <Table
          offset={130}
          ref={table}
          endComponent={<TableEnd />}
          limit={1000}
          columns={columns}
          fetchData={(param) => {
            if (serachOptions.current.mode === 'new'){
              return getNewChats({ ...param, query:serachOptions.current.query  })
            } else if (serachOptions.current.mode === 'my'){
              return getMyChats({ ...param, query:serachOptions.current.query})
            } else 
            return getChats({ ...param, query:serachOptions.current.query })
          }
          }
        />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    actions:{
      display:'flex',
      flex:1,
      justifyContent:'flex-end',
      marginRight:40
    },

    filtersSection: {
      marginBottom: 32,
      display:'flex'
    },
    content: {
      paddingLeft: 40,
      paddingTop: 30,
    },
    searchItem: {
      marginBottom: 4,
    },
    searchLabel: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    arraowDown: {
      color: Colors.accent2,
    },
    arraowUp: {
      color: Colors.color1,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  };
};
