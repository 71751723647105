import { Music } from "react-feather";
import { getClasses } from "../../../constants/theme";
import { makeImageUrl } from "../../../utils";
import AudioPlayer from "../../AudioPlayer";

export default function ViewBlockAudio({ block }) {
  const classes = getClasses(dynamicStyle);

  return (
    <div className={classes("container")}>
        <div className={classes("musicWrapper")}>
        <Music size={28} className={classes("musicIcon")} name={"music"} />
      </div>
      <div className={classes("mc")}>
        <AudioPlayer src={makeImageUrl(block.value, void 0, "filename")} />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts) => {
  return {
    image: {
      width: "100%",
      minHeight: 124,
      flex: 1,
      borderRadius: 10,
      justifyContent: "flex-start",
      alignItems: "flex-end",
      backgroundColor: Colors.neutral3,
      display: "flex",
    },
    wrapper: {
      display: "flex",
      flex: 1,
    },

    mc: {
      display: "flex",
      flex: 0,
      justifyContent: "flex-start",
      alignItems: "flex-end",
    },

    container: {
      marginTop: 0,
      marginBottom: 12,
      overflow: "hidden",
      height: 124,
      backgroundColor: Colors.color2,
      borderRadius: 10,
      display: "flex",
      flex: 1,
      justifyContent: "flex-start",
      flexDirection:'column'
    },
    musicWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex:1
    },
    musicIcon: {
      fontSize: 28,
      color: Colors.neutral2,
    },
  };
};
