import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import AuthPage from "./../pages/AuthPage/AuthPage";
import { checkToken, errorHandler, getCurrentCounters, getMe } from "../api";
import { Settings } from "../stores/Settings";
import NotFound from "./../pages/NotFound/NotFound";
import Main from "../pages/Main";
import TransactionsList from "../pages/Transactions/TransactionsList";
import Transaction from "../pages/Transactions/Transaction";
import PretenstionList from "../pages/Pretension/PretenstionList";
import CoursesList from "../pages/Courses/CoursesList";
import StudyGroupsList from "../pages/StudyGroups/StudyGroupsList";
import KYCList from "../pages/KYC/KYCList";
import ChatList from "../pages/Chats/ChatList";
import Pretension from "../pages/Pretension/Pretension";
import Course from "../pages/Courses/Course";
import Lesson from "../pages/Courses/Lesson";
import Hometask from "../pages/Courses/Hometask";
import KYC from "../pages/KYC/KYC";
import StartPage from "../pages/Courses/StartPage";
import Chat from "../pages/Chats/Chat";
import NenKYCList from "../pages/KYC/NenKYCList";
import NewChatList from "../pages/Chats/NewChatList";
import NewPretenstionList from "../pages/Pretension/NewPretenstionList";
import NewTransactionsList from "../pages/Transactions/NewTransactionsList";
import NewCoursesList from "../pages/Courses/NewCoursesList";
import EditLesson from "../pages/EditCourses/EditLesson";
import EditHometask from "../pages/EditCourses/EditHometask";
import EditStartPage from "../pages/EditCourses/EditStartPage";
import StudyGroup from "../pages/StudyGroups/StudyGroup";
import ArticlesList from "../pages/Articles/ArticlesList";
import CommentsList from "../pages/Comments/CommentsList";
import Articles from "../pages/Articles/Articles";
import EditSGStartPage from "../pages/StudyGroups/EditStartPage";
import EditArticle from "../pages/Articles/EditPage/EditArticle";
import RequestList from "../pages/Request/RequestList";
import InvoiceList from "../pages/Invoice/InvoiceList";
import NewsList from "../pages/News/NewsList";
import EventsList from "../pages/Events/EventsList";
import ServicesList from "../pages/Services/ServicesList";
import Request from "../pages/Request/Request";
import CreateNewChat from '../pages/Chats/CreateNewChat';
import CreateNewNews from './../pages/News/CreateNewNews';
import PartnersList from "../pages/Partners/PartnersList";
import RequestTemplateList from "../pages/RequestTemplate/RequestTemplateList";
import CreateNewPartner from "../pages/Partners/CreateNewPartner";
import CreateNewEvent from "../pages/Events/CreateNewEvent";
import CreateNewService from "../pages/Services/CreateNewService";


export default function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/dasboard" element={<NotFound />} />


  




          <Route path="/chats" element={<ChatList />} />
          <Route path="/chats/create" element={<CreateNewChat />} />
          <Route path="/chats/:id" element={<Chat />} />


          <Route path="/articles" element={<ArticlesList />} />
          <Route path="/articles/:id" element={<Articles />} />
          <Route path="/articles/:id/edit" element={<EditArticle />} />
          <Route path="/comments" element={<CommentsList />} />





          <Route path="/requests" element={<RequestList />} />
          <Route path="/requests/:id" element={<Request />} />

          
          <Route path="/invoices" element={<InvoiceList />} />
          <Route path="/news" element={<NewsList />} />
          <Route path="/news/create" element={<CreateNewNews />} />
          <Route path="/news/:id" element={<CreateNewNews />} />


          
          <Route path="/events" element={<EventsList />} />
          <Route path="/events/create" element={<CreateNewEvent />} />
          <Route path="/events/:id" element={<CreateNewEvent />} />

          

          <Route path="/services" element={<ServicesList />} />
          <Route path="/services/create" element={<CreateNewService />} />
          <Route path="/services/:id" element={<CreateNewService />} />
          <Route path="/services/types" element={<ServicesList />} />
          <Route path="/services/categories" element={<ServicesList />} />


          <Route path="/partners" element={<PartnersList />} />
          <Route path="/partners/create" element={<CreateNewPartner />} />
          <Route path="/partners/:id" element={<CreateNewPartner />} />


          

          <Route path="/request_template" element={<RequestTemplateList />} />

          

          


        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Layout() {
  const location = useLocation();
  const [ready, setReady] = useState(undefined);

  useEffect(() => {
    if (Settings.has("authorizationToken")) {
      checkToken(Settings.get("authorizationToken"))
        .then(() => getMe())
        .then((res) => {
          Settings.memorize("me", res);
        }).then(() => getCurrentCounters())
        .then((counters) => {
          Settings.memorize("notify_counters", counters);
          setReady(true);
        }).catch((err) => {
          if (err.exception === "SessionExpiredError") {
            Settings.clear("me");
            Settings.clear("authorizationToken");
          }
          errorHandler(err);
          setReady(false);
        });
    } else {
      setReady(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (ready === false) {
    return <AuthPage />;
  } else if (ready === true) {
    return <Main />;
  } else {
    return <NotFound />;
  }
}
