import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import {
  errorHandler,
  articleСonsider,
  getArticle,
  articleReject,
  articleConfirm,
  changeArticleAuthor,
} from "../../api";
import Button from "../../components/Button";
import PageData1 from "../../components/Data/PageData1";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import Title2 from "../../components/Layout/Title2";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import Statuses from "../../components/Statuses";
import { getClasses } from "../../constants/theme";
import {
  getFormCollector,
  useFormCollector,
} from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";
import { pureGet } from "../../utils";
import LessaonItem from "../CreateNewCourse/components/LessonItem";
import createChat from "../../methods/createChat";
import Section from "../../components/Layout/Section";
import ClientInfo from "../../components/ClientInfo";
import { beautifyAmount } from "../../utils/currency";
import BlockView from "../../components/BlockView/BlockView";
import FormErrorContainer from "../../components/FormErrorContainer";
import HelpSection from "../../components/HelpSection";
import Link from "../../components/Link";
import TextInput from "../../components/Form/TextInput";

const useArticle = (_id) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    getArticle(_id)
      .then((res) => setState({ ...res, ready: true }))
      .catch((err) => errorHandler(err));
  }, [_id]);

  return [
    state,
    (status, st = {}) => {
      setState({ ...state, status, ...st });
    },
  ];
};

export default function Articles(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const [article, updateStatus] = useArticle(id);

  const considerArticle = () => {
    Settings.emit("show_dialog", {
      title: "Статья на рассмотрении",
      text: "Вы действительно хотите взять эту статью на рассмотрение.",
      acceptText: "Взять",
      cancelText: "Отмена",
      onAccept: consider,
    });
  };

  const consider = async () => {
    try {
      const res = await articleСonsider(id);
      updateStatus(res.status);
    } catch (err) {
      errorHandler(err);
    }
  };

  const rejectArticle = () => {
    const form = getFormCollector({
      text: "",
    });

    Settings.emit("show_dialog", {
      title: "Отклонить статью",
      text: "Вы действительно хотите отклонить эту статью, не забудьте написать причину отклонения, что бы пользователь мог внести корректировки.",
      acceptText: "Отклонить",
      cancelText: "Отмена",
      Comment: (
        <div className={classes("commentWrapper")}>
          <TextArea
            collector={form}
            name={"text"}
            placeholder={"Причина отказа"}
          />
        </div>
      ),
      onAccept: () => reject(form.get("text")),
    });
  };

  const reject = async (comment) => {
    try {
      const res = await articleReject(id, comment);
      updateStatus(res.status, { reject_comment: comment });
    } catch (err) {
      errorHandler(err);
    }
  };

  const confirmArticle = () => {
    Settings.emit("show_dialog", {
      title: "Одобрить статью",
      text: "Вы действительно хотите одобрить эту статью. После одобоения она станет доступна для всех пользователй",
      acceptText: "Одобрить",
      cancelText: "Отмена",
      onAccept: confirm,
    });
  };

  const confirm = async () => {
    try {
      const res = await articleConfirm(id);
      updateStatus(res.status);
    } catch (err) {
      errorHandler(err);
    }
  };

  const changeAuthor = () => {
    const form = getFormCollector({ _id: "" });

    const change = async () => {
      if (form.get("_id") == "") {
        return Settings.emit("local_notification", {
          level: "red",
          text: "Введите ID автора",
        });
      }

      try {
        const res = await changeArticleAuthor({cl_id:form.get('_id'), _id:article._id })
      } catch (err) {
        errorHandler(err);
      }

      Settings.emit("hide_dialog");
    };

    Settings.emit("show_dialog", {
      title: "Сменить автора статьи",
      text: "Введите ID Автора",
      Comment: (
        <>
          <div style={{ height: 4 }}></div>
          <TextInput name={"_id"} collector={form} placeholder={"CL-1A0A1"} />
          <div style={{ height: 12 }}></div>
          <Button
            onClick={change}
            mode={"green"}
            text={"Взять на рассмотрение"}
          />
        </>
      ),
    });
  };

  return (
    <InnerPage
      ready={article._ready}
      Header={
        <InnerHeader breadcrumbs={[["Статьи", "/articles"], [`#${id}`]]} />
      }
      MainContent={
        <>
          <Section title={"Информация о статье"}>
            <PageLineData title={"Название статьи"} value={article.title} />

            {article?.art_id && (
              <HelpSection>
                Изменение статьи{" "}
                <Link to={`/articles/${article?.art_id?._id}`}>
                  "{article?.art_id?.title}"
                </Link>
              </HelpSection>
            )}

            <PageData1
              withoutMargin={true}
              data={[
                {
                  title: "Статус",
                  value: (
                    <Statuses
                      rowMode={true}
                      class={"ARTStatus"}
                      status={article?.status}
                    />
                  ),
                },
                {
                  title: "ID статьи",
                  value: article._id,
                },

                {
                  title: "Дата создания",
                  value: moment(article.created_at).format("HH:mm DD.MM.YYYY"),
                },
              ]}
            />
          </Section>

          <Section title={"Статистика"}>
            <PageData1
              withoutMargin={false}
              data={[
                {
                  title: "Кол. лайков",
                  value: article.like_count,
                },
                {
                  title: "Уник. просмотров",
                  value: article.unique_view_count,
                },
                {
                  title: "Просмотров",
                  value: article.view_count,
                },
                {
                  title: "Кол. комментариев",
                  value: article.comment_count,
                },
              ]}
            />
          </Section>

          {window.ARTStatus.prototype.isRejected(article.status) && (
            <Section title={"Статья отклонена"}>
              <FormErrorContainer>{article.reject_comment}</FormErrorContainer>
            </Section>
          )}

          <Section title={"Содержание курса"}>
            <div className={classes("artPewViewContainer")}>
              <div className={classes("artPewView")}>
                <BlockView blocks={pureGet(article, "preview", [])} />
                <BlockView blocks={pureGet(article, "blocks", [])} />
              </div>
            </div>
          </Section>
        </>
      }
      RightContent={
        <>
          <div className={classes("actions")}>
            <Link to={`/articles/${id}/edit`}>
              <Button
                containerStyle={{ marginBottom: 8 }}
                text={"Редатировать"}
                mode={"green"}
              />
            </Link>

            <Button
              containerStyle={{ marginBottom: 8 }}
              onClick={changeAuthor}
              text={"Сменить автра"}
              mode={"blue"}
            />

            {window.ARTStatus.prototype.isPendingConsideration(
              article.status
            ) && (
              <Button
                containerStyle={{ marginBottom: 8 }}
                onClick={considerArticle}
                text={"Взять на рассмотрение"}
              />
            )}

            {window.ARTStatus.prototype.isUnderConsideration(
              article.status
            ) && (
              <>
                <Button
                  containerStyle={{ marginBottom: 8 }}
                  onClick={confirmArticle}
                  text={"Одобрить статью"}
                />
                <Button
                  containerStyle={{
                    marginBottom: 8,
                    backgroundColor: "#d02c2c",
                  }}
                  onClick={rejectArticle}
                  text={"Отклонить статью"}
                />
              </>
            )}
          </div>

          <ClientInfo client={article?.created_by} />
        </>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    artPewViewContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
    },
    artPewView: {
      maxWidth: 700,
    },
    h2: {
      ...Fonts.h2,
      color: Colors.n90,
      marginBottom: 8,
    },
    buttonLink: {
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
      cursor: "pointer",
    },
    commentWrapper: {
      marginTop: 16,
    },
    actions: {
      marginBottom: 16,
    },
    subtitle: {
      ...Fonts.headline1,
      marginBottom: 8,
    },
    body: {
      flexDirection: "column",
      display: "flex",
      maxWidth: 500,
    },
    lessons: {
      flexDirection: "column",
      display: "flex",
      flex: 1,
    },

    description: {
      ...Fonts.subhead1,
      color: Colors.neutral1,
      marginBottom: 8,
    },
    backIcon: {
      color: Colors.content,
      marginRight: 16,
      cursor: "pointer",
    },
    title: {
      ...Fonts.title1,
      alignItems: "center",
      display: "flex",
      marginBottom: 16,
    },
  };
};
