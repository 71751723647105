import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  errorHandler,
  getChatMember,
  getChatMessages,
  getKYCEnity,
  readChat,
  rejectKYCEnity,
  sendToVerifyKYC,
  verifyKYCEnity,
} from "../../api";
import Button from "../../components/Button";
import PageData1 from "../../components/Data/PageData1";
import PageLineData from "../../components/Data/PageLineData";
import TextArea from "../../components/Form/TextArea";
import InfinityList from "../../components/InfinityList";
import Title2 from "../../components/Layout/Title2";
import InnerHeader from "../../components/Page/InnerHeader";
import InnerPage from "../../components/Page/InnerPage";
import Statuses from "../../components/Statuses";
import { getClasses } from "../../constants/theme";
import { getFormCollector } from "../../stores/core/collector";
import { Settings } from "../../stores/Settings";
import ChatInput from "./companents/ChatInput";
import Message from "./companents/Message";
import Section from "../../components/Layout/Section";
import ClientInfo from "../../components/ClientInfo";

const useChat = (_id) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    getChatMember(_id)
      .then((res) => setState({ ...res, _ready: true }))
      .catch((err) => errorHandler(err));
  }, []);

  return [state, (cb) => setState({ ...cb(state) })];
};

export default function Chat(props) {
  const classes = getClasses(dynamicStyle);
  const { id } = useParams();
  const [chatMember, updater] = useChat(id);
  const list = useRef();
  const page = useRef();
  const input = useRef();

  let [searchParams, setSearchParams] = useSearchParams();
  const rmg = useRef(searchParams.get("request_msg"));

  const startSession = async () => {
    try {
      const res = await Settings.get('ws').rpc('chat_session.start', { cl_id: chatMember._id })

      updater(state => {
        state.session_first_name = Settings.get('me.first_name');
        state.session_id = +res._id;
        state.session_last_name = Settings.get('me.last_name');
        state.session_usr_id = res.usr_id;
        state.session_started_at = res.started_at

        return state;
      });

    } catch (err) {
      console.log(err)
    }
  }

  const closeSession = async () => {
    try {
      const res = await Settings.get('ws').rpc('chat_session.finish', { cl_id: chatMember._id })

      updater(state => {
        state.session_first_name = void 0;
        state.session_id = void 0;
        state.session_last_name = void 0;
        state.session_usr_id = void 0;
        state.session_started_at = void 0;

        return state;
      });
    } catch (err) {
      console.log(err)
    }
  }

  const scrollToDown = () => {
    page.current.getMainContentRef().current.scrollTo(0, 99999999)
  }

  const onMessageSent = () => {
    updater((state) => {
      state.has_unread_messages = true;
      return state;
    });

    scrollToDown();
  }

  useEffect(() => {
    return Settings.on('messagesRead', (payload) => {
      updater((state) => {
        state.has_unread_messages = false;
        return state;
      });
    })

  }, [updater])

  useEffect(() => {
    return Settings.on('newMessage', (payload) => {
      if (payload.cl_id == chatMember._id) {
        list.current.insertBefore(payload);
        scrollToDown();
        return false
      }
    });
  }, [chatMember._id])


  // useEffect(() => {
  //   if (!rmg)
  //     return;


  // }, [rmg])


  const onBatchAdded = async (res) => {
    if (!rmg.current) return

    if (res.data.length == 0)
      return;

    if (res.data.some(msg => {
      return msg._id == rmg.current
    })) {
      rmg.current = false;
     // list.current.getPrev();
    } else {
      list.current.getPrev();

    }
  }

  const onAnswerMessage = (message) => {
    input.current && input.current.addAnswer(message)
  }



  return (
    <InnerPage
      ref={page}
      ready={chatMember._ready}
      Header={<InnerHeader breadcrumbs={[["Чаты", "/chats"], [`${chatMember?.first_name} ${chatMember?.last_name}`]]} />}
      contentClass={classes("contentClass")}
      MainContent={
        <div className={classes("content")}>
          <div className={classes("listContainer")}>
            <div className={classes("list")}>
              <InfinityList
                onBatchAdded={onBatchAdded}
                renderFooterComponent={() => {
                  if (chatMember.has_unread_messages) {
                    return <div className={classes("clientnoreadLantMessage")}>Пользователь не прочитал последние сообщение</div>

                  } else {
                    return <div className={classes("clientreadLantMessage")}>Пользователь прочитал последние сообщение</div>
                  }
                }}
                ref={list}
                startComponent={<div>Ещё</div>}
                disableAutoLoad={true}
                invert={true}
                size={props.size}
                renderItem={(item, index, arr) => {
                  return (
                    <Message
                      isClient={
                        item?.usr_id == undefined
                      }
                      selected={searchParams.get("request_msg") == item._id}
                      message={item}
                      onAnswerMessage={onAnswerMessage}
                    />
                  );
                }}
                fetchData={(params) =>
                  getChatMessages(
                    {
                      offset: params.offset,
                      list_name: "message_list",
                      limit: 10,
                      params: { cl_id: +chatMember?._id },
                      sorting: [
                        { field: 'created_at', order: 'desc' }, { field: '_id', order: 'desc' }
                      ]
                    }

                  )
                }
              />
            </div>
          </div>
          {chatMember.session_usr_id === Settings.get('me._id') && (
            <ChatInput
              ref={input}
              class={classes("inputContainer")}
              list={list}
              clId={chatMember?._id}
              onMessageSent={onMessageSent}
            />)}
        </div>
      }
      RightContent={
        <div className={classes("rightConent")}>
          <Section title={"Действия"}>
            {chatMember.session_usr_id === Settings.get('me._id') ? (
              <>

                <Button onClick={closeSession} mode={'red'} text={'Закрыть сессию'} />
              </>) : chatMember.session_usr_id !== undefined ? null : <Button onClick={startSession} mode={'green'} text={'Открыть сессию'} />}


            {/* <div
              className={classes("buttonLink")}
              onClick={startSession}

            >
              Открыть сессию
            </div> */}
          </Section>

          {chatMember.session_usr_id && (
            <Section>
              <PageLineData
                title={"Оператор"}
                value={`${chatMember?.session_first_name} ${chatMember?.session_last_name}`}
              />
              <PageLineData
                title={"Старт сессии"}
                value={moment(chatMember?.session_started_at).format('D MMMM HH:mm')}
              />



            </Section>)}

          <ClientInfo client={chatMember} />

        </div>
      }
    />
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    clientnoreadLantMessage: {
      color: Colors.accent1,
      textAlign: 'center',
      marginTop: 14,
      ...Fonts.subhead2

    },
    clientreadLantMessage: {
      color: Colors.blue90,
      textAlign: 'center',
      marginTop: 14,
      ...Fonts.subhead2

    },
    inputContainer: {
      position: "fixed",
      bottom: 0,
      right: 419,
      left: 332,
      // right: "min(419px, 10%)",
      // left: "min(332px, 10%)",
    },
    listContainer: {},
    list: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      justifyContent: "flex-end",
      marginBottom: 90,
    },
    rejectText: {
      color: Colors.red90,
    },
    commentWrapper: {
      marginTop: 16,
    },
    contentClass: {
      backgroundColor: `${Colors.n20}!important`,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },

    rightConent: {},
    buttonLink: {
      marginTop: -8,
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
    },
  };
};
