import {Settings} from '../stores/Settings';
import { UUIDGeneratorBrowser } from '../utils';

export default class WSClient {
  constructor(host, options = {}) {
    this.host = host;
    this.pingInterval = options.pingInterval || 5000;
    this.reconnectInterval = options.reconnectInterval || 5000;
    this.connected = false;
    this._queue = [];
    this._listeners = {};
    this.subscribes = [];
  }

  connect() {
    if (this.connected || this._socket !== undefined) return;

    this.emit('connecting');

    console.log('connecting');

    this._socket = new WebSocket(this.host);
    this._socket.onmessage = message => this.onMessage(message);
    this._socket.onclose = message => this.onClose(message);
    this._socket.onerror = message => this.onError(message);
    this._socket.onopen = message => this.onConnect(message);
  }

  emit(event, message = this) {
    if (this._listeners[event] === undefined) return this;

    let l = this._listeners[event].length;

    while (l--) {
      this._listeners[event][l].callback(message);
    }

    return this;
  }

  on(event, callback, options = {}) {
    if (this._listeners[event] === undefined) this._listeners[event] = [];

    this._listeners[event].push({callback, options});
  }

  off(event) {
    if (this._listeners[event] === undefined) this._listeners[event] = [];

    delete this._listeners[event];

    return this;
  }

  async rpc(command, payload = {}, {timeout} = {timeout: 30000}) {
    const _rid = UUIDGeneratorBrowser();

    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        clearInterval(timer);
        this.off(_rid);

        reject({
          exception: 'RequestTimeoutError',
          message: 'Попробуйте попытку позднее',
          http_error: 'Ошибка соединения',
        });
      }, timeout);

      this.on(_rid, _payload => {
        clearInterval(timer);
        this.off(_rid);

        if (_payload.exception) {
          reject(_payload);
        } else {
          resolve(_payload);
        }
      });

      this.send(command, payload, _rid);
    });
  }

  reconnect = () => {
    if (this.reconnectInterval === 0) return;

    if (this._rTimeout) return;

    if (this.connected) return;

    this.emit('reconnect');

    this._rTimeout = setTimeout(() => {
      this.connect();

      clearTimeout(this._rTimeout);

      this._rTimeout = void 0;
    }, this.reconnectInterval);
  };

  onConnect = message => {
    this.connected = true;

    let args;

    while ((args = this._queue.shift())) {
      this.send(...args);
    }

    this.emit('connected');

    if (this.pingInterval > 0) this.startPing();

    // eslint-disable-next-line no-unused-vars
  };

  disconnect = () => {
    this._socket && this._socket.close();
    clearTimeout(this._rTimeout);
    this.stopPing();
  };

  startPing = () => {
    this.pingTimer = setInterval(() => {
      if (this.connected) {
        this._socket && this._socket.send(JSON.stringify({_command: 'ping'}));
      }
    }, this.pingInterval);
  };

  stopPing = () => {
    clearInterval(this.pingTimer);
    this.pingTimer = void 0;
  };

  onError = message => {
    this.connected = false;

    this.emit('error', message);
    this._socket = void 0;
    this.stopPing();

    // this.reconnect();
  };

  onClose = message => {
    this._socket = void 0;
    this.connected = false;
    this.stopPing();

    this.emit('close', message);

    this.reconnect();
  };

  onMessage = message => {
    if (message.data === 'pong') return this.emit('messege', message.data);

    try {
      const json = JSON.parse(message.data);

      console.log('recive message', json);

      if (json._event === '_commandResponse') {
        this.emit(json._rid, json.payload);
      } else if (json._event === '_syncDataItemSent') {
        Settings.emit('_syncDataItemSent', json.payload);
      } else {
        Settings.emit(json._event, json.payload);
        this.emit(json.event, json);
      }
    } catch (e) {
      console.error(e);
    }
  };

  subscribe = (event, options, callback) => {
    if (callback === undefined) {
      callback = options;
      options = {};
    }

    (Array.isArray(event) ? event : [event]).forEach(e => {
      this.on(event, callback, options);
      if (this.connected) this.send('subscribe', {event, options});
    });
  };

  unsubscribe = (event, callback) => {
    (Array.isArray(event) ? event : [event]).forEach(e => {
      this.off(e, callback);

      this.send('unsubscribe', event);
    });
  };

  authorize = token => {
    this.send('authorize', token);
  };

  resubscribe() {
    this.subscribes.forEach(subscribe => {
      this.send('subscribe', subscribe);
    });
  }

  sendSubscribe(payload) {
    this.subscribes.push(payload);
    this.send('subscribe', payload);
  }

  sendUnsubscribe(payload) {
    this.subscribes = this.subscribes.filter(s => {
      return s.rid !== payload.rid;
    });

    this.send('unsubscribe', payload);
  }

  send = (command, payload = {}, _rid) => {
    if (this.connected === false || this._socket === undefined) {
      this._queue.push([command, payload, _rid]);

      return;
    }

    console.log('send command', command, payload);

    this.emit('send', {command, payload});

    this._socket.send(
      JSON.stringify({
        _command: command,
        payload: payload,
        _rid,
      }),
    );
  };
}
