//<Outlet />;

import {
  BookOpen,
  Users,
  Folder,
  Frown,
  MessageSquare,
  Pocket,
  FileText,
  Star,
  ThumbsUp,
  Shield,
} from "react-feather";
import { Link } from "react-router-dom";
import { getClasses } from "../../../constants/theme";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Settings } from "../../../stores/Settings";
import Title2 from "../../../components/Layout/Title2";

const gsc = (link, currentLink, lv) => {
  return link === currentLink && lv === undefined ? "selected" : void 0;
};

export default function LeftMenu(props) {
  const classes = getClasses(dynamicStyle);
  const { pathname } = useLocation();

  const [, currentLink, level2] = pathname.split("/");

  return (
    <div className={classes("leftMenu")}>
      <Header />
      <div className={classes("sec")}>
        <Title2>{Settings.get('me.first_name')} {Settings.get('me.last_name')}</Title2>
      </div>
      <div className={classes("wrapper")}>
        <NotificationMenu currentLink={currentLink} />




        <Link
          className={classes("link", gsc("chats", currentLink))}
          to={"/chats"}
        >
          <MessageSquare size={20} className={classes("icon")} />
          Чаты
        </Link>

        <Link
          className={classes("link", gsc("requests", currentLink))}
          to={"/requests"}
        >
          <Pocket size={20} className={classes("icon")} />
          Запросы
        </Link>

        {Settings.get('me.show_full_menu') && <>

          <Link
            className={classes("link", gsc("invoices", currentLink))}
            to={"/invoices"}
          >
            <Pocket size={20} className={classes("icon")} />
            Счета
          </Link>


          <Link
            className={classes("link", gsc("news", currentLink))}
            to={"/news"}
          >
            <FileText size={20} className={classes("icon")} />
            Новости
          </Link>

          <Link
            className={classes("link", gsc("events", currentLink))}
            to={"/events"}
          >
            <Star size={20} className={classes("icon")} />
            Мероприятия
          </Link>


          <Link
            className={classes("link", gsc("services", currentLink))}
            to={"/services"}
          >
            <Shield size={20} className={classes("icon")} />
            Сервисы
          </Link>
            {currentLink === 'services' && 
            <>
              
            <Link
              className={classes("link","two", gsc("types", level2))}
              to={"/services/types"}
            >
              <Shield size={20} className={classes("icon")} />
              Типы сервиса
            </Link>
            <Link
              className={classes("link","two", gsc("categories", level2))}
              to={"/services/categories"}
            >
              <Shield size={20} className={classes("icon")} />
              Категории сервиса
            </Link>
            
            </>}

          <Link
            className={classes("link", gsc("partners", currentLink))}
            to={"/partners"}
          >
            <Shield size={20} className={classes("icon")} />
            Партнеры
          </Link>
          <Link
            className={classes("link", gsc("request_template", currentLink))}
            to={"/request_template"}
          >
            <Shield size={20} className={classes("icon")} />
            Шаблоны запросов
          </Link>

        </>}



        {/* <Link className={classes("link", gsc("kyc", currentLink))} to={"/kyc"}>
          <Pocket size={20} className={classes("icon")} />
          Документы
        </Link>

        <Link
          className={classes("link", gsc("chats", currentLink))}
          to={"/chats"}
        >
          <MessageSquare size={20} className={classes("icon")} />
          Чаты
        </Link>

        <Link
          className={classes("link", gsc("study_groups", currentLink))}
          to={"/study_groups"}
        >
          <BookOpen size={20} className={classes("icon")} />
          Курсы
        </Link>
        <Link
          className={classes("link", gsc("courses", currentLink))}
          to={"/courses"}
        >
          <Folder size={20} className={classes("icon")} />
          Шаблоны
        </Link>


        <Link
          className={classes("link", gsc("articles", currentLink))}
          to={"/articles"}
        >
          <FileText size={20} className={classes("icon")} />
          Статьи
        </Link>



        <Link
          className={classes("link", gsc("comments", currentLink))}
          to={"/comments"}
        >
          <MessageSquare size={20} className={classes("icon")} />
          Коментарии
        </Link>



        <Link
          className={classes("link", gsc("transactions", currentLink))}
          to={"/transactions"}
        >
          <Pocket size={20} className={classes("icon")} />
          Транзакции
        </Link>
        <Link
          className={classes("link", gsc("pretensions", currentLink))}
          to={"/pretensions"}
        >
          <Frown size={20} className={classes("icon")} />
          Претензии
        </Link> */}
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    sec: {
      marginLeft: 32
    },
    delimiter: {
      borderBottom: "1px solid #E5EAEF",
      marginTop: 16,
      marginBottom: 16,
    },
    counterWrapper: {
      ...Fonts.subhead2,
      color: "#0560FD",
      backgroundColor: "#F3F5F8",
      height: 24,
      width: 24,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 24,
      lineHeight: void 0,
    },
    counter: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
    icon: {
      marginRight: 16,
      color: Colors.baseIconColor,
    },
    selected: {
      backgroundColor: Colors.n30,
    },
    link: {
      ...Fonts.mainMenu,
      color: Colors.n90,
      textDecoration: "none",
      height: 42,
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      paddingLeft: 16,
    },
    two:{
      marginLeft:16
    },
    "link:hover": {
      backgroundColor: Colors.n20,
    },

    wrapper: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      paddingLeft: 16,
      paddingRight: 16,
    },
    leftMenu: {
      display: "flex",
      flexDirection: "column",
      width: 292,
      borderRight: `1px solid #E5EAEF`,
      backgroundColor: Colors.n0,
    },
  };
};


const NotificationMenu = ({ currentLink }) => {
  const classes = getClasses(dynamicStyle2);
  const [counters, setCounters] = useState(Settings.get("notify_counters"));
  const newMessagwAudio = useRef(new Audio('/message.mp3'));

  const links = [    {
    link: "chats?mode=my",
    prop: "my_open_sessions",
    title: "Мои открытые сессии",
  },
    {
      link: "chats?mode=my",
      prop: "new_chat_count",
      title: "Мои открытые чаты",
    },
    {
      link: "requests?mode=new",
      prop: "new_request_count",
      title: "Запрос",
    },
    ,
    {
      link: "chats?mode=new",
      prop: "common_new_chat_count",
      title: "Обращения",
    },
  ];


  useEffect(() => {
    return Settings.on("notify_counters", ({ next }) => {
      setCounters(next);

      if (next.common_new_chat_count > 0) {
        newMessagwAudio.current.play()
      }
    });
  }, []);

  const menu = links.filter((l) => counters[l.prop] != 0);

  if (menu.length === 0) return null;

  return (
    <div className={classes("nwrapper")}>
      {menu.map((link, index, arr) => {
        return (
          <div key={link.link}>
            <Link
              reloadDocument={true}
              className={classes("link", gsc(link.link, currentLink))}
              to={`/${link.link}`}
            >
              {link.title}
              <div className={classes("counter")}>
                <div className={classes("counterWrapper")}>
                  {counters[link.prop]}
                </div>
              </div>
            </Link>
            {arr.length - 1 !== index && (
              <div className={classes("delimetr")}></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const dynamicStyle2 = (Colors, Fonts, params = {}) => {
  return {
    delimetr: {
      borderBottom: "1px solid #ccc",
      marginLeft: 20,
      marginRight: 20,
    },
    nwrapper: {
      backgroundColor: Colors.n30,
      borderRadius: 8,
      marginBottom: 24,
      paddingTop: 8,
      paddingBottom: 8,
    },

    counterWrapper: {
      ...Fonts.subhead2,
      color: "#0560FD",
      backgroundColor: "#F3F5F8",
      height: 24,
      width: 24,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 24,
      lineHeight: void 0,
    },
    counter: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
    icon: {
      marginRight: 16,
      color: Colors.baseIconColor,
    },
    selected: {
      backgroundColor: Colors.neutral4,
    },
    link: {
      ...Fonts.tableData,
      color: Colors.n90,
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      paddingLeft: 20,
      marginRight: 20,
      marginTop: 8,
      marginBottom: 8,
    },
  };
};
