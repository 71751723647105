import moment from "moment";
import React, { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { getChats, getEvents, getInvoices, getNews, getRequests } from "../../api";
import Link from "../../components/Link";
import InnerHeader from "../../components/Page/InnerHeader";
import Table from "../../components/Table";
import TableEnd from "../../components/TableEnd";
import { getClasses } from "../../constants/theme";
import { omitUndefined } from "../../utils";

const getColumns = (classes) => ({
  _id: {
    f: 0.1,
    title: "ID",
    converter: (_id) => {
      return <Link to={`/chats/${_id}`}>{_id}</Link>;
    },
  },
  title: {
    f: 3,
    title: "Название",
    converter: (title, elm) => {
      return <Link to={`/events/${elm._id}`}>{title}</Link>;
    },
  },

  
  
  external: {
    f: 0.6,
    title: "Внутреннее",
    converter: (external, elm) => {
      return external ? 'Да' : 'Нет';
    },
  },
  

  started_at:{
    f: 0.6,
    title: "Начало",
    converter: (started_at) => {
      return moment(started_at).format('D.MM.YY HH:mm');
    },
  },
  city: {
    f: 0.6,
    title: "Город",
    
  },
  
  
});

export default function EventsList() {
  const classes = getClasses(dynamicStyle);
  const table = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const columns = useMemo(() => getColumns(classes), []);
  const serachOptions = useRef({
    query: searchParams.get("query") ?? void 0,
    action: searchParams.get("action") ?? "all",
  });

  return (
    <div className={classes("wrapper")}>
      <InnerHeader
        defaultQuery={serachOptions.current.query}
        onQuerySubmit={(query) => {
          if (query) {
            serachOptions.current.query = query;
          } else {
            delete serachOptions.current.query;
          }

          setSearchParams(omitUndefined(serachOptions.current));

          table.current.reload();
        }}
        title={"Чаты"}
      />
      <div className={classes("content")}>
        <Table
          offset={130}
          ref={table}
          endComponent={<TableEnd />}
          limit={1000}
          columns={columns}
          fetchData={(param) =>
            getEvents({ ...param, ...serachOptions.current })
          }
        />
      </div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    filtersSection: {
      marginBottom: 32,
    },
    content: {
      paddingLeft: 40,
      paddingTop: 30,
    },
    searchItem: {
      marginBottom: 4,
    },
    searchLabel: {
      ...Fonts.subhead2,
      color: Colors.neutral1,
      marginBottom: 2,
    },
    arraowDown: {
      color: Colors.accent2,
    },
    arraowUp: {
      color: Colors.color1,
    },
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  };
};
