import { Store } from "./core/store";

export const Settings = new Store({
  key: "store:settings-5",
  defaultSchema: {
    theme: "system",
    system_theme: "system",
    authorized: false, // приложение загружено и авторизировано
    "use-system-locale": true,
    // server: {
    //   proxy_host: "https://ev-proxy.getbackdev.com/v2/bo/",
    //   cdn: "https://storage.googleapis.com/",
    //   cdn_type: "gcs",
    //   bucket: "marathon-temp",
    // },
    server: {
      ws_host: 'wss://rc.fwalls.ru/ws',
    },
    windowsize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    notify_counters: {
      chat_count: 0,
      course_count: 0,
      kyc_count: 0,
      pretension_count: 0,
      transaction_count: 0,
      articles_count:0
    },
  },
});

window.Settings = Settings;
