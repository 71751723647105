import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { errorHandler, getClientInfo } from "../api";
import { getClasses } from "../constants/theme";
import createChat from "../methods/createChat";
import { beautifyAmount } from "../utils/currency";
import PageLineData from "./Data/PageLineData";
import Statuses from "./Statuses";

const useClientInfo = (cl) => {
  const [state, setState] = useState({ _ready: false });

  useEffect(() => {
    if (!cl?._id)
      return [state]
    getClientInfo(cl._id)
      .then((res) => setState({ ...state, ...res, _ready: true }))
      .catch((err) => errorHandler(err));
  }, [cl?._id]);

  return [state];
};

export default function ClientInfo(props) {
  const classes = getClasses(dynamicStyle);
  const navigate = useNavigate();
  const [client] = useClientInfo(props?.client);

  return (
    <>
        <PageLineData
        title={"Имя пользователя"}
        value={`${client?.last_name} ${client?.first_name}`}
      />
      <PageLineData title={"ID пользователя"} value={client?._id} />


      <PageLineData
        title={"Дата региcтрации"}
        value={moment(client.registered_at).format("DD.MM.YYYY")}
      />


      <PageLineData title={"Номер телефона"} value={client?.phone_number} />



      <PageLineData
        title={"Последнее сообщение"}
        value={moment(client.last_message_at).format("DD.MM.YYYY HH:mm:ss")}
      />

      <PageLineData title={"Всего счетов"} value={client?.total_invoice_count} />
      <PageLineData title={"Всего запросов"} value={client?.total_request_count} />
      <PageLineData title={"Оплаченых счетов"} value={client?.paid_invoice_count} />
      <PageLineData title={"Закрытых запросов"} value={client?.completed_request_count} />
      <PageLineData title={"Открытыз запросов"} value={+client?.total_request_count - +client?.completed_request_count} />

    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    h2: {
      ...Fonts.h2,
      color: Colors.n90,
      marginBottom: 8,
    },
    buttonLink: {
      color: Colors.blue90,
      backgroundColor: Colors.blue10,
      ...Fonts.button,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: 45,
      borderRadius: 8,
      textDecoration: "none",
      marginBottom: 8,
      cursor: "pointer",
    },
  };
};
