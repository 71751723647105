export const title1 = {
    fontWeight: '600',
    fontSize: 28,
    fontFamily: 'Manrope, sans-serif',
    lineHeight: '32px',
  };
  
  export const title2 = {
    fontWeight: '600',
    fontSize: 22,
    fontFamily: 'Manrope, sans-serif',
    lineHeight: '27px',

  };
  
  export const headline1 = {
    fontWeight: '600',
    fontSize: 17,
    fontFamily: 'Manrope, sans-serif',
    lineHeight:'21px'
  };
  
  export const body = {
    fontSize: 17,
    fontFamily: 'Manrope, sans-serif',
    lineHeight: '22px',

  };
  
  export const subhead1 = {
    fontSize: 15,
    fontFamily: 'Manrope, sans-serif',
    lineHeight: '21px',

  };
  
  export const subhead2 = {
    fontWeight: '600',
    fontSize: 15,
    fontFamily: 'Manrope, sans-serif',
    lineHeight: '20px',

  };
  
  export const footnote = {
    fontSize: 13,
    fontFamily: 'Manrope, sans-serif',
    lineHeight: '17px',

  };
  
  export const caption1 = {
    fontSize: 12,
    fontFamily: 'Manrope, sans-serif',
    lineHeight: '16px',

  };
  
  export const tagline3 = {
    fontWeight: '600',
    fontSize: 11,
    fontFamily: 'Manrope, sans-serif',
    lineHeight: '16px',

  };