import React, {useState, useEffect} from 'react';
import {Settings} from '../stores/Settings';
// import checkToken from '../methods/checkToken';
// import updateMe from '../methods/updateMe';
// import {showError} from '../api';
// import deauth from '../methods/deauth';
//import manager from '../models/manager';
//import {startSyncMessages} from '../hooks/useMessageSync';
import WSClient from '../service/wsclient';
import { checkToken, getMe } from '../api';

export default ({state, descriptors, navigation, ...props}) => {
  // const [status, setStatus] = useState();
  // const [visible, setVisible] = useState(false);

  const tryEnter = async () => {
    //setStatus('autorization');

    try {
       await checkToken(Settings.get('authorizationToken'));
       const me = await getMe();

       Settings.memorize('me', me)
      //setStatus('autorized');
    } catch (err) {
     // deauth(props.navigation);
    //  showError(err);

      console.log(err);
    }
  };

  useEffect(() => {
    const ws = new WSClient(Settings.get('server.ws_host'));

    Settings.memorize('ws', ws);

    ws.on('connected', () => {
      // setStatus('connected');

      Settings.memorize('wsconneted', true);

      if (Settings.has('authorizationToken')) {
        tryEnter().then(() => {
          // setVisible(false);
          // startSyncMessages();
          // manager.syncAll();
        });
      } else {
        //setVisible(false);
      }
    });

    ws.on('connecting', () => {
      // setVisible(true);
      // setStatus('connecting');
    });

    ws.on('authorization_completed', () => {
      // setStatus('authorized');
      // setVisible(false);
    });

    ws.on('error', m => {
      console.log('error: ', m);
    });

    ws.on('close', m => {
      console.log('close: ', m);
      Settings.memorize('wsconneted', false);
    });

    ws.on('reconnect', () => {
      // setStatus('reconnecting');
    });

    ws.connect();
  }, []);

  //const styles = useTheme(dynamicStyle);

  return null;
};

