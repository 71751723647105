export const Storage = {
  set: async (key, value) => {
      return await localStorage.setItem(key, value)
  },
  get: async (key) => {
      return await localStorage.getItem(key)
  },
  setJSON: async (key, value) => {
      const v = typeof value === 'string' ? value : JSON.stringify(value)

      return await Storage.set(key, v)
  },
  getJSON: async (key) => {
      const res = await Storage.get(key)

      return JSON.parse(res)
  },
  remove: async (key) => await localStorage.removeItem(key)
}
