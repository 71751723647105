import { useEffect, useRef } from "react";
import "./App.css";
import Connection from "./components/Connection";
import Dialog from "./components/Dialog";
import Notifications from "./components/Notifications";
import useStore from "./hooks/useStore";
import Navigation from "./navigation";
import { Settings } from "./stores/Settings";



function App() {
  const ready = useStore();
  const newMessagwAudio = useRef(new Audio('/message2.mp3'));

  useEffect( () => {
    return Settings.on('newMessage',(payload) => {
      newMessagwAudio.current.play()
      Settings.emit("local_notification", {
        title: `Новое сообщение от ${payload.first_name} ${payload.last_name}`,
        text: payload.text,
      });

    });
  }, [])

  if (!ready) return null;


  return (
    <>
      <Connection />
      <Navigation />
      <Dialog />
      <Notifications />
    </>
  );
}

export default App;

(function () {
  var throttle = function (type, name, obj) {
    obj = obj || window;
    var running = false;
    var func = function () {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(function () {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };

  /* init - you can init any event */
  throttle("resize", "optimizedResize");
})();

// handle event
window.addEventListener("optimizedResize", function () {
  Settings.memorize("windowsize", {
    width: window.innerWidth,
    height: window.innerHeight,
  });
});


console.error = () => {}
console.warn = () => {}
// console.log = () => {}